import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable, combineLatest, map, startWith, tap, withLatestFrom } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { EventsFacade } from '@yuno/admin/features/events';
import { LayersFacade } from '@yuno/admin/features/layers';
import { LegendFacade } from '@yuno/admin/features/legends';
import { ContentForm } from '@yuno/admin/features/legends/feature/editor/legend-editor.service';
import { ImageButtonFacade } from '@yuno/admin/features/media';
import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers';
import { VariantsFacade } from '@yuno/admin/features/variants/data-access';
import { moveItemInFormArray } from '@yuno/angular/forms';
import {
	CdnFile,
	Dataset,
	Event,
	LanguageAll,
	Layer,
	Legend,
	MarkerCategory,
	MediaImageButton,
	Pages,
	RoutesInterface
} from '@yuno/api/interface';

import { PageEditorService, PagesFacade, TextfieldEditorService } from '../../data-access';
import { TextPageComponentService } from '../../data-access/components.service';
import { ListItemForm } from './list-editor/list.interface';
import { ItemLinkForm } from './page-item-editor/page-item.interface';
import { TextNewsForm } from './text-news-editor';
import { ToggleItemForm } from './toggle-editor/toggles.interface';

@Injectable({
	providedIn: 'root'
})
export class TextEditorsService {
	private readonly destroyRef = inject(DestroyRef);

	private readonly appFacade = inject(AppFacade);
	private readonly layerFacade = inject(LayersFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly pageService = inject(PageEditorService);
	private readonly pagesFacade = inject(PagesFacade);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly imageButtonFacade = inject(ImageButtonFacade);
	private readonly legendFacade = inject(LegendFacade);
	private readonly variantFacade = inject(VariantsFacade);
	private readonly eventsFacade = inject(EventsFacade);

	private readonly textService = inject(TextfieldEditorService);
	private readonly componentService = inject(TextPageComponentService);

	service: PageEditorService | TextfieldEditorService | undefined;

	readonly languages = LanguageAll;
	language$ = this.appFacade.language$.pipe(startWith('nl'));

	eventToggle$ = this.eventsFacade.toggle$;
	config$ = this.appFacade.config$;

	data$ = combineLatest({
		pages: this.pagesFacade.pages$.pipe(
			tap(pages => {
				if (pages && pages.length >= 1) {
					this.setPagesValues(pages);
				}
			})
		),
		routes: this.variantFacade.navigation$.pipe(
			withLatestFrom(this.appFacade.language$),
			tap(([navigation, lang]) => {
				if (navigation && navigation.routes?.length >= 1 && lang) {
					this.setNavigationValues(navigation.routes, lang);
				}
			})
		),
		layers: this.layerFacade.layers$.pipe(
			tap(layers => {
				if (layers && layers.length >= 1) {
					this.setLayerValues(layers);
				}
			})
		),
		datasets: this.datasetFacade.datasets$.pipe(
			tap(datasets => {
				if (datasets && datasets.length >= 1) {
					this.setDatasetValues(datasets);
				}
			})
		),
		categories: this.categoryFacade.markerCategories$.pipe(
			tap(categories => {
				if (categories && categories.length >= 1) {
					this.setCategoryValues(categories);
				}
			})
		),
		category: this.categoryFacade.selectedMarkerCategory$.pipe(
			tap(data => {
				if (this.textService.component && data) {
					this.textService.component.get('category')?.patchValue(data);
				}
			})
		),
		imageButtons: this.imageButtonFacade.imageButtons$.pipe(
			tap(buttons => {
				if (buttons && buttons.length >= 1) {
					this.setImageButtonValues(buttons);
				}
			})
		),
		imageButton: this.imageButtonFacade.selectedImageButton$.pipe(
			tap(data => {
				if (data) {
					if (this.pageService.component) {
						this.pageService.component.patchValue({ item: data });
					}
					if (this.textService.component) {
						this.textService.component.patchValue({ item: data });
					}
				}
			})
		),
		legends: this.legendFacade.legends$.pipe(
			tap(legends => {
				if (legends && legends.length >= 1) {
					this.setLegendValues(legends);
				}
			})
		),
		legend: this.legendFacade.selectedLegends$.pipe(
			tap(data => {
				if (data) {
					if (this.pageService.component) {
						this.onUpdateLegend(this.pageService.component, data);
					}
					if (this.textService.component) {
						this.onUpdateLegend(this.textService.component, data);
					}
				}
			})
		),
		language: this.language$
	});

	getActiveService(): Observable<PageEditorService | TextfieldEditorService | undefined> {
		return combineLatest([this.pageService.active$, this.textService.active$]).pipe(
			map(([page, textfield]) =>
				page ? this.pageService : textfield ? this.textService : undefined
			)
		);
	}

	isPageEditorService(
		service: PageEditorService | TextfieldEditorService
	): service is PageEditorService {
		return service instanceof PageEditorService;
	}

	returnPagesService(
		service: PageEditorService | TextfieldEditorService | undefined
	): PageEditorService {
		return service as PageEditorService;
	}

	isTextfieldEditorService(
		service: PageEditorService | TextfieldEditorService
	): service is TextfieldEditorService {
		return service instanceof TextfieldEditorService;
	}

	returnTextfieldService(
		service: PageEditorService | TextfieldEditorService | undefined
	): TextfieldEditorService {
		return service as TextfieldEditorService;
	}

	onInitNav() {
		this.variantFacade.getNavigationsByAppId();
	}

	onInitPages() {
		this.pagesFacade.get();
	}

	onInitLayers() {
		this.layerFacade.get();
	}

	onInitDatasets() {
		this.datasetFacade.get();
	}

	onInitCategories() {
		this.categoryFacade.get();
	}

	onInitImageButtons() {
		this.imageButtonFacade.getImageButtons();
	}

	onInitLegends() {
		this.legendFacade.getLegendsByAppId();
	}

	// Button
	onToggleEvents(type: 'events' | 'activeEvents') {
		if (this.service) {
			const events: Event[] = [];
			if (type === 'events') {
				this.service.eventType = 'events';
			}
			if (type === 'activeEvents') {
				this.service.eventType = 'activeEvents';
			}
			this.service.eventOrigin = 'button';
			this.eventsFacade.toggleEvents(true);
			this.eventsFacade.updateEvents(events);
			this.eventsFacade.setKey(type);
		}
	}

	// Category
	onSelectCategory(id: string | null) {
		if (id) {
			this.categoryFacade.select(id);
		}
	}

	onClearCategory() {
		this.categoryFacade.clearSelect();
	}

	// Image Button
	onSelectImageButton(id: string | null) {
		if (id) {
			this.imageButtonFacade.onSelect(id);
		}
	}

	onClearImageButtons() {
		this.imageButtonFacade.clearSelect();
	}

	// Legend
	onSelectLegend(id: string | null) {
		if (id) {
			this.legendFacade.select(id);
		}
	}

	onClearLegend() {
		this.legendFacade.clearSelect();
	}

	onUpdateLegend(form: FormGroup, data: Partial<Legend>) {
		if (data.content && data.content.length > 0) {
			const formContent = form.get('content') as FormArray<FormGroup<ContentForm>>;
			for (const item of data.content) {
				const itemForm = this.componentService.createLegendContentItemForm(item);
				formContent.push(itemForm);
			}
		}
		form.patchValue(data);
	}

	// List Items
	onToggleListEvents(index: number) {
		if (this.service) {
			this.service.eventIndex = index;
			this.service.eventOrigin = 'list';
			this.service.eventType = 'events';
			this.eventsFacade.toggleEvents(true);
			this.eventsFacade.setKey('events');
		}
	}

	dropListItems(event: CdkDragDrop<FormGroup<ListItemForm>[]>): void {
		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		const list = event.container.data.map(data => data.value);
		if (this.service) {
			this.service.listItems.patchValue(list);
		}
	}

	// Page Items
	/**
	 * When updating the pageUrl we need to patch the pageUrl
	 * to the id input. So it will render in the view
	 */
	onPageItemUrlChange(event: string | null, index: number): void {
		if (this.pageService.itemLinks) {
			const form = this.pageService.itemLinks.controls.at(index);
			form?.get('pageUrl')?.patchValue({
				id: event
			});
		}
	}

	/**
	 * When updating the pageUrl we need to patch the pageId
	 * to the page input
	 */
	onPageItemUrlIdChange(event: string | null, index: number): void {
		if (this.pageService.itemLinks) {
			const form = this.pageService.itemLinks.controls.at(index);
			form?.get('page')?.patchValue(event);
		}
	}

	// News Items
	get component(): FormGroup<TextNewsForm> {
		return this.service?.component as FormGroup<TextNewsForm>;
	}

	get links(): FormArray<FormGroup<ItemLinkForm>> {
		return this.component?.get('links') as FormArray<FormGroup<ItemLinkForm>>;
	}

	observeForm() {
		this.component?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.disableControls();
		});

		// when not using a timeout, the disabled formControls
		// wont return to a enabled state
		setTimeout(() => {
			this.disableControls();
		});
	}

	//
	disableControls(): void {
		const value = this.component?.value;
		if (!value) return;

		if (value.imageLink) {
			if (this.component.get('imageZoom')?.enabled) {
				this.component.get('imageZoom')?.disable();
				this.component.get('imageZoom')?.patchValue(false);
			}
		} else {
			if (this.component.get('imageZoom')?.disabled) {
				this.component.get('imageZoom')?.enable();
			}
		}

		if (value.secondaryImageLink) {
			if (this.component.get('secondaryImageZoom')?.enabled) {
				this.component.get('secondaryImageZoom')?.disable();
				this.component.get('secondaryImageZoom')?.patchValue(false);
			}
		} else {
			if (this.component.get('secondaryImageZoom')?.disabled) {
				this.component.get('secondaryImageZoom')?.enable();
			}
		}

		if (value.align !== 'bottom' && value.align !== 'top') {
			if (this.component.get('imageOrder')?.enabled) {
				this.component.get('imageOrder')?.disable();
				this.component.get('imageOrder')?.patchValue('column');
			}
		} else {
			if (this.component.get('imageOrder')?.disabled) {
				this.component.get('imageOrder')?.enable();
			}
		}
	}

	/**
	 * Selects a image from the image library
	 * and patches the value to the image input
	 */
	patchImageValue(file: CdnFile): void {
		this.component?.get('imageFile')?.patchValue(file);
	}

	patchSecondaryImageValue(file: CdnFile): void {
		this.component?.get('secondaryImageFile')?.patchValue(file);
	}

	/**
	 * Creates a new Link FormGroup
	 * inside the Links FormArray
	 */
	addLink(): void {
		this.links.push(this.componentService.createItemComponentLinkForm());
	}

	/**
	 * Removes a Link from the Links Array
	 * at a certain index
	 */
	removeLink(index: number): void {
		this.links?.removeAt(index);
	}

	/**
	 * Removes Image file from the image input
	 */
	clearImage(): void {
		this.component?.get('imageFile')?.reset();
	}

	clearSecondaryImage(): void {
		this.component?.get('secondaryImageFile')?.reset();
	}

	/**
	 * When updating the pageUrl we need to patch the pageUrl
	 * to the id input. So it will render in the view
	 */
	onPageUrlChange(event: string | null, index: number): void {
		if (this.links) {
			const form = this.links.controls.at(index);
			form?.get('pageUrl')?.patchValue({
				_id: null,
				id: event
			});
		}
	}

	/**
	 * When updating the pageUrl we need to patch the pageId
	 * to the page input
	 */
	onPageUrlIdChange(event: string | null, index: number): void {
		if (this.links) {
			const form = this.links.controls.at(index);
			form?.get('page')?.patchValue(event);
		}
	}

	/**
	 * When updating the type we need to clear the inactive types
	 */
	onTypeChange(event: string | null, index: number): void {
		const form = this.links.controls.at(index);
		if (event === 'page') {
			form?.get('link')?.reset();
			form?.get('url')?.reset();
		}
		if (event === 'navigation') {
			form?.get('page')?.reset();
			form?.get('pageUrl')?.reset();
			form?.get('url')?.reset();
		}
		if (event === 'url') {
			form?.get('page')?.reset();
			form?.get('pageUrl')?.reset();
			form?.get('link')?.reset();
		}
	}

	/**
	 * Drag and drop module moves the items inside the FormArray
	 */
	dropNewsItems(event: CdkDragDrop<FormArray<FormGroup<ItemLinkForm>>>): void {
		moveItemInFormArray(this.links, event.previousIndex, event.currentIndex);
	}

	// Toggle Items
	dropToggleItems(event: CdkDragDrop<FormArray<FormGroup<ToggleItemForm>>>): void {
		moveItemInFormArray(this.textService.toggleItems, event.previousIndex, event.currentIndex);
	}

	// setValues
	setNavigationValues(nav: RoutesInterface[], lang: string): void {
		const displayList: string[] = [];
		const linkList: string[] = [];

		nav?.forEach(item => {
			if (item.dropdown && item.dropdown.length >= 1) {
				item.dropdown.forEach(dropdownItem => {
					displayList.push(
						(dropdownItem.display as { [key: string]: string | undefined })[
							lang
						] as string
					);
					linkList.push(dropdownItem.link as string);
				});
			} else {
				displayList.push(
					(item.display as { [key: string]: string | undefined })[lang] as string
				);
				linkList.push(item.link as string);
			}
		});
		this.pageService.navigationSelectDisplay = displayList;
		this.pageService.navigationSelectValues = linkList;
		this.textService.navigationSelectDisplay = displayList;
		this.textService.navigationSelectValues = linkList;
	}

	setPagesValues(pages: Partial<Pages>[]): void {
		const displayValues = ['none', ...pages.map(page => page.id as string)];
		const values = ['', ...pages.map(page => page._id as string)];
		this.pageService.linkPageSelectDisplay = displayValues;
		this.pageService.linkPageSelectValues = values;
		this.textService.linkPageSelectDisplay = displayValues;
		this.textService.linkPageSelectValues = values;
	}

	setImageButtonValues(buttons: Partial<MediaImageButton>[]): void {
		const displayValues = buttons.map(button => button.id as string);
		const values = buttons.map(button => button._id as string);
		this.pageService.imageButtonSelectDisplay = displayValues;
		this.pageService.imageButtonSelectValues = values;
		this.textService.imageButtonSelectDisplay = displayValues;
		this.textService.imageButtonSelectValues = values;
	}

	setDatasetValues(datasets: Partial<Dataset>[]): void {
		const displayValues = datasets.map(data => data.id as string);
		const values = datasets.map(data => data._id as string);
		this.textService.toggleDatasetDisplay = displayValues;
		this.textService.toggleDatasetValues = values;
	}

	setLayerValues(layers: Partial<Layer>[]): void {
		const displayValues = layers.map(layer => layer.id as string);
		const values = layers.map(layer => layer._id as string);
		this.textService.toggleLayerDisplay = displayValues;
		this.textService.toggleLayerValues = values;
	}

	setLegendValues(legends: Partial<Legend>[]): void {
		const displayValues = legends.map(legend => legend.id as string);
		const values = legends.map(legend => legend._id as string);
		this.textService.legendDisplay = displayValues;
		this.textService.legendValues = values;
		this.pageService.legendDisplay = displayValues;
		this.pageService.legendValues = values;
	}

	setCategoryValues(categories: Partial<MarkerCategory>[]): void {
		const displayValues = categories.map(cat => cat.id as string);
		const values = categories.map(cat => cat._id as string);
		this.textService.categoryDisplay = displayValues;
		this.textService.categoryValues = values;
	}
}
