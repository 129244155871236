import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { MapUiButtons } from '@yuno/api/interface';

import { MapUiButtonsFacade } from '../../data-access';


@Component({
	selector: 'yuno-admin-map-ui-view',
	standalone: true,
	imports: [CommonModule, RouterModule, YunoAdminTableComponent, YunoAdminButtonsModule],
	templateUrl: './internal-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapUiComponent extends AppDataComponent implements OnInit {
	private readonly mapUiFacade = inject(MapUiButtonsFacade);

	data$ = combineLatest({
		buttons: this.mapUiFacade.mapButtons$.pipe(
			map(data => data as { [key: string]: unknown }[])
		),
		selected: this.mapUiFacade.selected$
	});

	ngOnInit(): void {
		this.mapUiFacade.get();
	}

	onSelect(row: Partial<MapUiButtons>) {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onCreate() {
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onDelete(row: Partial<MapUiButtons>) {
		this.mapUiFacade.delete(row._id as string);
	}
}
