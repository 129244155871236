import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, take } from 'rxjs';

import {
	DELETE_USER,
	GET_USERS,
	GET_USER_APPLIST,
	INVITE_USER,
	UserAppData,
	UserInvite,
	UsersQuery
} from '@yuno/admin/features/users/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { ApiService } from '@yuno/angular/api';
import { MessageService } from '@yuno/angular/notifications';

import { usersActions } from './users.actions';

export const loadUsers = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(usersActions.load),
			switchMap(() =>
				graphql
					.query<UsersQuery>({
						query: GET_USERS
					})
					.pipe(
						map(data => {
							if (!data.data.users) {
								throw new Error('no Users found');
							}
							return usersActions.loadSuccess({
								data: data.data.users
							});
						}),
						take(1),
						catchError(error => of(usersActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadUserData = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(usersActions.userData),
			switchMap(user =>
				graphql
					.query<UserAppData>({
						query: GET_USER_APPLIST,
						variables: {
							email: user.email
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getUserApps) {
								message.sendToast(`Error receiving User Data!`, 'error');
								throw new Error('no User with that id found');
							}

							return usersActions.userDataSuccess({
								data: data.data.getUserApps
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error receiving User Data!`, 'error');
							return of(usersActions.userDataFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateUserRole = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.updateRole),
			map(data => {
				if (!data.role) {
					message.sendToast(`Error updating role!`, 'error');
					throw new Error('no Role selected');
				}
				message.sendToast(`User Role successfully updated!`, 'success');
				return usersActions.updateRoleSuccess({
					role: data.role,
					index: data.index
				});
			}),
			catchError(error => of(usersActions.updateRoleFailure({ error })))
		),
	{ functional: true }
);

// ADD USER
export const addUsers = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(usersActions.addUser),
			switchMap(object =>
				graphql
					.mutate<UserInvite>({
						mutation: INVITE_USER,
						variables: {
							user: object.user
						}
					})
					.pipe(
						map(data => {
							if (!data.data) {
								message.sendToast(`Error adding user!`, 'error');
								throw new Error('no User to add');
							}

							message.sendToast(`User successfully invited!`, 'success');
							return usersActions.addUserSuccess({
								user: data.data.inviteNewUser
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(error[0]?.message || `Error adding user!`, 'error');

							return of(usersActions.userDataFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const addUserErrors = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.addUserFailure),
			map(error => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const err = error as any;
				let msg = 'Unknown error, saving to the Database!';
				if (err.error[0].message) {
					msg = err.error[0].message;
				}

				message.sendToast(`User failed to save!, <br><br> ${msg}`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

// REMOVE USER
export const deleteUser = createEffect(
	(
		store = inject(Store),
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(usersActions.deleteUser),
			switchMap(object =>
				graphql
					.mutate<UserInvite>({
						mutation: DELETE_USER,
						variables: {
							user: {
								email: object.user.email,
								role: object.user.role
							}
						}
					})
					.pipe(
						map(data => {
							if (!data.data) {
								message.sendToast(`Error deleting user!`, 'error');
							}

							store.dispatch(usersActions.load());

							message.sendToast(`User successfully deleted!`, 'success');
							return usersActions.deleteUserSuccess();
						}),
						take(1),
						catchError(error => {
							return of(usersActions.userDataFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const deleteUserErrors = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.deleteUserFailure),
			map(error => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const err = error as any;
				let msg = 'Unknown error!';
				if (err.error[0].message) {
					msg = err.error[0].message;
				}

				message.sendToast(`User failed to save!, <br><br> ${msg}`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

//  RESET PASSWORD
export const resetPassword = createEffect(
	(actions$ = inject(Actions), api = inject(ApiService)) =>
		actions$.pipe(
			ofType(usersActions.resetPassword),
			switchMap(object =>
				api
					.postObservable('auth/user/reset/request', {
						email: object.email
					})
					.pipe(
						map(() => {
							return usersActions.resetPasswordSuccess();
						}),
						take(1),
						catchError(error => {
							return of(usersActions.resetPasswordFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const resetPasswordSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.resetPasswordSuccess),
			map(() => message.sendToast(`Reset Password e-mail send!`, 'success'))
		),
	{ functional: true, dispatch: false }
);

export const resetPasswordFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.resetPasswordFailure),
			map(error =>
				message.sendToast(`Reset password e-mail failed!, ${error.error.message}`, 'error')
			)
		),
	{ functional: true, dispatch: false }
);

export const resendInvite = createEffect(
	(actions$ = inject(Actions), api = inject(ApiService)) =>
		actions$.pipe(
			ofType(usersActions.resendInvite),
			switchMap(object =>
				api
					.postObservable('auth/user/resend-invite', {
						email: object.email
					})
					.pipe(
						map(() => {
							return usersActions.resendInviteSuccess();
						}),
						take(1),
						catchError(error => {
							return of(usersActions.resendInviteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const resendInviteSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.resendInviteSuccess),
			map(() => message.sendToast(`Invite e-mail send!`, 'success'))
		),
	{ functional: true, dispatch: false }
);

export const resendInviteFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(usersActions.resendInviteFailure),
			map(error =>
				message.sendToast(`Invite e-mail failed!, ${error.error.message}`, 'error')
			)
		),
	{ functional: true, dispatch: false }
);
