import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

export const IdleBadgeAnimationTime = 500;

@Component({
	selector: 'yuno-idle-badge',
	template: `
		<div
			class="absolute left-1/2 top-6 z-50 -translate-x-1/2 transform-gpu rounded-md bg-yuno-gray-500 px-8 py-4 ring-2 ring-white ring-opacity-30 transition-all duration-500 ease-in-out">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 46 25"
				fill="currentColor"
				class="w-24 text-white opacity-80">
				<path
					d="M17.47 9.73c.7-.54 1.05-1.28 1.05-2.25 0-.66-.21-1.19-.62-1.58-.41-.39-1.03-.63-1.83-.73v-.04c.68-.16 1.21-.46 1.59-.9.38-.44.57-.97.57-1.59 0-.75-.3-1.33-.9-1.75S15.91.26 14.88.26c-1.26 0-2.35.33-3.26.98l.98 1.46c.42-.27.79-.46 1.13-.57.34-.11.67-.16 1-.16.95 0 1.42.38 1.42 1.13 0 .49-.18.84-.54 1.04-.36.2-.93.3-1.7.3h-.77v1.62h.76c.84 0 1.45.1 1.83.29.38.2.57.53.57 1 0 .52-.17.91-.5 1.14-.33.24-.85.36-1.54.36-.46 0-.92-.06-1.38-.18-.46-.12-.89-.28-1.28-.48v1.8c.84.36 1.8.54 2.89.54 1.29 0 2.29-.26 2.98-.8zM26.61 7.07c0-.99-.25-1.76-.76-2.32s-1.21-.84-2.1-.84c-.99 0-1.72.39-2.17 1.16h-.09c.04-.79.18-1.4.4-1.83.23-.43.57-.75 1.03-.97.46-.22 1.05-.33 1.77-.33.4 0 .8.05 1.2.14V.39c-.32-.07-.77-.1-1.34-.1-1.67 0-2.92.48-3.76 1.45-.84.97-1.25 2.44-1.25 4.42 0 .92.15 1.72.45 2.37.3.66.72 1.16 1.27 1.5.55.34 1.19.51 1.93.51 1.07 0 1.9-.31 2.5-.93.62-.63.92-1.48.92-2.54zM24.2 8.39c-.24.3-.59.46-1.04.46-.44 0-.8-.19-1.08-.56-.28-.37-.43-.83-.43-1.37 0-.37.15-.69.45-.97.3-.28.67-.42 1.1-.42.46 0 .8.14 1.02.42.23.28.34.66.34 1.15 0 .56-.12.99-.36 1.29zM31.04 10.53c1.19 0 2.07-.42 2.64-1.26.57-.84.86-2.13.86-3.88 0-1.69-.29-2.97-.88-3.84C33.07.67 32.2.24 31.04.24c-1.18 0-2.06.42-2.63 1.25s-.85 2.14-.85 3.9c0 1.69.29 2.97.88 3.83.58.88 1.45 1.31 2.6 1.31zm-1.06-7.77c.21-.54.57-.8 1.07-.8.49 0 .84.27 1.06.8.22.54.33 1.42.33 2.64 0 1.21-.11 2.09-.33 2.62s-.57.81-1.07.81c-.5 0-.86-.27-1.07-.8-.21-.53-.32-1.41-.32-2.63.01-1.23.11-2.11.33-2.64zM38.04 4.98c.42 0 .82-.1 1.18-.31s.65-.49.86-.86a2.359 2.359 0 0 0 0-2.37c-.21-.37-.5-.66-.87-.87a2.34 2.34 0 0 0-1.18-.31c-.43 0-.83.11-1.19.32s-.65.5-.86.87-.31.76-.31 1.18c0 .66.23 1.22.69 1.67.46.45 1.02.68 1.68.68zm-.75-3.11c.21-.21.46-.31.75-.31.3 0 .55.11.76.32.2.21.3.46.3.74 0 .28-.1.53-.31.74-.21.21-.46.31-.76.31s-.55-.1-.76-.31a1.047 1.047 0 0 1 .02-1.49z"></path>
				<path
					d="M21.58 19.93c.01-.06.02-.12.02-.19 0-.06-.02-.13-.04-.19-.01-.06-.03-.12-.05-.18-.03-.06-.07-.11-.11-.17-.03-.04-.05-.09-.08-.12l-3.49-3.54a.996.996 0 1 0-1.42 1.4l1.73 1.76c-9.19-.54-15.59-3.3-15.94-5.72-.25-1.75 2.8-3.83 8.15-5.56.53-.17.81-.73.64-1.26a.993.993 0 0 0-1.26-.64C3 7.7-.2 10.3.22 13.26c.63 4.38 9.47 7 18.3 7.46l-1.99 2.39a1.001 1.001 0 1 0 1.54 1.28l3.31-3.97c.03-.04.04-.09.07-.13.04-.06.07-.11.1-.18l.03-.18zM36.6 5.57c-.52-.18-1.09.11-1.26.63s.11 1.09.63 1.26c5.16 1.72 8.09 3.78 7.83 5.51-.41 2.71-7.89 5.74-18.36 5.93-.55.01-.99.47-.98 1.02.01.55.46.98 1 .98h.02c9.53-.18 19.57-2.85 20.3-7.63.44-2.92-2.65-5.52-9.18-7.7z"></path>
			</svg>
		</div>
	`,
	styleUrls: ['./idle-badge.component.scss'],
	animations: [
		trigger('slideIn', [
			transition(':enter', [
				style({ transform: 'translateY(-100%)' }),
				animate(
					`500ms ${IdleBadgeAnimationTime}ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateY(0)' })
				)
			]),
			transition(':leave', [
				style({ transform: 'translateY(0)' }),
				animate(
					`${IdleBadgeAnimationTime}ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateY(-100%)' })
				)
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class IdleBadgeComponent {
	@HostBinding('@slideIn') animate = true;
}
