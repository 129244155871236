module.exports = {
  A: {
    A: {
      "1": "B",
      "2": "K D E F A eC"
    },
    B: {
      "1": "4 5 6 7 8 9 M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "129": "C L"
    },
    C: {
      "1": "4 5 6 7 8 9 iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC",
      "260": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB",
      "804": "J IB K D E F A B C L M hC iC"
    },
    D: {
      "1": "4 5 6 7 8 9 oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "260": "jB kB lB mB nB",
      "388": "OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB",
      "1412": "0 1 2 3 G N O P JB y z KB LB MB NB",
      "1956": "J IB K D E F A B C L M"
    },
    E: {
      "1": "PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "129": "A B C L M G nC NC AC BC oC pC qC OC",
      "1412": "K D E F lC mC",
      "1956": "J IB jC MC kC"
    },
    F: {
      "1": "bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F uC vC",
      "260": "WB XB YB ZB aB",
      "388": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB",
      "1796": "wC xC",
      "1828": "B C AC cC yC BC"
    },
    G: {
      "1": "PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "129": "5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC",
      "1412": "E 1C 2C 3C 4C",
      "1956": "MC zC dC 0C"
    },
    H: {
      "1828": "LD"
    },
    I: {
      "1": "I",
      "388": "QD RD",
      "1956": "GC J MD ND OD PD dC"
    },
    J: {
      "1412": "A",
      "1924": "D"
    },
    K: {
      "1": "H",
      "2": "A",
      "1828": "B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z UD VD WD NC XD YD ZD aD bD DC EC FC cD",
      "260": "SD TD",
      "388": "J"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "gD",
      "260": "fD"
    }
  },
  B: 4,
  C: "CSS3 Border images",
  D: true
};