import { Injectable, inject } from '@angular/core';
import { AsyncSubject, combineLatest, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { EventsFacade } from '@yuno/admin/features/events';
import { FencesFacade } from '@yuno/admin/features/fences';
import { LegendFacade } from '@yuno/admin/features/legends';
import { MapFacade } from '@yuno/admin/features/map';
import { MapUiButtonsFacade } from '@yuno/admin/features/map-ui';
import { ParticipationModelFacade } from '@yuno/admin/features/participation';
import { SidemenuFacade } from '@yuno/admin/features/sidemenus/data-access';
import { StatesFacade } from '@yuno/admin/features/states';
import { TextblockFacade, TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import { Theme } from '@yuno/api/interface';

import { ThemeFacade } from '../../data-access';
import { tabOptions } from './theme-editor.component';
import { ThemeEditorFormService } from './theme-editor.form.service';

@Injectable()
export class ThemeEditorComponentService {
	private readonly appFacade = inject(AppFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly textblockFacade = inject(TextblockFacade);
	private readonly participationFacade = inject(ParticipationModelFacade);
	private readonly legendFacade = inject(LegendFacade);
	private readonly fenceFacade = inject(FencesFacade);
	private readonly stateFacade = inject(StatesFacade);
	private readonly sidemenuFacade = inject(SidemenuFacade);
	private readonly mapFacade = inject(MapFacade);
	private readonly mapButtonsFacade = inject(MapUiButtonsFacade);
	private readonly eventsFacade = inject(EventsFacade);

	readonly formService = inject(ThemeEditorFormService);
	private _menusCreated = new AsyncSubject<boolean>();

	originalData?: Partial<Theme>;
	desktopView = true;
	tabValue: tabOptions = 'Settings';

	language$ = this.appFacade.language$.pipe(startWith('nl'));
	theme$ = this.themeFacade.selectedTheme$.pipe(
		tap(data => {
			if (!this.originalData) {
				this.originalData = data;

				this.formService.addDatasets(data?.datasets);
				this.formService.addMapButtons(data?.controls);
				this.formService.addAvailableStates(data?.states?.available);
				this.formService.addModuleParticipationDatas(data?.modules?.participation?.data);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.formService.form.patchValue(this.originalData as any);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.formService.updateMapStyles(this.originalData as any);

				if (
					this.originalData?.view?.maxBounds &&
					this.originalData?.view?.maxBounds.length > 1
				) {
					this.formService.updateBounds(this.originalData.view.maxBounds, 'maxBounds');
				}
			}
		})
	);
	sidemenusCreated$ = this._menusCreated.asObservable().pipe(startWith(false));
	events$ = this.eventsFacade.toggle$.pipe(startWith(false));

	data$ = combineLatest({
		bounds: this.themeFacade.bounds$.pipe(
			tap(bounds => {
				if (!bounds) {
					return;
				}
				this.formService.updateBounds(bounds, 'bounds');
			})
		),
		maxBounds: this.themeFacade.maxBounds$.pipe(
			tap(bounds => {
				if (!bounds) {
					return;
				}
				this.formService.updateBounds(bounds, 'maxBounds');
			})
		),
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(data => {
				if (data) {
					this.formService.textfieldValues = [
						'',
						...data.map(text => text._id)
					] as string[];
					this.formService.textfieldDisplay = [
						'none',
						...data.map(text => text.id)
					] as string[];
				}
			})
		),
		textblocks: this.textblockFacade.allTextblocks$.pipe(
			tap(data => {
				this.formService.textblockDisplay = [
					'none',
					...data.map(text => text.id)
				] as string[];
				this.formService.textblockValues = ['', ...data.map(text => text._id)] as string[];
			})
		),
		legend: this.legendFacade.legends$.pipe(
			tap(data => {
				this.formService.legendDisplay = [
					'none',
					...data.map(object => object.id)
				] as string[];
				this.formService.legendValues = ['', ...data.map(object => object._id)] as string[];
			})
		),
		states: this.stateFacade.states$.pipe(
			tap(data => {
				this.formService.statesValues = ['', ...data.map(state => state._id)] as string[];
				this.formService.statesDisplay = [
					'none',
					...data.map(state => state.state)
				] as string[];
			})
		),
		datasets: this.datasetFacade.datasets$.pipe(
			tap(data => {
				this.formService.datasetValues = data.map(data => data._id) as string[];
				this.formService.datasetDisplay = data.map(data => data.id) as string[];
			})
		),
		mapStyles: this.mapFacade.mapStyles$.pipe(
			tap(data => {
				this.formService.styleDisplay = data?.map(style => style.id) as string[];
				this.formService.styleValues = data?.map(style => style._id) as string[];
			})
		),
		mapUiButtons: this.mapButtonsFacade.mapButtons$.pipe(
			tap(data => {
				this.formService.mapButtonDisplay = data?.map(button => button.id) as string[];
				this.formService.mapButtonValues = data?.map(button => button._id) as string[];
			})
		),
		fences: this.fenceFacade.fences$.pipe(
			tap(data => {
				this.formService.fencesDisplay = [
					'none',
					...data.map(fence => fence.id)
				] as string[];
				this.formService.fencesValues = ['', ...data.map(fence => fence._id)] as string[];
			})
		),
		participation: this.participationFacade.data$.pipe(
			tap(data => {
				this.formService.participateDisplay = data.map(data => data.id) as string[];
				this.formService.participateValues = data.map(data => data._id) as string[];
			})
		),
		sidemenus: this.sidemenuFacade.data$.pipe(
			tap(data => {
				this.formService.sidemenuDisplay = [
					'none',
					...data.map(text => text.id)
				] as string[];
				this.formService.sidemenuValues = ['', ...data.map(text => text._id)] as string[];
				if (data && data.length >= 1) {
					this._menusCreated.next(true);
					this._menusCreated.complete();
				}
			})
		)
	});

	get form() {
		return this.formService.form;
	}

	onInit() {
		this.mapFacade.getMapStyles();
		this.textfieldFacade.get();
		this.textblockFacade.get();
		this.participationFacade.get();
		this.legendFacade.getLegendsByAppId();
		this.stateFacade.get();
		this.datasetFacade.get();
		this.fenceFacade.get();
		this.sidemenuFacade.get();
		this.mapButtonsFacade.get();
		this.formService.createFormGroup();
	}

	onSwitchTab(key: string) {
		this.tabValue = key as tabOptions;
	}

	onSelect(id: string | null) {
		this.themeFacade.select(id);
	}

	onUpdate() {
		this.themeFacade.updateSelect(this.form.getRawValue() as Partial<Theme>);
	}

	onSave() {
		this.themeFacade.save();
	}

	onClose(): void {
		this.themeFacade.clearSelect();
		this.mapFacade.clearStyle();
	}

	onClear() {
		this.themeFacade.clearSelect();
	}
	onToggleService(index: number) {
		this.formService.eventIndex = index;
		this.eventsFacade.setKey('events');
		this.eventsFacade.toggleEvents(true);
	}
}
