import { createFeature, createReducer, on } from '@ngrx/store';

import { layoutActions } from './layout.actions';

export interface LayoutState {
	sidemenu: {
		open: boolean;
	};
	sideoptions: {
		open: boolean;
		visible: boolean;
	};
	language: string;
	mode: 'dark' | 'light';
}

export const initialState: LayoutState = {
	sidemenu: {
		open: true
	},
	sideoptions: {
		open: false,
		visible: false
	},
	language: 'nl',
	mode: 'light'
};

const reducer = createReducer(
	initialState,
	on(layoutActions.open, state => ({
		...state,
		sidemenu: { open: true }
	})),
	on(layoutActions.close, state => ({
		...state,
		sidemenu: { open: false }
	})),
	on(layoutActions.toggle, (state, action) => ({
		...state,
		sidemenu: { open: action.toggle }
	})),
	on(layoutActions.optionsToggle, (state, action) => ({
		...state,
		sideoptions: {
			open: action.toggle,
			visible: state.sideoptions.visible
		}
	})),
	on(layoutActions.optionsActive, (state, action) => ({
		...state,
		sideoptions: {
			open: state.sideoptions.open,
			visible: action.active
		}
	})),
	on(layoutActions.language, (state, action) => ({
		...state,
		language: action.language
	})),
	on(layoutActions.mode, (state, action) => ({
		...state,
		mode: action.mode
	}))
);

export const layoutFeature = createFeature({
	name: 'layout',
	reducer
});
