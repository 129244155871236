import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

import { EventForm, EventFormsService } from '@yuno/admin/features/events';
import { TextPageComponentService } from '@yuno/admin/features/textfield-pages/data-access/components.service';
import { PageComponentForm } from '@yuno/admin/features/textfield-pages/data-access/pages-editor.service';
import { ContentKeys } from '@yuno/admin/features/textfield-pages/feature/content/types';
import { newLanguageFormGroup } from '@yuno/angular/forms';
import {
	CdnFile,
	LanguageType,
	PagesComponentKeys,
	TextListItemComponents,
	Textfield,
	TextfieldComponents
} from '@yuno/api/interface';

import { ButtonForm } from '../feature/item-editors/button-editor/button.interface';
import { CustomTextForm } from '../feature/item-editors/custom-text-editor/custom-text.interface';
import { MediaImageForm } from '../feature/item-editors/image-editor/image.interface';
import { LegendForm } from '../feature/item-editors/legend-editor/legend.interface';
import { LinkForm } from '../feature/item-editors/link-editor/link.interface';
import { ListForm, ListItemForm } from '../feature/item-editors/list-editor/list.interface';
import { NotificationForm } from '../feature/item-editors/notification-editor/notification.interface';
import { PredefinedForm } from '../feature/item-editors/predefined-editor/predefined.interface';
import { TextblockForm } from '../feature/item-editors/textblock-editor/textblock.interface';
import {
	ToggleItemForm,
	TogglesForm
} from '../feature/item-editors/toggle-editor/toggles.interface';
import { MediaVideoForm } from '../feature/item-editors/video-editor/video.interface';

export interface TextfieldForm {
	id: FormControl<string>;
	_id: FormControl<string | null>;
	// appId: FormControl<string>;
	public: FormControl<boolean>;
	components: FormArray<FormGroup<TextfieldComponentForm>>;
}

export interface TextfieldComponentForm {
	button?: FormGroup<ButtonForm>;
	container?: FormControl<string | null>;
	custom?: FormGroup<CustomTextForm>;
	image?: FormGroup<MediaImageForm>;
	link?: FormGroup<LinkForm>;
	list?: FormGroup<ListForm>;
	textblock?: FormGroup<TextblockForm>;
	toggles?: FormGroup<TogglesForm>;
	video?: FormGroup<MediaVideoForm>;
	legend?: FormGroup<LegendForm>;
	notification?: FormGroup<NotificationForm>;
	predefined?: FormGroup<PredefinedForm>;
}

@Injectable({
	providedIn: 'root'
})
export class TextfieldEditorService {
	private readonly eventsForm = inject(EventFormsService);
	private readonly componentsService = inject(TextPageComponentService);

	_closed = new Subject<string>();
	closed$ = this._closed.asObservable();
	active$ = new BehaviorSubject<boolean>(false);

	form: FormGroup<TextfieldForm>;
	language: LanguageType;

	// BUTTON COMPONENT
	buttonAlignSelectValues: string[] = ['left', 'right', 'default'];
	buttonAlignSelectDisplay: string[] = ['Left', 'Right', 'Default'];

	// LINK COMPONENT
	linkSelectValues: string[] = [''];
	linkSelectDisplay: string[] = ['none'];

	// TOGGLES COMPONENT
	toggleLayerValues: string[] = [];
	toggleLayerDisplay: string[] = [];
	toggleDatasetValues: string[] = [];
	toggleDatasetDisplay: string[] = [];

	// VIDEO COMPONENT
	videoSelectValues: string[] = ['vimeo', 'youtube'];
	videoSelectDisplay: string[] = ['Vimeo', 'YouTube'];

	// TEXTFIELD
	textfieldValues: string[] = [];
	textfieldDisplay: string[] = [];

	// LEGEND
	legendValues: string[] = [];
	legendDisplay: string[] = [];

	// PLACE MARKER CATEGORIES
	categoryValues: string[] = [];
	categoryDisplay: string[] = [];

	// FILES
	filesValues: CdnFile[] = [];
	filesDisplay: string[] = [];

	// IMAGEBUTTONS
	linkTypeSelectValues: string[] = ['url', 'page', 'navigation', 'privacy', 'privacy-client'];
	linkTypeSelectDisplay: string[] = ['Url', 'Page', 'Routes', 'Privacy', 'Privacy-client'];
	linkPageSelectValues: string[] = [];
	linkPageSelectDisplay: string[] = [];
	navigationSelectValues: string[] = [];
	navigationSelectDisplay: string[] = [];
	imageButtonSelectValues: string[] = [];
	imageButtonSelectDisplay: string[] = [];

	componentIndex: number | undefined;
	componentType: string | undefined;

	eventOrigin: 'button' | 'list' = 'button';
	eventType: 'events' | 'activeEvents' = 'events';
	eventIndex = 0;

	get components(): FormArray<FormGroup<TextfieldComponentForm>> {
		return this.form.get('components') as FormArray<FormGroup<TextfieldComponentForm>>;
	}

	get component() {
		if (this.componentIndex === undefined || !this.componentType) {
			return null;
		}

		return this.components.controls
			.at(this.componentIndex as number)
			?.get(this.componentType) as FormGroup;
	}

	/* Current EVENT */
	get events(): FormArray<FormGroup<EventForm>> | null {
		if (!this.component) {
			return null;
		}

		if (this.eventOrigin === 'button') {
			return this.component.get(this.eventType) as FormArray<FormGroup<EventForm>>;
		}

		if (this.eventOrigin === 'list') {
			if (!this.listItems) {
				return null;
			}
			return this.listItems.controls[this.eventIndex].get(this.eventType) as FormArray<
				FormGroup<EventForm>
			>;
		}

		return new FormArray<FormGroup<EventForm>>([]);
	}

	// List
	get listItems(): FormArray<FormGroup<ListItemForm>> {
		if (!this.component) {
			return new FormArray<FormGroup<ListItemForm>>([]);
		}
		return this.component.get('items') as FormArray<FormGroup<ListItemForm>>;
	}

	// List
	get toggleItems(): FormArray<FormGroup<ToggleItemForm>> {
		if (!this.component) {
			return new FormArray<FormGroup<ToggleItemForm>>([]);
		}
		return this.component.get('items') as FormArray<FormGroup<ToggleItemForm>>;
	}

	createFormGroup(): void {
		this.form = new FormGroup<TextfieldForm>({
			_id: new FormControl(null),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			// appId: new FormControl('', { nonNullable: true }),
			components: new FormArray<FormGroup<TextfieldComponentForm>>([]),
			public: new FormControl<boolean>(true, { nonNullable: true })
		});
	}

	// Available States
	remove(index: number): void {
		this.components.removeAt(index);
	}

	addComponent(key: ContentKeys, component?: TextfieldComponents, index?: number): void {
		const form = this.componentsService.componentCreator(key as ContentKeys, component);

		if (form && form.type) {
			const group = this.createTextfieldComponentForm(form.type, form.formControl);

			// adds the component to the components array at a specific index
			this.components.insert(index || this.components?.value.length, group);
		}
	}

	createTextfieldComponentForm(
		type: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		control: FormGroup<any> | FormControl<any>
	): FormGroup<PageComponentForm> {
		return new FormGroup<TextfieldComponentForm>({
			[type]: control
		});
	}

	addComponents(components?: Textfield['components']): void {
		this.components.clear();
		if (!components) {
			return;
		}

		for (const component of components) {
			const key = this.componentsService.getComponentKey(component);
			if (!key) {
				continue;
			}

			const form = this.componentsService.componentCreator(
				key as PagesComponentKeys,
				component
			);
			if (form && form.type) {
				const group = this.createTextfieldComponentForm(form.type, form.formControl);
				// adds the component to the components array at a specific index
				this.components.insert(this.components?.value.length, group);
			}
		}
	}

	addListItem(index?: number, item?: TextListItemComponents) {
		if (index !== undefined && index >= 0) {
			const component = this.components.controls.at(index);
			if (!component) {
				return;
			}
			const items = component.get('list')?.get('items') as FormArray<FormGroup<ListItemForm>>;
			items.push(
				new FormGroup<ListItemForm>({
					active: new FormControl(false, { nonNullable: true }),
					id: new FormControl('', { nonNullable: true }),
					title: newLanguageFormGroup(),
					events: this.eventsForm.createEventForm()
				})
			);

			if (item?.events && item?.events?.length >= 1) {
				const count = item?.events.length;
				if (count > 0) {
					for (let j = 0; j < count; j++) {
						const array = items.controls[items.length - 1].get('events') as FormArray<
							FormGroup<EventForm>
						>;
						if (array) {
							array.push(this.eventsForm.createEvent(item.events[j].type));
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							array.controls[j].patchValue(item.events[j] as any);
						}
					}
				}
			}
		} else if (this.listItems) {
			this.listItems.push(
				new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					id: new FormControl('', { nonNullable: true }),
					title: newLanguageFormGroup(),
					events: this.eventsForm.createEventForm()
				})
			);
		}
	}

	// TOGGLES OPTIONS
	removeToggleItem(i: number): void {
		if (this.toggleItems) {
			this.toggleItems.removeAt(i);
		}
	}

	addToggleItem(index?: number) {
		if (index !== undefined && index >= 0) {
			const component = this.components.controls.at(index);
			if (!component) {
				return;
			}
			const items = component.get('toggles')?.get('items') as FormArray;
			items.push(
				new FormGroup<ToggleItemForm>({
					active: new FormControl(false, { nonNullable: true }),
					group: new FormControl(false, { nonNullable: true }),
					public: new FormControl(true, { nonNullable: true }),
					type: new FormControl('layer', { nonNullable: true }),
					title: newLanguageFormGroup(),
					dataset: new FormArray<FormControl<string>>([]),
					layer: new FormArray<FormControl<string>>([]),
					items: new FormArray<FormGroup<Omit<ToggleItemForm, 'items'>>>([]),
					color: new FormControl(null)
				})
			);
		} else if (!index && this.toggleItems) {
			this.toggleItems.push(
				new FormGroup<ToggleItemForm>({
					active: new FormControl(false, { nonNullable: true }),
					group: new FormControl(false, { nonNullable: true }),
					public: new FormControl(true, { nonNullable: true }),
					type: new FormControl('layer', { nonNullable: true }),
					title: newLanguageFormGroup(),
					dataset: new FormArray<FormControl<string>>([]),
					layer: new FormArray<FormControl<string>>([]),
					items: new FormArray<FormGroup<Omit<ToggleItemForm, 'items'>>>([]),
					color: new FormControl(null)
				})
			);
		}
	}

	// TOGGLES LAYERS
	removeToggleLayer(i: number, parentIndex: number): void {
		if (this.toggleItems) {
			const layers = this.toggleItems.controls[parentIndex]?.get('layer') as FormArray;
			layers.removeAt(i);
		}
	}

	addToggleLayer(index: number, componentIndex?: number) {
		if (componentIndex !== undefined && componentIndex >= 0) {
			const component = this.components.controls.at(componentIndex);
			if (!component) {
				return;
			}
			const items = component.get('toggles')?.get('items') as FormArray;
			const layers = items.controls[index].get('layer') as FormArray;

			layers.push(new FormControl<string>('', { nonNullable: true }));
		} else if (this.toggleItems && this.toggleItems.length >= 1) {
			const layers = this.toggleItems.controls[index]?.get('layer') as FormArray;
			layers.push(new FormControl<string>('', { nonNullable: true }));
		}
	}

	// TOGGLES DATASETS
	removeToggleDataset(i: number, parentIndex: number): void {
		if (this.toggleItems) {
			const datasets = this.toggleItems.controls[parentIndex]?.get('dataset') as FormArray;
			datasets.removeAt(i);
		}
	}

	addToggleDataset(index: number, componentIndex?: number) {
		if (componentIndex !== undefined && componentIndex >= 0) {
			const component = this.components.controls.at(componentIndex);
			if (!component) {
				return;
			}
			const items = component.get('toggles')?.get('items') as FormArray;
			const datasets = items.controls[index].get('dataset') as FormArray;

			datasets.push(new FormControl<string>('', { nonNullable: true }));
		} else if (this.toggleItems && this.toggleItems.length >= 1) {
			const datasets = this.toggleItems.controls[index]?.get('dataset') as FormArray;
			datasets.push(new FormControl<string>('', { nonNullable: true }));
		}
	}

	// TOGGLES [SUB] ITEMS
	removeToggleSubItem(i: number, parentIndex: number): void {
		if (this.toggleItems) {
			const subItems = this.toggleItems.controls[parentIndex]?.get('items') as FormArray;
			subItems.removeAt(i);
		}
	}

	addToggleSubItem(itemIndex: number, componentIndex?: number) {
		if (componentIndex !== undefined && componentIndex >= 0) {
			const component = this.components.controls.at(componentIndex);
			if (!component) {
				return;
			}
			const items = component.get('toggles')?.get('items') as FormArray;
			const subItems = items.controls[itemIndex]?.get('items') as FormArray;

			subItems.push(
				new FormGroup<Omit<ToggleItemForm, 'items'>>({
					active: new FormControl(false, { nonNullable: true }),
					group: new FormControl(false, { nonNullable: true }),
					public: new FormControl(true, { nonNullable: true }),
					type: new FormControl('layer', { nonNullable: true }),
					title: newLanguageFormGroup(),
					dataset: new FormArray<FormControl<string>>([]),
					layer: new FormArray<FormControl<string>>([]),
					color: new FormControl(null)
				})
			);
		} else if (this.toggleItems) {
			const subItems = this.toggleItems.controls[itemIndex]?.get('items') as FormArray;
			if (subItems) {
				subItems.push(
					new FormGroup<Omit<ToggleItemForm, 'items'>>({
						active: new FormControl(false, { nonNullable: true }),
						group: new FormControl(false, { nonNullable: true }),
						public: new FormControl(true, { nonNullable: true }),
						type: new FormControl('layer', { nonNullable: true }),
						title: newLanguageFormGroup(),
						dataset: new FormArray<FormControl<string>>([]),
						layer: new FormArray<FormControl<string>>([]),
						color: new FormControl(null)
					})
				);
			}
		}
	}

	// TOGGLES [SUB] LAYERS
	removeToggleSubLayer(i: number, subIndex: number, parentIndex: number): void {
		if (this.toggleItems) {
			const subItems = this.toggleItems.controls[parentIndex]?.get('items') as FormArray;
			const layers = subItems.controls[subIndex]?.get('layer') as FormArray;
			layers.removeAt(i);
		}
	}

	addToggleSubLayer(index: number, itemIndex: number, componentIndex?: number) {
		if (componentIndex !== undefined && componentIndex >= 0) {
			const component = this.components.controls.at(componentIndex);
			if (!component) {
				return;
			}
			const items = component.get('toggles')?.get('items') as FormArray;
			const subItems = items.controls[itemIndex]?.get('items') as FormArray;
			const layers = subItems.controls[index].get('layer') as FormArray;

			layers.push(new FormControl<string>('', { nonNullable: true }));
		} else if (this.toggleItems) {
			const subItems = this.toggleItems.controls[itemIndex]?.get('items') as FormArray;
			const layers = subItems?.controls[index].get('layer') as FormArray;
			if (layers) {
				layers.push(new FormControl<string>('', { nonNullable: true }));
			}
		}
	}

	// TOGGLES [SUB] DATASETS
	removeToggleSubDataset(i: number, subIndex: number, parentIndex: number): void {
		if (this.toggleItems) {
			const subItems = this.toggleItems.controls[parentIndex]?.get('items') as FormArray;
			const datasets = subItems.controls[subIndex]?.get('dataset') as FormArray;
			datasets.removeAt(i);
		}
	}

	addToggleSubDataset(index: number, itemIndex: number, componentIndex?: number) {
		if (componentIndex !== undefined && componentIndex >= 0) {
			const component = this.components.controls.at(componentIndex);
			if (!component) {
				return;
			}
			const items = component.get('toggles')?.get('items') as FormArray;
			const subItems = items.controls[itemIndex]?.get('items') as FormArray;
			const datasets = subItems.controls[index].get('dataset') as FormArray;

			datasets.push(new FormControl<string>('', { nonNullable: true }));
		} else if (this.toggleItems) {
			const subItems = this.toggleItems.controls[itemIndex]?.get('items') as FormArray;
			const datasets = subItems.controls[index].get('dataset') as FormArray;
			datasets.push(new FormControl<string>('', { nonNullable: true }));
		}
	}

	// LIST OPTIONS
	removeListItem(i: number): void {
		if (this.listItems) {
			this.listItems.removeAt(i);
		}
	}
}
