import { gql } from 'apollo-angular';

import { Fence } from '@yuno/api/interface';

export const GET_FENCES_BY_APPID = gql`
	query getFencesByAppId($appId: String!) {
		fences(appId: $appId) {
			_id
			id
			minZoom
			maxZoom
			bearing
			pitch
		}
	}
`;

export type FencesQuery = {
	fences: Partial<Fence>[];
};

export const GET_FENCE_BY_ID = gql`
	query getFenceById($_id: String!) {
		selectedFence(_id: $_id) {
			_id
			id
			polygon
			style {
				color
				opacity
			}
			minZoom
			maxZoom
			bearing
			pitch
			featureId
		}
	}
`;

export const GET_FENCES_BY_ID = gql`
	query getFencesById($ids: [String!]!) {
		getFencesById(ids: $ids) {
			_id
			id
			polygon
			minZoom
			maxZoom
			bearing
			pitch
			featureId
		}
	}
`;

export type SelectFenceQuery = {
	selectedFence: Partial<Fence>;
};
export type SelectFencesQuery = {
	getFencesById: Partial<Fence>[];
};
