import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { ApiService } from '@yuno/angular/api';
import { MessageService } from '@yuno/angular/notifications';
import { RoutesVariantsDto } from '@yuno/api/dto';
import { Navigation } from '@yuno/api/interface';

import { SAVE_NAVIGATION } from './../../utils/graphql';
import { variantsActions } from './variants.actions';
import { variantsFeature } from './variants.state';

export type VariantsQuery = {
	navigationAdmin: Partial<Navigation>;
};

export type SaveNavigationMutation = {
	saveNavigation: Partial<Navigation>;
};

export const load = createEffect(
	(actions$ = inject(Actions), api = inject(ApiService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(variantsActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				api.getObservable<RoutesVariantsDto>(`routes/variants/${appId}`).pipe(
					map(data => {
						return variantsActions.loadSuccess({
							data
						});
					}),
					take(1),
					catchError(error => of(variantsActions.loadFailure({ error })))
				)
			)
		);
	},
	{ functional: true }
);

export const save = createEffect(
	(
		actions$ = inject(Actions),
		graphql = inject(GraphQLService),
		message = inject(MessageService),
		store = inject(Store)
	) => {
		return actions$.pipe(
			ofType(variantsActions.save),
			withLatestFrom(
				store.pipe(select(variantsFeature.selectNavigation)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, nav, appId]) =>
				graphql
					.mutate<SaveNavigationMutation>({
						mutation: SAVE_NAVIGATION,
						variables: {
							navigation: nav,
							appId: appId
						}
					})
					.pipe(
						map(navigation => {
							if (!navigation.data?.saveNavigation) {
								message.sendToast(`Error saving Navigation!`, 'error');
								throw new Error('error saving Navigation to the database');
							}
							message.sendToast(`Navigation successfully saved!`, 'success');
							return variantsActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(variantsActions.saveFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);
