module.exports = {
  A: {
    A: {
      "2": "K D E F A B eC"
    },
    B: {
      "1": "9 AB BB CB DB EB FB GB HB I",
      "2": "C L M G N",
      "164": "4 5 6 7 8 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "3138": "O",
      "12292": "P"
    },
    C: {
      "1": "4 5 6 7 8 9 lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC",
      "260": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB hC iC"
    },
    D: {
      "1": "9 AB BB CB DB EB FB GB HB I 9B KC LC",
      "164": "0 1 2 3 4 5 6 7 8 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    E: {
      "1": "PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "jC MC",
      "164": "J IB K D E F A B C L M G kC lC mC nC NC AC BC oC pC qC OC"
    },
    F: {
      "1": "p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "164": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o"
    },
    G: {
      "1": "PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "164": "E MC zC dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "164": "QD RD",
      "676": "GC J MD ND OD PD dC"
    },
    J: {
      "164": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "CC"
    },
    P: {
      "1": "3",
      "164": "0 1 2 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "164": "dD"
    },
    R: {
      "164": "eD"
    },
    S: {
      "1": "gD",
      "260": "fD"
    }
  },
  B: 4,
  C: "CSS Masks",
  D: true
};