import { InjectionToken } from '@angular/core';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';

import { LanguageTypeAll, TextfieldComponentsTypes } from '@yuno/api/interface';

import {
	TextBlockComponent,
	TextButtonComponent,
	TextCategoryComponent,
	TextCollapsibleComponent,
	TextEmbedComponent,
	TextImageButtonComponent,
	TextImageComponent,
	TextLegendComponent,
	TextLinkComponent,
	TextListComponent,
	TextNewsItemComponent,
	TextNotificationComponent,
	TextPageItemComponent,
	TextPredefinedComponent,
	TextTogglesComponent,
	TextVideoComponent
} from './components';

export const TextfieldToken = new InjectionToken<TextfieldInjectionToken>('');
export type TextfieldCompType =
	| typeof TextBlockComponent
	| typeof TextButtonComponent
	| typeof TextCollapsibleComponent
	| typeof TextEmbedComponent
	| typeof TextImageComponent
	| typeof TextLinkComponent
	| typeof TextListComponent
	| typeof TextTogglesComponent
	| typeof TextVideoComponent
	| typeof TextPageItemComponent
	| typeof TextNewsItemComponent
	| typeof TextLegendComponent
	| typeof TextCategoryComponent
	| typeof TextNotificationComponent
	| typeof TextImageButtonComponent
	| typeof TextPredefinedComponent;

export type PageOptions = {
	order?: number;
	orderMobile?: number;
	pageSide?: 'left' | 'right';
};

export class TextfieldInjectionToken {
	data: TextfieldComponentsTypes;
	component: TextfieldCompType;
	feature?: Feature | MapGeoJSONFeature;
	language?: LanguageTypeAll;
	page?: PageOptions;
	colors?: {
		textLinkColor?: string;
		textHeadingColor?: string;
		buttonColor?: string;
		buttonBgColor?: string;
	};
}
