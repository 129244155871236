<router-outlet></router-outlet>
@if (toggleTemplates) {
	<yuno-admin-templates
		[selector]="'textfield'"
		(bgClicked)="toggleTemplates = false"
		(clicked)="onCreate($event)"></yuno-admin-templates>
}

@if (data$ | async; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="true"
		[pagination]="true"
		[pageOptions]="{
			pageSize: 25,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="data.textfields"
		[buttons]="tableButtons"
		[buttonsWidth]="5.5"
		[columns]="[
			{ key: 'id', label: 'textfield' },
			{ key: 'updatedBy', label: 'last modified by', child: 'displayName' },
			{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
		]"
		(clicked)="onSelectTextfield($event)">
		@if (minimalAppRole(userRoles.EDITOR)) {
			<button yuno-admin-button (click)="onSelectTemplate(true)" [add]="true">
				New Textfield
			</button>
		}
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onSelectTextfield(row)"></button>
</ng-template>
