import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';

import {
	Event,
	LanguageStringsModel,
	TextButton,
	TextfieldButtonAlignment
} from '@yuno/api/interface';

import { YunoButtonComponent } from '../../../../../../project-atlas/ui/src/lib/button/button.component';
import { LanguagePipe } from '../../../../../pipes/src/lib/language/language.pipe';
import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';

@Component({
	selector: 'yuno-textfield-button',
	template: `
		<yuno-button
			class="yuno__textfield__styling"
			[color]="clientColors ? colors?.buttonColor : undefined"
			[backgroundColor]="clientColors ? colors?.buttonBgColor : undefined"
			(click)="btnClick()">
			<ng-content></ng-content>
			{{ getContent() | languageSelect: language }}
		</yuno-button>
	`,
	styleUrls: ['./text-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [YunoButtonComponent, LanguagePipe]
})
export class TextButtonComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private event = inject(EventsService);
	private cdr = inject(ChangeDetectorRef);

	private _data?: TextButton;
	private _feature?: Feature | MapGeoJSONFeature;

	active = false;

	@Input() language?: string;
	@Input() disableInjection = false;

	@Input() content?: LanguageStringsModel;
	@Input() contentActive?: LanguageStringsModel;

	@Input() colors?: { buttonColor: string | undefined; buttonBgColor: string | undefined } = {
		buttonColor: undefined,
		buttonBgColor: undefined
	};
	@Input() clientColors = false;

	@Output() clickEvents = new EventEmitter<Event[] | void>();

	@HostBinding('class') private _className: string;
	@Input() @HostBinding('class.flex-1') alignBottom: boolean;

	@Input() set alignment(align: TextfieldButtonAlignment) {
		switch (align) {
			case 'left':
				this._className = 'justify-start';
				break;
			case 'right':
				this._className = 'justify-end';
				break;
			case 'default':
			default:
				this._className = 'justify-center';
				break;
		}
	}

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue.data &&
			this.handleInjectionData(
				this.injectValue.data as TextButton,
				this.injectValue.language,
				this.injectValue.feature,
				this.injectValue.colors
			);
	}

	/**
	 * Setup the Component by using the Injection Method
	 *
	 * @param {TextButton} data
	 * @param {TextButton} language
	 * @param {TextButton} feature
	 * @param {TextButton} colors
	 *
	 */
	handleInjectionData(
		data: TextButton,
		language?: string,
		feature?: Feature | MapGeoJSONFeature,
		colors?: {
			textLinkColor?: string;
			textHeadingColor?: string;
			buttonColor?: string;
			buttonBgColor?: string;
		}
	) {
		this.language = language;
		this._feature = feature;
		this._data = data as TextButton;
		this.alignBottom = !!data?.alignBottom;
		this.alignment = data.align || 'default';
		this.content = data.content;

		if (data.clientColors !== undefined) {
			this.clientColors = data.clientColors;
		}
		if (this.colors) {
			this.colors.buttonColor = colors?.buttonColor;
			this.colors.buttonBgColor = colors?.buttonBgColor;
		}

		this.contentActive = data.activeContent;
	}

	getContent(): LanguageStringsModel {
		const toggle = this._data?.activeToggle === undefined ? true : this._data?.activeToggle;
		return this.active && toggle ? this.contentActive : this.content;
	}

	btnClick(): void {
		if (this._data?.activeEvents && this._data?.activeEvents.length < 1) {
			this.defaultClickEvents();
			return;
		}

		if (!this.active) {
			this.defaultClickEvents();
			return;
		}

		this.activeClickEvent();
	}

	defaultClickEvents(): void {
		this.clickEvents.emit(this._data?.events);

		if (this._data?.events && this._data.events.length) {
			this.event.sendEvent(this._data.events, this._feature);
		}

		this.active = true;
		this.cdr.detectChanges();
	}

	activeClickEvent(): void {
		this.clickEvents.emit(this._data?.activeEvents);

		if (this._data?.activeEvents && this._data.activeEvents.length) {
			this.event.sendEvent(this._data.activeEvents, this._feature);
		}

		this.active = false;
		this.cdr.detectChanges();
	}
}
