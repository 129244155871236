<router-outlet></router-outlet>

@if (data$ | async; as data) {
	<section class="grid grid-cols-1">
		<yuno-admin-table
			[selectable]="false"
			[draggable]="false"
			[filterable]="true"
			[sortable]="false"
			[pagination]="true"
			[pageOptions]="{
				pageSize: 100,
				pageSizeOptions: [5, 10, 25, 100],
				hidePageSize: true
			}"
			[data]="getData(data.list)"
			[buttons]="tableButtons"
			[columns]="[
				{ key: 'id', label: 'Name' },
				{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
			]"
			(clicked)="onEdit($event)">
			<button yuno-admin-add-button [routerLink]="['./create/']">
				New Notification Setup
			</button>
		</yuno-admin-table>
	</section>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-view-button (click)="onEdit(row)"></button>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onEdit(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
