import { DatePipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageStringsModel, PredefinedSummary } from '@yuno/api/interface';

@Component({
	selector: 'yuno-predefined-date-summary',
	standalone: true,
	imports: [DatePipe, LanguagePipe, RouterLink, NgStyle],
	template: ` <h4 class="font-semibold empty:hidden">{{ title | languageSelect: language }}</h4>
		@for (item of list; track item.content) {
			<div class="flex h-10 gap-2 border-t-2">
				<div class="w-30 p-2 text-right font-semibold text-yuno-secondary empty:hidden">
					{{ item.date | date: 'd MMM yyyy' }}
				</div>
				<div class="p-2 text-left">
					@if (!(item.url || item.page)) {
						<span>{{ item.content | languageSelect: language }}</span>
					}
					@if (item.url) {
						<a
							[href]="item.url.startsWith('http') ? item.url : 'https://' + item.url"
							target="_blank">
							{{ item.content | languageSelect: language }}
						</a>
					}
					@if (item.page) {
						<a [routerLink]="item.page" [ngStyle]="{ color: '#3498DB' }">
							{{ item.content | languageSelect: language }}
						</a>
					}
				</div>
			</div>
		}
		@if (list.length >= 1) {
			<div class="ignore-hidden mb-4 border-t-2"></div>
		}`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedDateSummaryComponent {
	@Input() language: string | undefined;
	@Input() title: LanguageStringsModel | undefined;
	@Input() list: PredefinedSummary['list'];
}

@Component({
	selector: 'yuno-predefined-summary',
	standalone: true,
	imports: [DatePipe, LanguagePipe, RouterLink, NgStyle],
	template: ` <h4 class="font-semibold empty:hidden">
			{{ title | languageSelect: language }}
		</h4>
		<ul>
			@for (item of list; track item.content) {
				<li>
					@if (!(item.url || item.page)) {
						<span>{{ item.content | languageSelect: language }}</span>
					}
					@if (item.url) {
						<a
							[href]="item.url.startsWith('http') ? item.url : 'https://' + item.url"
							target="_blank">
							{{ item.content | languageSelect: language }}
						</a>
					}
					@if (item.page) {
						<a [routerLink]="item.page" [ngStyle]="{ color: '#3498DB' }">
							{{ item.content | languageSelect: language }}
						</a>
					}
				</li>
			}
		</ul>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedSummaryComponent {
	@Input() language: string | undefined;
	@Input() title: LanguageStringsModel | undefined;
	@Input() list: PredefinedSummary['list'];
}
