import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminCloseButtonComponent,
	YunoAdminCodeEditorModule,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { ClientsEditorComponent } from './clients-editor.component';

@NgModule({
	declarations: [ClientsEditorComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		YunoAdminCodeEditorModule,
		SpritesheetModule,
		EditContainerContentDirective,
		YunoAdminCloseButtonComponent,
		YunoAdminSaveButtonComponent
	]
})
export class ClientsModule {}
