@if (service.data$ | async; as data) {
	@if (data.mapStyle) {
		<mgl-map
			(styleImageMissing)="service.mapOnMissingImage($event)"
			(mapLoad)="service.mapLoad($event)"
			class="absolute left-0 top-0 z-0 h-full w-full"
			[dragRotate]="data.theme?.view?.dragRotate"
			[dragPan]="data.theme?.view?.dragPan"
			[pitchWithRotate]="data.theme?.view?.pitchWithRotate"
			[touchZoomRotate]="data.theme?.view?.touchZoomRotate"
			[style]="data.mapStyle"
			[fitBounds]="data.bounds"
			[fitBoundsOptions]="{ padding: 80 }">
			<ng-content></ng-content>
		</mgl-map>
	}
}
