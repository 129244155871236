import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { State } from '@yuno/api/interface';

import { StatesFacade } from '../../data-access';
import { StatesRoutingModule } from './states-routing.module';


@Component({
	selector: 'yuno-admin-states',
	standalone: true,
	imports: [YunoAdminTableComponent, YunoAdminButtonsModule, StatesRoutingModule, AsyncPipe],
	templateUrl: './states.component.html',
	styleUrls: ['./states.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatesComponent extends AppDataComponent implements OnInit {
	private readonly statesFacade = inject(StatesFacade);

	data$ = combineLatest({
		states: this.statesFacade.states$.pipe(map(data => data as { [key: string]: unknown }[])),
		selected: this.statesFacade.selectedState$
	});

	ngOnInit(): void {
		this.statesFacade.get();
	}

	onSelect(row: Partial<State>) {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onCreate() {
		this.statesFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<State>) {
		this.statesFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<State>) {
		this.statesFacade.delete(row._id as string);
	}
}
