import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { GeoPhotosCategory } from '@yuno/api/interface';

import { GeoPhotoCategoryFacade } from '../../data-access';


@Component({
	selector: 'yuno-admin-geophotos-internal-view',
	standalone: true,
	imports: [YunoAdminTableComponent, YunoAdminButtonsModule, RouterModule, AsyncPipe],
	templateUrl: './internal-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoPhotoViewSelectorComponent extends AppDataComponent {
	private facade = inject(GeoPhotoCategoryFacade);

	data$ = combineLatest({
		list: this.facade.data$
	});

	onView(row: Partial<GeoPhotosCategory>): void {
		this.router.navigate([row._id as string], {
			relativeTo: this.route
		});
	}

	onEdit(row: Partial<GeoPhotosCategory>): void {
		this.router.navigate([row._id, 'map', 'edit'], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<GeoPhotosCategory>): void {
		this.facade.duplicate(row._id as string);
	}

	onDelete(row: Partial<GeoPhotosCategory>): void {
		this.facade.delete(row._id as string);
	}
}
