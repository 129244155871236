module.exports = {
  A: {
    A: {
      "2": "K D E F eC",
      "33": "A B"
    },
    B: {
      "1": "4 5 6 7 8 9 o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "33": "C L M G N O P",
      "132": "Q H R S T U V W",
      "260": "X Y Z a b c d e f g h i j k l m n"
    },
    C: {
      "1": "4 5 6 7 8 9 bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB hC iC",
      "33": "0 1 2 3 K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB"
    },
    D: {
      "1": "4 5 6 7 8 9 X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB",
      "132": "nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W"
    },
    E: {
      "1": "EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB jC MC",
      "33": "K D E F A B C L M G kC lC mC nC NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC"
    },
    F: {
      "1": "a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 F B C G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB uC vC wC xC AC cC yC BC",
      "132": "aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z"
    },
    G: {
      "1": "EC VC WC XC YC ZC aC FC bC",
      "2": "MC zC",
      "33": "E dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "2": "GC J MD ND OD PD dC QD RD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z TD UD VD WD NC XD YD ZD aD bD DC EC FC cD",
      "2": "J",
      "132": "SD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 4,
  C: "CSS Hyphenation",
  D: true
};