import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, debounceTime, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { StatesFacade } from '@yuno/admin/features/states/data-access';
import {
	AnnotationComponent,
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { LanguageAll, State } from '@yuno/api/interface';

import { StateEditorService } from './state-editor.service';

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoFormsModule,
		YunoAdminButtonComponent,
		UserRightsMessageComponent,
		AnnotationComponent,
		AsyncPipe,
		EditContainerContentDirective,
		YunoAdminCloseButtonComponent,
		YunoAdminSaveButtonComponent
	],
	selector: 'yuno-admin-state-editor',
	templateUrl: './state-editor.component.html',
	styleUrls: ['./state-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly stateFacade = inject(StatesFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly destroyRef = inject(DestroyRef);

	readonly service = inject(StateEditorService);

	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	languages = LanguageAll;

	originalData: Partial<State>;
	url: string;

	data$ = combineLatest({
		state: this.stateFacade.selectedState$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as State;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);
				}
				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		),
		language: this.appFacade.language$
	});

	ngOnInit(): void {
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.stateFacade.select(id);
		});

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.stateFacade.updateSelect(this.service.form.getRawValue() as Partial<State>);
			});
	}

	onSave() {
		this.stateFacade.save();
	}

	/* 	navigates back to the state page */
	onClose() {
		redirectTo(this.route, this.router);
		this.stateFacade.clearSelect();
	}

	ngOnDestroy() {
		this.stateFacade.clearSelect();
		this.languageService.destroyLanguageSelector();
	}
}
