import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';

import { EventForm, EventFormsService } from '@yuno/admin/features/events';
import {
	ContentForm,
	legendList
} from '@yuno/admin/features/legends/feature/editor/legend-editor.service';
import {
	MarkerCategoryLayoutForm,
	MarkerCategoryStylesForm
} from '@yuno/admin/features/place-markers/feature/settings/category/category-editor.service';
import { ContentKeys } from '@yuno/admin/features/textfield-pages/feature/content/types';
import { ControlsOf, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	CdnData,
	CdnFile,
	LanguageObjectModel,
	Legend,
	LegendOptions,
	LegendOptionsDefault,
	MarkerCategoryStyles,
	MarkerFixedProperties,
	MarkerIconStyle,
	MarkerLabelStyle,
	MarkerPhotoStyle,
	MediaImage,
	MediaNotification,
	MediaVideo,
	PageItem,
	PageItemImgBtn,
	PageItemLink,
	PagesComponents,
	PredefinedItem,
	PredefinedSummary,
	TextButton,
	TextCategory,
	TextCustom,
	TextLink,
	TextList,
	TextNewsItem,
	TextToggle,
	Textblock,
	TextfieldComponents,
	VisibilityEnum
} from '@yuno/api/interface';

import { ButtonForm } from '../feature/item-editors/button-editor/button.interface';
import { CustomTextForm } from '../feature/item-editors/custom-text-editor/custom-text.interface';
import { MediaImageForm } from '../feature/item-editors/image-editor/image.interface';
import { LinkForm } from '../feature/item-editors/link-editor/link.interface';
import { ListForm, ListItemForm } from '../feature/item-editors/list-editor/list.interface';
import { NotificationForm } from '../feature/item-editors/notification-editor/notification.interface';
import {
	ItemImageBtnForm,
	ItemLinkForm,
	ItemTitleForm,
	PageItemForm
} from '../feature/item-editors/page-item-editor/page-item.interface';
import {
	PredefinedDividerForm,
	PredefinedFileForm,
	PredefinedForm,
	PredefinedListForm,
	PredefinedParagraphForm,
	PredefinedQuoteForm,
	PredefinedSummaryForm,
	PredefinedTitleForm
} from '../feature/item-editors/predefined-editor/predefined.interface';
import { TextNewsForm } from '../feature/item-editors/text-news-editor';
import { TextblockForm } from '../feature/item-editors/textblock-editor/textblock.interface';
import {
	ToggleItemForm,
	TogglesForm
} from '../feature/item-editors/toggle-editor/toggles.interface';
import { MediaVideoForm } from '../feature/item-editors/video-editor/video.interface';

@Injectable({
	providedIn: 'root'
})
export class TextPageComponentService {
	private readonly eventsFormService = inject(EventFormsService);

	/**
	 * Return a string based on the components properties.
	 * @param component
	 * @returns string
	 */
	getComponentKey(component: TextfieldComponents | PagesComponents): string | undefined {
		const componentKeys: (keyof TextfieldComponents | keyof PagesComponents)[] = [
			'button',
			'container',
			'image',
			'item',
			'link',
			'list',
			'textblock',
			'toggles',
			'video',
			'custom',
			'news',
			'legend',
			'category',
			'notification',
			'imageButton',
			'predefined'
		];

		for (const key of componentKeys) {
			if (key in component && component[key as keyof typeof component]) {
				return key;
			}
		}

		return undefined;
	}

	/**
	 * Will return a type and a formControl, based on the key.
	 * When a component is send along, it will patch the formControl with the component data.
	 *
	 * @param key
	 * @param component
	 * @returns { type: string; formControl: FormGroup | FormControl } | undefined
	 */
	componentCreator(
		key: ContentKeys,
		component?: TextfieldComponents | PagesComponents
	): { type: string; formControl: FormGroup | FormControl } | undefined {
		if (!key && !component) {
			return undefined;
		}

		switch (key) {
			case 'button':
				return {
					type: key,
					formControl: this.createButtonComponentForm(component?.button)
				};
			case 'container':
				return {
					type: key,
					formControl: this.createContainerComponentForm(component?.container)
				};
			case 'image':
				return {
					type: key,
					formControl: this.createImageComponentForm(component?.image)
				};
			case 'item':
				return {
					type: key,
					formControl: this.createItemComponentForm(component?.item)
				};
			case 'link':
				return {
					type: key,
					formControl: this.createLinkComponentForm(component?.link)
				};
			case 'list':
				return {
					type: key,
					formControl: this.createListComponentForm(component?.list)
				};
			case 'textblock':
				return {
					type: key,
					formControl: this.createTextblockComponentForm(component?.textblock)
				};
			case 'toggles':
				return {
					type: key,
					formControl: this.createTogglesComponentForm(component?.toggles)
				};
			case 'video':
				return {
					type: key,
					formControl: this.createVideoComponentForm(component?.video)
				};
			case 'custom':
				return {
					type: key,
					formControl: this.createCustomComponentForm(component?.custom)
				};
			case 'news':
				return {
					type: key,
					formControl: this.createNewsItemComponent(component?.news)
				};
			case 'legend':
				return {
					type: key,
					formControl: this.createLegendComponentForm(component?.legend)
				};
			case 'category':
				return {
					type: key,
					formControl: this.createCategoryComponentForm(component?.category)
				};
			case 'notification':
				return {
					type: key,
					formControl: this.createNotificationComponentForm(component?.notification)
				};
			case 'imageButton':
				return {
					type: key,
					formControl: this.createItemComponentImageBtnForm(component?.imageButton)
				};
			case 'predefined':
			case 'h1':
			case 'h2':
			case 'h3':
			case 'paragraph':
			case 'summary':
			case 'dateSummary':
			case 'blockquote':
			case 'divider':
			case 'file':
				return {
					type: 'predefined',
					formControl: this.createPredefinedComponentForm(key, component?.predefined)
				};
			default:
				return undefined;
		}
	}

	// Text Buttons
	private createButtonComponentForm(component?: TextButton): FormGroup<ButtonForm> {
		const form = new FormGroup<ButtonForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			content: newLanguageFormGroup(),
			events: this.eventsFormService.createEventForm(),
			align: new FormControl('default', {
				nonNullable: true,
				validators: Validators.required
			}),
			alignBottom: new FormControl(false, { nonNullable: true }),
			activeContent: newLanguageFormGroup(),
			activeEvents: this.eventsFormService.createEventForm(),
			activeToggle: new FormControl(false, { nonNullable: true }),
			clientColors: new FormControl(false, { nonNullable: true })
		});

		if (component) {
			if (component.events && component.events.length > 0) {
				for (const event of component.events) {
					const eventsArray = form.get('events') as FormArray<FormGroup<EventForm>>;
					eventsArray?.push(
						this.eventsFormService.createEvent(event.type, event.options)
					);
				}
			}

			form.patchValue(component as never);
		}
		return form;
	}

	// Text Container
	private createContainerComponentForm(component?: string): FormControl<string> {
		const form = new FormControl('container', {
			nonNullable: true,
			validators: Validators.required
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// Text Link
	private createLinkComponentForm(component?: TextLink): FormGroup<LinkForm> {
		const form = new FormGroup<LinkForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			color: new FormControl('', { nonNullable: true }),
			display: newLanguageFormGroup(),
			type: new FormControl('url', { nonNullable: true }),
			link: new FormControl('', { nonNullable: true }),
			page: new FormControl('', { nonNullable: true }),
			url: newLanguageFormGroup()
		});

		if (component) {
			form.patchValue(component as never);
			if (!component?.type) {
				form.controls.type.patchValue('url');
			}
		}

		return form;
	}

	// Textblock
	private createTextblockComponentForm(component?: Textblock): FormGroup<TextblockForm> {
		const form = new FormGroup<TextblockForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			content: newLanguageFormGroup(),
			public: new FormControl(true, { nonNullable: true })
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// Media Image
	private createImageComponentForm(component?: MediaImage): FormGroup<MediaImageForm> {
		const form = new FormGroup<MediaImageForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			alt: newLanguageFormGroup(),
			url: new FormControl('', { nonNullable: true }),
			file: new FormControl<CdnFile | null>(null, { nonNullable: true }),
			description: newLanguageFormGroup(),
			zoomable: new FormControl(true, { nonNullable: true })
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// Media Video
	private createVideoComponentForm(component?: MediaVideo): FormGroup<MediaVideoForm> {
		const form = new FormGroup<MediaVideoForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			type: new FormControl('vimeo', {
				nonNullable: true,
				validators: Validators.required
			}),
			description: newLanguageFormGroup(),
			videoId: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			})
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// Custom Text
	private createCustomComponentForm(component?: TextCustom): FormGroup<CustomTextForm> {
		const form = new FormGroup<CustomTextForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			name: new FormControl('', { nonNullable: true }),
			content: new FormControl('', { nonNullable: true }),
			customData: new FormControl(undefined)
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// News Item
	private createNewsItemComponent(component?: TextNewsItem): FormGroup<TextNewsForm> {
		const form = new FormGroup<TextNewsForm>({
			_id: new FormControl(undefined, { nonNullable: true }),
			id: new FormControl('', { validators: Validators.required, nonNullable: true }),
			bgColor: new FormControl('', { nonNullable: true }),
			textColor: new FormControl('', { nonNullable: true }),
			textHeadingColor: new FormControl('', { nonNullable: true }),
			textLinkColor: new FormControl('', { nonNullable: true }),
			align: new FormControl<'left' | 'right' | 'top' | 'bottom'>('right', {
				nonNullable: true
			}),
			size: new FormControl<'1/2' | '1/3' | '1/4'>('1/3', { nonNullable: true }),
			title: newLanguageFormGroup(),
			subTitle: newLanguageFormGroup(),
			content: newLanguageFormGroup(),
			imageSrc: new FormControl('', { nonNullable: true }),
			imageFile: new FormGroup<ControlsOf<CdnFile> | undefined>({
				_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
				data: new FormGroup<ControlsOf<CdnData> | undefined>({
					fileName: new FormControl(
						{ value: undefined, disabled: true },
						{ nonNullable: true }
					),
					url: new FormControl(
						{ value: undefined, disabled: true },
						{ nonNullable: true }
					)
				})
			}),
			imageLink: new FormControl('', { nonNullable: true }),
			imageZoom: new FormControl(false, { nonNullable: true }),

			secondaryImageSrc: new FormControl('', { nonNullable: true }),
			secondaryImageFile: new FormGroup<ControlsOf<CdnFile> | undefined>({
				_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
				data: new FormGroup<ControlsOf<CdnData> | undefined>({
					fileName: new FormControl(
						{ value: undefined, disabled: true },
						{ nonNullable: true }
					),
					url: new FormControl(
						{ value: undefined, disabled: true },
						{ nonNullable: true }
					)
				})
			}),
			secondaryImageLink: new FormControl('', { nonNullable: true }),
			secondaryImageZoom: new FormControl(false, { nonNullable: true }),

			imageOrder: new FormControl('row', { nonNullable: true }),

			videoSrc: new FormControl('', { nonNullable: true }),
			videoType: new FormControl<MediaVideo['type'] | undefined>(undefined, {
				nonNullable: true
			}),
			description: newLanguageFormGroup(),
			links: new FormArray<FormGroup<ItemLinkForm>>([]),
			updatedAt: new FormControl(new Date())
		});

		if (component) {
			if (component.links && component.links.length > 0) {
				const formLinks = form.get('links') as FormArray<FormGroup<ItemLinkForm>>;
				for (const link of component.links) {
					const linkForm = this.createItemComponentLinkForm(link);
					formLinks.push(linkForm);
				}
			}

			form.patchValue(component as never);
		}

		return form;
	}

	// Text Legend
	private createLegendComponentForm(component?: Legend): FormGroup {
		const form = new FormGroup({
			_id: new FormControl('', { nonNullable: true }),
			id: new FormControl('', { nonNullable: true }),
			title: new FormControl('', { nonNullable: true }),
			content: new FormArray<FormGroup<ContentForm>>([])
		});

		if (component) {
			if (component.content && component.content.length > 0) {
				const formContent = form.get('content') as FormArray<FormGroup<ContentForm>>;
				for (const item of component.content) {
					const itemForm = this.createLegendContentItemForm(item);
					formContent.push(itemForm);
				}
			}

			form.patchValue(component as never);
		}

		return form;
	}

	createLegendContentItemForm(item?: LegendOptions): FormGroup<ContentForm> {
		const content = new FormGroup<ContentForm>({
			public: new FormControl(true, { nonNullable: true }),
			title: newLanguageFormGroup(),
			bold: new FormControl(false, { nonNullable: true }),
			class: new FormControl('', { nonNullable: true }),
			color: new FormControl('', { nonNullable: true }),
			svg: new FormControl('', { nonNullable: true }),
			border: new FormControl('', { nonNullable: true }),
			borderColor: new FormControl('', { nonNullable: true }),
			image: new FormControl('', { nonNullable: true }),
			gradiant: new FormArray<
				FormGroup<{
					color?: FormControl<string>;
					offset?: FormControl<number>;
					title?: FormControl<string>;
				}>
			>([]),
			gradiantHeight: new FormControl(0, { nonNullable: true }),
			list: new FormArray<FormGroup<legendList>>(this.createListFormData(item?.list))
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		content.patchValue(item as any);

		return content;
	}

	private createListFormData(list?: LegendOptionsDefault[]): FormGroup<legendList>[] {
		if (!list) {
			return [];
		}
		const arr: FormGroup<legendList>[] = [];

		for (const item of list) {
			const newList = new FormGroup<legendList>({
				public: new FormControl(true, { nonNullable: true }),
				title: newLanguageFormGroup(),
				bold: new FormControl(false, { nonNullable: true }),
				class: new FormControl('', { nonNullable: true }),
				color: new FormControl('', { nonNullable: true }),
				svg: new FormControl('', { nonNullable: true }),
				border: new FormControl('', { nonNullable: true }),
				borderColor: new FormControl('', { nonNullable: true }),
				image: new FormControl('', { nonNullable: true }),
				gradiant: new FormArray<
					FormGroup<{
						color?: FormControl<string>;
						offset?: FormControl<number>;
						title?: FormControl<string>;
					}>
				>([]),
				gradiantHeight: new FormControl(0, { nonNullable: true })
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newList.patchValue(item as any);
			arr.push(newList);
		}
		return arr;
	}

	// Text Place Marker Category
	private createCategoryComponentForm(data?: TextCategory): FormGroup {
		const form = new FormGroup({
			title: newLanguageFormGroup(),
			category: new FormGroup({
				_id: new FormControl('', { nonNullable: true }),
				id: new FormControl('', { nonNullable: true }),
				type: new FormControl('', { nonNullable: true }),
				layout: new FormGroup<MarkerCategoryLayoutForm>({
					fallback: new FormControl<string | undefined>('', {
						nonNullable: true,
						validators: Validators.required
					}),
					filter: new FormControl<keyof MarkerFixedProperties | undefined>(undefined, {
						nonNullable: true
					}),
					options: this.createLayoutOptionsForm()
				}),
				styles: new FormArray<FormGroup<MarkerCategoryStylesForm>>(
					this.createStyleFormData(data?.category?.styles)
				)
			}),
			dropdown: new FormControl<boolean>(false, { nonNullable: true }),
			hide: new FormControl<boolean>(false, { nonNullable: true })
		});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		form.patchValue(data as any);

		return form;
	}

	private createLayoutOptionsForm(): UntypedFormArray {
		return new UntypedFormArray([]);
	}

	private createStyleFormData(
		styles?: MarkerCategoryStyles[]
	): FormGroup<MarkerCategoryStylesForm>[] {
		if (!styles) {
			return [];
		}
		const arr: FormGroup<MarkerCategoryStylesForm>[] = [];

		for (const item of styles) {
			const formGroup = new FormGroup<MarkerCategoryStylesForm>({
				id: new FormControl<string>('new style', { nonNullable: true }),
				overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
			});

			const formStyle = this.createFormStyle();
			formGroup.setControl('style', formStyle);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			formGroup.patchValue(item as any);
			arr.push(formGroup);
		}
		return arr;
	}

	private createFormStyle(): FormGroup<
		ControlsOf<MarkerIconStyle | MarkerPhotoStyle | MarkerLabelStyle>
	> {
		return new FormGroup<ControlsOf<MarkerIconStyle | MarkerPhotoStyle | MarkerLabelStyle>>({
			type: new FormControl('icon', { nonNullable: true }),
			visibility: new FormControl<VisibilityEnum>(VisibilityEnum.visible, {
				nonNullable: true
			}),
			minZoom: new FormControl<number>(1, { nonNullable: true }),
			maxZoom: new FormControl<number>(24, { nonNullable: true }),
			zIndex: new FormControl<number>(1, { nonNullable: true }),
			alignment: new FormControl<string>('bottom', { nonNullable: true }),
			eventStyle: new FormControl<string>('chevron-event', { nonNullable: true }),
			icon: new FormControl<string>('icon', { nonNullable: true }),
			iconSelect: new FormControl<string>('', { nonNullable: true }),
			rotation: new FormControl<number>(0, { nonNullable: true }),
			scale: new FormControl<number>(1, { nonNullable: true }),
			class: new FormControl<string>('label pointer', { nonNullable: true }),
			color: new FormControl<string>('#394551', { nonNullable: true }),
			backgroundColor: new FormControl<string>('rgba(255,255,255,0.85)', {
				nonNullable: true
			})
		});
	}

	// Text List
	private createListComponentForm(component?: TextList): FormGroup<ListForm> {
		const form = new FormGroup<ListForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			defaultValue: new FormControl('', { nonNullable: true }),
			title: newLanguageFormGroup(),
			items: new FormArray<FormGroup<ListItemForm>>([]),
			select: new FormControl(false, { nonNullable: true }),
			multiselect: new FormControl(false, { nonNullable: true }),
			ressetable: new FormControl(false, { nonNullable: true }),
			keepActiveDataset: new FormControl(false, { nonNullable: true }),
			disableActiveState: new FormControl(false, { nonNullable: true }),
			active: new FormGroup({
				backgroundColor: new FormControl('', { nonNullable: true }),
				color: new FormControl('', { nonNullable: true })
			})
		});

		if (component) {
			if (component.items && component.items.length > 0) {
				for (const item of component.items) {
					const eventsArray = form.get('items') as FormArray<FormGroup<ListItemForm>>;
					const itemForm = this.createListComponentItemForm();

					if (item.events) {
						const eventFormArray = itemForm.get('events') as FormArray<
							FormGroup<EventForm>
						>;
						for (const event of item.events) {
							const eventForm = this.eventsFormService.createEvent(
								event.type,
								event.options
							);

							eventFormArray.push(eventForm);
						}
					}

					eventsArray?.push(itemForm);
				}
			}

			form.patchValue(component as never);
		}

		return form;
	}

	private createListComponentItemForm(): FormGroup<ListItemForm> {
		return new FormGroup<ListItemForm>({
			active: new FormControl(false, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true }),
			title: newLanguageFormGroup(),
			events: this.eventsFormService.createEventForm()
		});
	}

	// END Text List

	// Page Item
	private createItemComponentForm(component?: PageItem): FormGroup<PageItemForm> {
		const form = new FormGroup<PageItemForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			class: new FormControl('', { nonNullable: true }),
			content: new FormControl('', { nonNullable: true }),
			imageButtons: new FormArray<FormGroup<ItemImageBtnForm>>([]),
			link: new FormArray<FormGroup<ItemLinkForm>>([]),
			title: new FormGroup<ItemTitleForm>({
				color: new FormControl('', { nonNullable: true }),
				icon: new FormControl('', { nonNullable: true }),
				title: newLanguageFormGroup()
			})
		});

		if (component) {
			if (component.link && component.link.length > 0) {
				const formLinks = form.get('link') as FormArray<FormGroup<ItemLinkForm>>;
				for (const link of component.link) {
					const linkForm = this.createItemComponentLinkForm(link);
					formLinks.push(linkForm);
				}
			}

			if (component.imageButtons && component.imageButtons.length > 0) {
				const imgBtnForm = form.get('imageButtons') as FormArray<
					FormGroup<ItemImageBtnForm>
				>;

				for (const btn of component.imageButtons) {
					const imageButtonForm = this.createItemComponentImageBtnForm(btn);
					imgBtnForm.push(imageButtonForm);
				}
			}

			form.patchValue(component as never);
		}

		return form;
	}

	createItemComponentLinkForm(component?: PageItemLink): FormGroup<ItemLinkForm> {
		const form = new FormGroup<ItemLinkForm>({
			color: new FormControl('#0ea5e9', { nonNullable: true }),
			page: new FormControl('', { nonNullable: true }),
			title: newLanguageFormGroup(),
			type: new FormControl('', { nonNullable: true }),
			text: newLanguageFormGroup(),
			url: newLanguageFormGroup(),
			link: new FormControl('', { nonNullable: true }),
			pageUrl: new FormGroup({
				_id: new FormControl<string | null>('', { nonNullable: true }),
				id: new FormControl<string | null>('', { nonNullable: true })
			})
		});

		if (component) {
			form.patchValue(component as never);

			// When a page is available
			// make sure the pageUrl has the same data
			if (component.page) {
				form.get('pageUrl')?.patchValue({
					_id: component.page,
					id: component.page
				});
			}
		}
		return form;
	}

	createItemComponentImageBtnForm(component?: PageItemImgBtn): FormGroup<ItemImageBtnForm> {
		const form = new FormGroup<ItemImageBtnForm>({
			item: new FormGroup({
				_id: new FormControl<string | null>(null),
				size: new FormControl('', { nonNullable: true }),
				wide: new FormControl(false, { nonNullable: true }),
				file: new FormGroup({
					_id: new FormControl<string | null>('', { nonNullable: false }),
					data: new FormGroup({
						url: new FormControl('', { nonNullable: true })
					})
				}),
				title: newLanguageFormGroup()
			}),
			link: new FormControl('', { nonNullable: true }),
			type: new FormControl('page', { nonNullable: true }),
			url: newLanguageFormGroup()
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// END PAGE ITEM

	createNotificationComponentForm(component?: MediaNotification): FormGroup<NotificationForm> {
		const form = new FormGroup<NotificationForm>({
			_id: new FormControl<string | null>(null),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			title: newLanguageFormGroup(),
			content: newLanguageFormGroup(),
			type: new FormControl('warning', { nonNullable: true })
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	// Text Toggles
	private createTogglesComponentForm(component?: TextToggle): FormGroup<TogglesForm> {
		const form = new FormGroup<TogglesForm>({
			_id: new FormControl(null),
			updatedAt: new FormControl(new Date()),
			id: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			title: newLanguageFormGroup(),
			items: new FormArray<FormGroup<ToggleItemForm>>([]),
			listLike: new FormControl(false, { nonNullable: true }),
			addColor: new FormControl(false, { nonNullable: true }),
			active: new FormGroup({
				backgroundColor: new FormControl('', { nonNullable: true }),
				color: new FormControl('', { nonNullable: true })
			})
		});

		if (component) {
			if (component.items && component.items.length > 0) {
				for (const item of component.items) {
					const itemForm = this.createToggleItemForm();
					// Creates subItems
					if (item.items && item?.items.length) {
						for (const subItemData of item.items) {
							const subItemForm = this.createToggleSubItemForm();

							if (subItemData?.layer) {
								for (const layer of subItemData.layer) {
									(
										subItemForm.get('layer') as FormArray<FormControl<string>>
									)?.push(this.createToggleItemLayer(layer));
								}
							}

							if (subItemData?.dataset) {
								for (const dataset of subItemData.dataset) {
									(
										subItemForm.get('dataset') as FormArray<FormControl<string>>
									)?.push(this.createToggleDataset(dataset));
								}
							}

							const subItemArr = itemForm.get('items') as FormArray<
								FormGroup<Omit<ToggleItemForm, 'items'>>
							>;
							subItemArr?.push(subItemForm);
						}
					}

					// Create Layers
					if (item?.layer && item?.layer.length) {
						const layerArr = itemForm.get('layer') as FormArray<FormControl<string>>;
						for (const layer of item.layer) {
							layerArr?.push(this.createToggleItemLayer(layer));
						}
					}

					// Create Datasets
					if (item?.dataset && item?.dataset.length) {
						const datasetArr = itemForm.get('dataset') as FormArray<
							FormControl<string>
						>;

						for (const dataset of item.dataset) {
							datasetArr?.push(this.createToggleDataset(dataset));
						}
					}

					(form.get('items') as FormArray<FormGroup<ToggleItemForm>>)?.push(itemForm);
				}
			}
			form.patchValue(component as never);
		}

		return form;
	}

	private createToggleItemForm(): FormGroup<ToggleItemForm> {
		return new FormGroup<ToggleItemForm>({
			active: new FormControl(false, { nonNullable: true }),
			group: new FormControl(false, { nonNullable: true }),
			public: new FormControl(true, { nonNullable: true }),
			type: new FormControl('layer', { nonNullable: true }),
			title: newLanguageFormGroup(),
			dataset: new FormArray<FormControl<string>>([]),
			layer: new FormArray<FormControl<string>>([]),
			items: new FormArray<FormGroup<Omit<ToggleItemForm, 'items'>>>([]),
			color: new FormControl(null)
		});
	}

	private createToggleSubItemForm(): FormGroup<Omit<ToggleItemForm, 'items'>> {
		return new FormGroup<Omit<ToggleItemForm, 'items'>>({
			active: new FormControl(false, { nonNullable: true }),
			group: new FormControl(false, { nonNullable: true }),
			public: new FormControl(true, { nonNullable: true }),
			type: new FormControl('layer', { nonNullable: true }),
			title: newLanguageFormGroup(),
			dataset: new FormArray<FormControl<string>>([]),
			layer: new FormArray<FormControl<string>>([]),
			color: new FormControl(null)
		});
	}

	private createToggleItemLayer(str?: string): FormControl<string> {
		const form = new FormControl<string>('', { nonNullable: true });
		if (str) {
			form.patchValue(str);
		}
		return form;
	}

	private createToggleDataset(str?: string): FormControl<string> {
		const form = new FormControl<string>('', { nonNullable: true });
		if (str) {
			form.patchValue(str);
		}
		return form;
	}

	// END Text Toggles

	// Predefined
	private createPredefinedComponentForm(
		e: ContentKeys,
		component?: PredefinedItem
	): FormGroup<PredefinedForm> {
		let key = e;
		if (component && component.selector) {
			key = component.selector as ContentKeys;
		}
		const form = new FormGroup<PredefinedForm>({
			selector: new FormControl(key, { nonNullable: true }),
			options: this.createPredefinedOptionsForm(key)
		});

		if (component) {
			if (
				(component.options as PredefinedSummary).list &&
				(component.options as PredefinedSummary).list.length >= 1
			) {
				const options = form.controls.options.controls as PredefinedSummaryForm;
				for (const item of (component.options as PredefinedSummary).list) {
					options.list?.push(this.createPredefinedListItem(item));
				}
			}
			form.patchValue(component as never);
		}

		return form;
	}

	private createPredefinedListItem(str?: {
		content: LanguageObjectModel;
		date?: Date;
		url?: string;
	}): FormGroup {
		const form = new FormGroup<PredefinedListForm>({
			date: new FormControl<Date | null>(null, { nonNullable: true }),
			content: newLanguageFormGroup(),
			url: new FormControl<string | null>(null, { nonNullable: true }),
			page: new FormControl<string | null>(null, { nonNullable: true })
		});
		if (str) {
			form.patchValue(str);
		}
		return form;
	}

	private createPredefinedOptionsForm(key: ContentKeys) {
		if (key === 'paragraph') {
			return new FormGroup<PredefinedParagraphForm>({
				title: newLanguageFormGroup(),
				content: newLanguageFormGroup()
			}) as FormGroup<
				| PredefinedTitleForm
				| PredefinedParagraphForm
				| PredefinedSummaryForm
				| PredefinedQuoteForm
				| PredefinedDividerForm
				| PredefinedFileForm
			>;
		}
		if (key === 'blockquote') {
			return new FormGroup<PredefinedQuoteForm>({
				color: new FormControl<string>('', { nonNullable: true }),
				content: newLanguageFormGroup()
			}) as FormGroup<
				| PredefinedTitleForm
				| PredefinedParagraphForm
				| PredefinedSummaryForm
				| PredefinedQuoteForm
				| PredefinedDividerForm
				| PredefinedFileForm
			>;
		}
		if (key === 'summary' || key === 'dateSummary') {
			return new FormGroup<PredefinedSummaryForm>({
				title: newLanguageFormGroup(),
				list: new FormArray<FormGroup<PredefinedListForm>>([])
			}) as FormGroup<
				| PredefinedTitleForm
				| PredefinedParagraphForm
				| PredefinedSummaryForm
				| PredefinedQuoteForm
				| PredefinedDividerForm
				| PredefinedFileForm
			>;
		}
		if (key === 'h1' || key === 'h2' || key === 'h3') {
			return new FormGroup<PredefinedTitleForm>({
				title: newLanguageFormGroup()
			}) as FormGroup<
				| PredefinedTitleForm
				| PredefinedParagraphForm
				| PredefinedSummaryForm
				| PredefinedQuoteForm
				| PredefinedDividerForm
				| PredefinedFileForm
			>;
		}

		if (key === 'divider') {
			return new FormGroup<PredefinedDividerForm>({
				line: new FormControl<boolean>(true, { nonNullable: true })
			}) as FormGroup<
				| PredefinedTitleForm
				| PredefinedParagraphForm
				| PredefinedSummaryForm
				| PredefinedQuoteForm
				| PredefinedDividerForm
				| PredefinedFileForm
			>;
		}

		if (key === 'file') {
			return new FormGroup<PredefinedFileForm>({
				title: newLanguageFormGroup(),
				file: new FormControl<CdnFile | null>(null, { nonNullable: true })
			}) as FormGroup<
				| PredefinedTitleForm
				| PredefinedParagraphForm
				| PredefinedSummaryForm
				| PredefinedQuoteForm
				| PredefinedDividerForm
				| PredefinedFileForm
			>;
		}

		return new FormGroup<PredefinedTitleForm>({
			title: newLanguageFormGroup()
		}) as FormGroup<
			| PredefinedTitleForm
			| PredefinedParagraphForm
			| PredefinedSummaryForm
			| PredefinedQuoteForm
			| PredefinedDividerForm
		>;
	}
}
