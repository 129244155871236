@if (categories$ | async; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="false"
		[pagination]="true"
		[pageOptions]="{
			pageSize: 100,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="data"
		[buttons]="tableButtons"
		[buttonsWidth]="12"
		[columns]="[
			{ key: 'public', type: 'visible', disableVisibility: true },
			{ key: 'id', label: 'Category' },
			{ key: 'inputs', label: 'Input Fields' },
			{ key: 'style', label: 'Style' },
			{ key: 'events', label: 'Click|Hover' },
			{ key: 'intern', label: 'Intern' },
			{ key: 'cluster', label: 'Cluster' }
		]"
		(clicked)="onView($event)">
		@if (xkpMember && minimalAppRole(userRoles.ADMIN)) {
			<button
				yuno-admin-button
				[routerLink]="['./', 'view', 'create', 'map', 'edit']"
				[add]="true">
				New Category
			</button>
		}
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onEdit(row)"></button>
</ng-template>
