<div
	class="group flex w-full cursor-pointer items-center justify-between gap-4 rounded border border-yuno-blue-gray-400 bg-yuno-blue-gray-300 p-2 text-yuno-blue-gray-700 hover:bg-yuno-blue-gray-400"
	[ngClass]="{
		'h-14': !disableHeight,
		'bg-yuno-blue-gray-300': !light,
		'bg-yuno-blue-gray-200': light
	}">
	<div
		cdkDragHandle
		class="flex flex-1 cursor-move select-none flex-row items-center justify-start gap-4 truncate">
		<svg
			[ngClass]="{ hidden: hideDrag }"
			viewBox="0 0 16 24"
			class="mx-2 h-6 w-4 text-gray-400"
			fill="currentColor">
			<use href="#drag-icon" />
		</svg>
		<div class="flex-1 truncate">
			<ng-content select="[title]"></ng-content>
		</div>
	</div>

	<div
		class="flex-row gap-2"
		[ngClass]="{
			'hidden group-hover:flex group-hover:empty:hidden': !keepButtons,
			'flex empty:hidden': keepButtons
		}">
		<ng-content select="[buttons]"></ng-content>
	</div>
</div>
