import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { ButtonCardModule } from '@yuno/project-atlas/ui';

import { ImageButtonEditorComponent } from './image-button-editor.component';


@NgModule({
	declarations: [ImageButtonEditorComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoFormsModule,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		ButtonCardModule,
		UserRightsMessageComponent,
		EditContainerContentDirective
	]
})
export class ImageButtonEditorModule {}
