import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NavbarGuard, ResetStateGuard } from '@yuno/admin/core';

const children: Routes = [
	{
		path: 'edit/:id',
		loadComponent: () =>
			import('./../editor/dashboard-editor.component').then(c => c.DashboardEditorComponent)
	}
];
const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				canActivate: [NavbarGuard('Dashboard', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'dashboard' },
				children: children,
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardInternalViewComponent)
			},
			{
				path: 'planning',
				canActivate: [NavbarGuard('Planning', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'planning' },
				children: children,
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardInternalViewComponent)
			},
			{
				path: 'deliverables',
				canActivate: [NavbarGuard('Deliverables', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'deliverables' },
				children: children,
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardInternalViewComponent)
			},
			{
				path: 'tickets',
				canActivate: [NavbarGuard('Tickets', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'tickets' },
				children: children,
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardInternalViewComponent)
			},
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule {}
