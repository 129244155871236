@if (service$ | async; as service) {
	@if (data$ | async; as data) {
		@if (service.component) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>Button Editor</yuno-forms-title>
					<yuno-forms-span>You can edit the selected button below.</yuno-forms-span>
				</section>
				<section class="flex gap-2">
					<yuno-forms-text
						class="flex-1"
						formControlName="id"
						placeholder="Button name"
						label="Button name"></yuno-forms-text>
					<yuno-forms-select
						formControlName="align"
						placeholder="Set alignment"
						label="Alignment"
						[selectValues]="service.buttonAlignSelectValues"
						[display]="service.buttonAlignSelectDisplay"></yuno-forms-select>
				</section>
				<section class="flex gap-4">
					<yuno-forms-toggle
						formControlName="clientColors"
						label="Use the colors from the App Settings"></yuno-forms-toggle>
				</section>
				<section class="flex gap-4">
					<yuno-forms-toggle
						formControlName="alignBottom"
						label="Align to bottom?"></yuno-forms-toggle>
					<yuno-forms-toggle
						formControlName="activeToggle"
						label="Button active state"></yuno-forms-toggle>
				</section>
				<section formGroupName="content" class="flex flex-col items-start gap-4">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-text
								class="w-full"
								[formControlName]="lang"
								[language]="lang"
								placeholder="Button content"
								label="Content"></yuno-forms-text>
						}
					}
					<button
						yuno-admin-button
						color="primary"
						(click)="editService.onToggleEvents('events')">
						Events ({{ service.component.value.events.length }})
					</button>
					<section>
						<label class="yuno-form">
							<span class="select-none">Preview</span>
						</label>
						<yuno-button
							[color]="
								service.component.value?.clientColors
									? data.config?.colors?.buttonFont
									: undefined
							"
							[backgroundColor]="
								service.component.value?.clientColors
									? data.config?.colors?.button
									: undefined
							">
							<span>{{
								service.component.value?.content[data.language || language]
							}}</span>
						</yuno-button>
					</section>
				</section>
				@if (service.component.value?.activeToggle) {
					<yuno-forms-divider></yuno-forms-divider>
					<section formGroupName="activeContent" class="flex flex-col items-start gap-4">
						@for (lang of editService.languages; track lang) {
							@if (lang === data.language) {
								<yuno-forms-text
									class="w-full"
									[formControlName]="lang"
									[language]="lang"
									placeholder="Button content"
									label="Active Content"></yuno-forms-text>
							}
						}
						<button
							yuno-admin-button
							color="primary"
							(click)="editService.onToggleEvents('activeEvents')">
							Events ({{ service.component.value?.activeEvents.length }})
						</button>
						<section>
							<label class="yuno-form">
								<span class="select-none">Preview</span>
							</label>
							<yuno-button
								[color]="
									service.component.value?.clientColors
										? data.config?.colors?.buttonFont
										: undefined
								"
								[backgroundColor]="
									service.component.value?.clientColors
										? data.config?.colors?.button
										: undefined
								">
								<span>{{
									service.component.value?.activeContent[
										data.language || language
									]
								}}</span>
							</yuno-button>
						</section>
					</section>
				}
			</form>
		}
	}
}
