@if (data._id) {
	<div class="yuno-text-legend">
		<span class="pb-4 text-xl font-semibold">{{ data.title | languageSelect: language }}</span>
		@for (item of data.content; track item) {
			@switch (item.list && item.list.length >= 1) {
				@case (false) {
					<yuno-legenda-item-content
						[sizing]="data.size || 24"
						[language]="language"
						[data]="item"
						[list]="false"
						[layer]="item.layer"></yuno-legenda-item-content>
				}
				@case (true) {
					<yuno-legenda-item-content
						[sizing]="data.size || 24"
						[language]="language"
						[data]="item"
						[list]="true"
						[layer]="item.layer"></yuno-legenda-item-content>
					@for (listItem of item.list; track listItem) {
						<yuno-legenda-item-content
							[sizing]="data.size || 24"
							[language]="language"
							[data]="listItem"
							[parentClass]="item.class"
							[layer]="item.layer"></yuno-legenda-item-content>
					}
				}
			}
		}
	</div>
}
