import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent,
	YunoFormsLabelRequired
} from '@yuno/angular/forms';

import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-select',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<!-- STRINGS -->
			<!-- STRINGS -->
			@if (!isNumber) {
				<select
					class="truncate group-hover:text-gray-500"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[formControl]="getFormControl()"
					(change)="onChanged($event)">
					<option [value]="null" [hidden]="!unselectDisplay">
						{{ unselectDisplay || placeholder }}
					</option>
					<option [value]="undefined" hidden>{{ placeholder }}</option>
					<option [value]="''" hidden>{{ placeholder }}</option>
					@for (select of selectValues; track trackRandomizer(select); let i = $index) {
						<option [value]="select">
							{{ returnDisplay(i, select) }}
						</option>
					}
					@for (
						group of selectValuesGroup;
						track trackRandomizer(group.optgroup);
						let i = $index
					) {
						<optgroup [label]="group.optgroup">
							@for (
								option of group.options;
								track trackRandomizer(option);
								let j = $index
							) {
								<option [value]="option">
									{{ returnDisplayGroup(i, j, option) }}
								</option>
							}
						</optgroup>
					}
				</select>
			}

			<!-- NUMBERS -->
			@if (isNumber) {
				<select
					class="truncate group-hover:text-gray-500"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[formControl]="getFormControl()"
					(change)="onChanged($event)">
					<option [ngValue]="null" [hidden]="!unselectDisplay">
						{{ unselectDisplay || placeholder }}
					</option>
					<option [ngValue]="undefined" hidden>{{ placeholder }}</option>
					<option [ngValue]="''" hidden>{{ placeholder }}</option>
					@for (select of selectValuesNumber; track select; let i = $index) {
						<option [ngValue]="select">
							{{ returnDisplay(i, select) }}
						</option>
					}
				</select>
			}

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired
	]
})
export class YunoFormsSelectComponent extends YunoFormsDefaultComponent implements AfterViewInit {
	/**
	 * When using CustomProrties make sure you set
	 * the Custom Property values as first in the display and selectValues array
	 */
	@Input() customProperties?: boolean;

	_selectValues?: string[];
	@Input() set selectValues(val: undefined | string[]) {
		this._selectValues = val;
		this.customProperties && this.checkCustomProperties();
	}

	get selectValues() {
		return this._selectValues;
	}

	@Input() selectValuesNumber?: number[];
	@Input() selectValuesGroup?: { optgroup: string; options: string[] }[];

	_display?: string[] | number[];
	@Input() set display(val: undefined | string[] | number[]) {
		this._display = val;
		this.customProperties && this.checkCustomProperties();
	}

	get display() {
		return this._display;
	}

	@Input() displayGroup?: { optgroup: string; options: string[] }[];

	@Input() isNumber = false;
	@Input() unselectDisplay?: string;

	@Output() changes = new EventEmitter<string | null>();
	@Output() changesDisplay = new EventEmitter<string | null>();
	@Output() changesNumber = new EventEmitter<number | null>();

	randomIdKey: string;

	constructor() {
		super();

		this.randomIdKey = (Math.random() + 1).toString(36).substring(7);
	}

	trackRandomizer(str: string): string {
		return `${str}-${this.randomIdKey}`;
	}

	ngAfterViewInit() {
		this.customProperties && this.checkCustomProperties();
	}

	returnDisplay(index: number, select: string | number) {
		if (this.display && this.display[index]) {
			return this.display[index];
		}
		return select;
	}

	returnDisplayGroup(group: number, index: number, select: string | number) {
		if (this.displayGroup && this.displayGroup[group]) {
			return this.displayGroup[group].options[index];
		}
		return select;
	}

	onChanged(event: Event) {
		// Get the value of the selected option
		const selectedValue = (event.target as HTMLSelectElement).value;

		// Outputs the changes
		if (this.isNumber) {
			this.changesNumber.emit(parseInt(selectedValue));
		} else {
			this.changes.emit(selectedValue);

			if (this.display) {
				const ind = this.selectValues?.findIndex(value => value === selectedValue);
				if (ind !== undefined && ind >= 0) {
					this.display[ind] && this.changesDisplay.emit(this.display[ind] as string);
				}
			}
		}

		// Set the value of the form control to a real null value
		// instead of a null string
		if (selectedValue === 'null') {
			this.getFormControl().setValue(null);
		}

		this.checkCustomProperties();
	}

	checkCustomProperties(): void {
		if (!this.selectValues || !this.display) {
			return;
		}

		const val = this.ngControl.value;
		if (!val) {
			return;
		}

		const found = this.selectValues.find(value => value === val);
		if (!found) {
			this.selectValues[0] = val;
		}
	}
}

@Component({
	selector: 'yuno-forms-template-select',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<!-- STRINGS -->
			@if (!isNumber) {
				<select
					class="truncate group-hover:text-gray-500"
					[ngModel]="value"
					(ngModelChange)="onChanged($event)"
					[required]="required">
					<option [value]="null" [hidden]="!unselectDisplay">
						{{ unselectDisplay || placeholder }}
					</option>
					<option [value]="undefined" hidden>{{ placeholder }}</option>
					<option [value]="''" hidden>{{ placeholder }}</option>
					@for (select of selectValues; track select; let i = $index) {
						<option [value]="select">
							{{ returnDisplay(i, select) }}
						</option>
					}
					@for (group of selectValuesGroup; track group; let i = $index) {
						<optgroup [label]="group.optgroup">
							@for (option of group.options; track option; let j = $index) {
								<option [value]="option">
									{{ returnDisplayGroup(i, j, option) }}
								</option>
							}
						</optgroup>
					}
				</select>
			}

			<!-- NUMBERS -->
			@if (isNumber) {
				<select
					class="truncate group-hover:text-gray-500"
					[ngModel]="value"
					(ngModelChange)="onChanged($event)"
					[required]="required">
					<option [ngValue]="null" [hidden]="!unselectDisplay">
						{{ unselectDisplay || placeholder }}
					</option>
					<option [ngValue]="undefined" hidden>{{ placeholder }}</option>
					<option [ngValue]="''" hidden>{{ placeholder }}</option>
					@for (select of selectValuesNumber; track select; let i = $index) {
						<option [ngValue]="select">
							{{ returnDisplay(i, select) }}
						</option>
					}
				</select>
			}
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsSelectTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsSelectTemplateComponent
	extends YunoFormsDefaultTemplateComponent
	implements AfterViewInit
{
	/**
	 * When using CustomProrties make sure you set
	 * the Custom Property values as first in the display and selectValues array
	 */
	@Input() customProperties?: boolean;

	_selectValues?: string[];
	@Input() set selectValues(val: undefined | string[]) {
		this._selectValues = val;
		this.customProperties && this.checkCustomProperties();
	}

	get selectValues() {
		return this._selectValues;
	}

	@Input() selectValuesNumber?: number[];
	@Input() selectValuesGroup?: { optgroup: string; options: string[] }[];

	_display?: string[] | number[];
	@Input() set display(val: undefined | string[] | number[]) {
		this._display = val;
		this.customProperties && this.checkCustomProperties();
	}

	get display() {
		return this._display;
	}

	@Input() displayGroup?: { optgroup: string; options: string[] }[];

	@Input() isNumber = false;
	@Input() unselectDisplay?: string;

	@Output() changes = new EventEmitter<string | null>();
	@Output() changesDisplay = new EventEmitter<string | null>();
	@Output() changesNumber = new EventEmitter<number | null>();

	ngAfterViewInit() {
		this.customProperties && this.checkCustomProperties();
	}

	returnDisplay(index: number, select: string | number) {
		if (this.display && this.display[index]) {
			return this.display[index];
		}
		return select;
	}

	returnDisplayGroup(group: number, index: number, select: string | number) {
		if (this.displayGroup && this.displayGroup[group]) {
			return this.displayGroup[group].options[index];
		}
		return select;
	}

	onChanged(selectedValue: string) {
		// Outputs the changes
		if (this.isNumber) {
			this.valueChange(parseInt(selectedValue));
		} else {
			this.valueChange(selectedValue);

			if (this.display) {
				const ind = this.selectValues?.findIndex(value => value === selectedValue);
				if (ind !== undefined && ind >= 0) {
					this.display[ind] && this.changesDisplay.emit(this.display[ind] as string);
				}
			}
		}

		// Set the value of the form control to a real null value
		// instead of a null string
		if (selectedValue === 'null') {
			this.valueChange('');
		}

		this.checkCustomProperties();
	}

	checkCustomProperties(): void {
		if (!this.selectValues || !this.display) {
			return;
		}

		const val = this.value;
		if (!val) {
			return;
		}

		const found = this.selectValues.find(value => value === val);
		if (!found && typeof val === 'string') {
			this.selectValues[0] = val;
		}
	}
}
