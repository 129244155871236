@if (data$ | async; as data) {
	<section class="grid grid-cols-1 gap-4">
		<div class="flex justify-between gap-2">
			<button yuno-admin-button [add]="true" (click)="onAdd(undefined, data.appId)">
				Add Item
			</button>
		</div>

		@if (data.files) {
			@switch (preview === 'No') {
				@case (true) {
					<yuno-admin-table
						[selectable]="false"
						[draggable]="false"
						[filterable]="false"
						[sortable]="true"
						[pagination]="true"
						[pageOptions]="{
							pageSize: 50,
							pageSizeOptions: [10, 25, 50, 100],
							hidePageSize: false
						}"
						[defaultColumn]="'fileName'"
						[data]="data.files"
						[customTemplate]="tableImage"
						[buttons]="tableButtons"
						[activeRow]="activeFile"
						[columns]="[
							{ key: 'fileName', label: 'Name', parent: 'data' },
							{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
						]"
						(clicked)="setActiveFile($event)" />
				}
				@default {
					<yuno-admin-table
						[selectable]="false"
						[draggable]="false"
						[filterable]="false"
						[sortable]="true"
						[pagination]="true"
						[pageOptions]="{
							pageSize: 50,
							pageSizeOptions: [10, 25, 50, 100],
							hidePageSize: false
						}"
						[defaultColumn]="'url'"
						[data]="data.files"
						[customTemplate]="tableImage"
						[buttons]="tableButtons"
						[activeRow]="activeFile"
						[columns]="[
							{
								key: 'url',
								label: 'Image',
								type: 'custom',
								parent: 'data',
								hidden: preview === 'No',
								sticky: true,
								maxWidth: 17,
								sortable: false
							},
							{ key: 'fileName', label: 'Name', parent: 'data' },
							{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
						]"
						(clicked)="setActiveFile($event)" />
				}
			}
		}
	</section>

	<ng-template #tableImage let-row>
		<div
			class="bg-stripes-gray my-2 flex aspect-video w-48 items-center justify-center overflow-hidden rounded md:w-60">
			@if (row.data.ext; as ext) {
				@if (ext === '.pdf') {
					<img
						class="max-h-full object-cover"
						src="https://cdn.projectatlas.app/content/static/images/portal-pdf.svg"
						alt="pdf" />
				} @else if (ext === '.zip') {
					<img
						class="max-h-full object-cover"
						src="https://cdn.projectatlas.app/content/static/images/portal-zip.svg"
						alt="zip" />
				} @else {
					<img
						class="max-h-full object-cover"
						[src]="row.data?.url"
						[srcset]="createSrcSet(row)"
						(error)="onImgError($event, row)"
						loading="lazy"
						[alt]="row.data?.fileName" />
				}
			}
		</div>
	</ng-template>

	<ng-template #tableButtons let-row>
		<button yuno-admin-button-table color="secondary" (click)="onCopy(row)">Copy Link</button>
		<button yuno-admin-edit-button (click)="onEdit(row, data.appId)"></button>
		<button yuno-admin-delete-admin-button (click)="onDelete(row, data.appId)"></button>
	</ng-template>
}

<ng-template #sideOptions>
	<div class="flex flex-col gap-8">
		<yuno-admin-search-bar
			[debounceTime]="100"
			[minimumLength]="0"
			(searches)="applyFilter($event)" />
		<section>
			<h4>Filters</h4>
			@for (pill of filterPills; track pill.value) {
				<yuno-admin-side-options-button
					[extraPadding]="true"
					(click)="setFilter(pill.value)"
					[active]="filter === pill.value">
					{{ pill.label }}
				</yuno-admin-side-options-button>
			}
		</section>
		<section>
			<h4>Preview</h4>
			@for (pill of previewPills; track pill) {
				<yuno-admin-side-options-button
					[extraPadding]="true"
					(click)="setPreview(pill)"
					[active]="preview === pill">
					{{ pill }}
				</yuno-admin-side-options-button>
			}
		</section>
	</div>
</ng-template>
