module.exports = {
  A: {
    A: {
      "2": "K D E F A B eC"
    },
    B: {
      "1": "4 5 6 7 8 9 x AB BB CB DB EB FB GB HB I",
      "2": "C L M G N O P",
      "164": "Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2049": "w"
    },
    C: {
      "1": "4 5 6 7 8 9 w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "0 1 2 3 fC GC J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U hC iC",
      "66": "V W",
      "2305": "Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2820": "X"
    },
    D: {
      "1": "4 5 6 7 8 9 x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "J IB K D E F A B C L M G N O P JB y",
      "164": "0 1 2 3 z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2049": "w"
    },
    E: {
      "1": "EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB jC MC kC",
      "132": "A B C L NC AC BC oC",
      "164": "K D E F lC mC nC",
      "1540": "M G pC qC OC PC CC rC DC QC RC SC TC UC sC"
    },
    F: {
      "1": "j k l m n o p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "164": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h",
      "2049": "i"
    },
    G: {
      "1": "EC VC WC XC YC ZC aC FC bC",
      "2": "MC zC dC 0C",
      "132": "6C 7C 8C 9C AD BD CD DD ED FD",
      "164": "E 1C 2C 3C 4C 5C",
      "1540": "GD HD ID OC PC CC JD DC QC RC SC TC UC KD"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "2": "GC J MD ND OD PD dC",
      "164": "QD RD"
    },
    J: {
      "2": "D",
      "164": "A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "CC"
    },
    P: {
      "1": "1 2 3",
      "164": "0 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "164": "dD"
    },
    R: {
      "164": "eD"
    },
    S: {
      "2": "fD gD"
    }
  },
  B: 5,
  C: "CSS image-set",
  D: true
};