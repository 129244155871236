@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Link Editor</yuno-forms-title>
				<yuno-forms-span>You can edit the selected link component below.</yuno-forms-span>
			</section>
			<yuno-forms-text
				class="flex-1"
				formControlName="id"
				placeholder="Link name"
				label="Link name"></yuno-forms-text>
			<yuno-forms-divider></yuno-forms-divider>
			@if (data$ | async; as data) {
				<section formGroupName="display">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-text
								class="w-full"
								[language]="lang"
								[formControlName]="lang"
								placeholder="List title"
								label="Title"></yuno-forms-text>
						}
					}
				</section>
				<yuno-forms-color
					formControlName="color"
					placeholder="#3498db"
					label="Color"
					[colorDirection]="'top'">
				</yuno-forms-color>
				<yuno-forms-divider></yuno-forms-divider>
				<section>
					<yuno-forms-span class="font-semibold">Links</yuno-forms-span>
					<div
						class="flex flex-col gap-2 rounded-md border-2 border-gray-200 bg-gray-100 p-4">
						<yuno-forms-select
							class="flex-1"
							formControlName="type"
							placeholder="Select type"
							label="Type"
							[selectValues]="service.linkTypeSelectValues"
							[display]="service.linkTypeSelectDisplay"
							(changes)="onType(service.component, $event)" />

						@if (service.component.get('type')?.value === 'page') {
							<yuno-forms-select
								class="flex-1"
								formControlName="page"
								placeholder="Select page"
								label="Page"
								[selectValues]="service.linkPageSelectValues"
								[display]="service.linkPageSelectDisplay" />
						}
						@if (service.component.get('type')?.value === 'navigation') {
							<yuno-forms-select
								class="flex-1"
								formControlName="link"
								placeholder="Select route"
								label="Routes"
								[selectValues]="service.navigationSelectValues"
								[display]="service.navigationSelectDisplay" />

							<yuno-admin-annotation class="col-span-3 mt-4" type="info">
								<span
									>This <strong>Navigation route</strong> is
									<strong>not</strong> actually linked to the Navigation Page.
									<br />
									When changing the link in your Navigation, this link will be
									broken and has to be re-applied.
								</span>
							</yuno-admin-annotation>
						}
						@if (service.component.get('type')?.value === 'url') {
							<section formGroupName="url">
								@for (lang of editService.languages; track lang) {
									@if (lang === data.language) {
										<yuno-forms-text
											class="flex-1"
											[formControlName]="lang"
											[language]="data.language"
											placeholder="Link url"
											label="Url" />
									}
								}
							</section>
						}
					</div>
				</section>
			}
		</form>
	}
}
