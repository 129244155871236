module.exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K D E F eC"
    },
    B: {
      "1": "C L M G N O P",
      "516": "4 5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "132": "kB lB mB nB oB pB qB HC rB IC sB tB uB",
      "164": "0 1 2 3 fC GC J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB hC iC",
      "516": "vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a",
      "1028": "4 5 6 7 8 9 b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC"
    },
    D: {
      "420": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB",
      "516": "4 5 6 7 8 9 iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC"
    },
    E: {
      "1": "A B C L M G NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "132": "F nC",
      "164": "D E mC",
      "420": "J IB K jC MC kC lC"
    },
    F: {
      "1": "C AC cC yC BC",
      "2": "F B uC vC wC xC",
      "420": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB",
      "516": "VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "132": "4C 5C",
      "164": "E 2C 3C",
      "420": "MC zC dC 0C 1C"
    },
    H: {
      "1": "LD"
    },
    I: {
      "420": "GC J MD ND OD PD dC QD RD",
      "516": "I"
    },
    J: {
      "420": "D A"
    },
    K: {
      "1": "C AC cC BC",
      "2": "A B",
      "516": "H"
    },
    L: {
      "516": "I"
    },
    M: {
      "1028": "9B"
    },
    N: {
      "1": "A B"
    },
    O: {
      "516": "CC"
    },
    P: {
      "420": "J",
      "516": "0 1 2 3 y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "516": "dD"
    },
    R: {
      "516": "eD"
    },
    S: {
      "164": "fD gD"
    }
  },
  B: 4,
  C: "CSS3 Multiple column layout",
  D: true
};