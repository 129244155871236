import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { ParticipationNotifications } from '@yuno/api/interface';

import { ParticipationNotificationFacade } from '../../../data-access';
import { ParticipationNotificationRoutingModule } from './notifications.routing.module';


@Component({
	selector: 'yuno-admin-participation-notifications-selector',
	standalone: true,
	imports: [
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		ParticipationNotificationRoutingModule,
		AsyncPipe
	],
	templateUrl: './notifications.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationNotificationsSelectorComponent {
	data$ = combineLatest({
		list: this.facade.data$,
		selected: this.facade.selected$
	});

	constructor(
		private facade: ParticipationNotificationFacade,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.facade.get();
	}

	getData(
		data: Partial<ParticipationNotifications>[] | null | undefined
	): { [key: string]: unknown }[] {
		return data as { [key: string]: unknown }[];
	}

	onEdit(row: Partial<ParticipationNotifications>): void {
		this.router.navigate(['edit', row._id as string], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<ParticipationNotifications>): void {
		this.facade.duplicate(row._id as string);
	}

	onDelete(row: Partial<ParticipationNotifications>): void {
		this.facade.delete(row._id as string);
	}
}

@Component({
	selector: 'yuno-admin-participation-notification',
	standalone: true,
	imports: [RouterModule],
	template: '<router-outlet></router-outlet>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationNotificationComponent {}
