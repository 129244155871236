module.exports = {
  A: {
    A: {
      "132": "K D E F A B eC"
    },
    B: {
      "1": "4 5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "4": "C L M G N O P"
    },
    C: {
      "1": "4 5 6 7 8 9 hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB K D E F A B hC iC",
      "33": "0 1 2 3 C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB"
    },
    D: {
      "1": "4 5 6 7 8 9 fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB",
      "322": "TB UB VB WB XB YB ZB aB bB cB dB eB"
    },
    E: {
      "1": "DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB K D E F A B C L M G jC MC kC lC mC nC NC AC BC oC pC qC OC PC CC rC"
    },
    F: {
      "1": "SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C G N O P JB y z uC vC wC xC AC cC yC BC",
      "578": "0 1 2 3 KB LB MB NB OB PB QB RB"
    },
    G: {
      "1": "DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "2": "E MC zC dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "2": "GC J MD ND OD PD dC QD RD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD",
      "2": "J"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "gD",
      "33": "fD"
    }
  },
  B: 4,
  C: "CSS3 text-align-last",
  D: true
};