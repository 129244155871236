module.exports = {
  A: {
    A: {
      "1": "E F A B",
      "8": "K D eC"
    },
    B: {
      "1": "4 5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "1": "4 5 6 7 8 9 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "33": "0 1 2 3 fC GC J IB K D E F A B C L M G N O P JB y z KB LB MB hC iC"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "33": "J IB K D E F"
    },
    E: {
      "1": "K D E F A B C L M G kC lC mC nC NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "33": "J IB jC MC"
    },
    F: {
      "1": "0 1 2 3 B C G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x uC vC wC xC AC cC yC BC",
      "2": "F"
    },
    G: {
      "1": "E 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "33": "MC zC dC"
    },
    H: {
      "1": "LD"
    },
    I: {
      "1": "J I PD dC QD RD",
      "33": "GC MD ND OD"
    },
    J: {
      "1": "A",
      "33": "D"
    },
    K: {
      "1": "A B C H AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 5,
  C: "CSS3 Box-sizing",
  D: true
};