import { gql } from 'apollo-angular';

import { App, Config } from '@yuno/api/interface';

export const SAVE_CONFIG = gql`
	mutation saveSettings($app: AppSettingsInput!, $config: ConfigSettingsInput!, $appId: String!) {
		saveAppSettings(app: $app, appId: $appId) {
			_id
			id
			public
			url
			trackingId
			data {
				lead
				engineer
				contact
				coordinates
				extend
			}
		}
		saveConfigSettings(config: $config, appId: $appId) {
			_id
			title
			customTitle
			path
			language
			languages
			epsg

			interface {
				mapOnly
				controlZoom
				navbarType
				embedType
				breadcrumbs
				fullscreenButton
				fullscreenPopup
				embedFullscreenPopup
				buttonStyle
				help {
					mobile
					desktop
				}
			}

			help {
				popup {
					display
					icon
				}
				textfields {
					mobile
					desktop
				}
				target
			}

			colors {
				navbar {
					background
					font
					active
					activeFont
					underline
				}
				embedNavbar {
					background
					font
					active
					activeFont
					underline
				}
				states {
					background
					font
				}
				panoramas
				panoramasClusterCount
				additional
				button
				buttonFont
				textHeading
				textLink
				locationMarker
				measure
			}
		}
	}
`;

export type SaveConfigMutation = {
	saceAppSettings: Partial<App>;
	saveConfigSettings: Partial<Config>;
};
