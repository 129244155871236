import { gql } from 'apollo-angular';

import { Pages } from '@yuno/api/interface';

export const SAVE_PAGE = gql`
	mutation savePage($page: PagesInput!, $appId: String!) {
		savePage(page: $page, appId: $appId) {
			_id
			id
			name
			type
			iframeSrc
			iframeOptions {
				backgroundColor
			}
			header {
				logo {
					active
					src
					position
					width
					height
					mobileSrc
					mobileWidth
					mobileHeight
				}
				title {
					description
					projectTitle
					color
				}
			}
			banner {
				active
				imageBanner {
					active
					image
					src
					play {
						active
						description
						vimeoId
					}
					split {
						active
						description
						backgroundColor
						fontColor
					}
				}
			}
			components {
				item {
					_id
					updatedAt
					id
					class
					content
					link {
						color
						page
						title
						type
						text
						url
					}
					title {
						title
					}
					imageButtons {
						item {
							_id
							id
							size
							wide
							title
							file {
								_id
								data {
									url
								}
							}
						}
						link
						type
						url
					}
				}
				textblock {
					_id
					updatedAt
					id
					content
				}
				button {
					_id
					updatedAt
					id
					content
					activeContent
					alignBottom
					events {
						type
						options
					}
					activeEvents {
						type
						options
					}
					clientColors
				}
				container
				custom {
					_id
					updatedAt
					name
					content
				}
				image {
					_id
					updatedAt
					file {
						data {
							filename
							originalSize
							url
						}
						_id
					}
					alt
					description
					url
					zoomable
				}
				link {
					_id
					updatedAt
					id
					display
					url
					type
					link
					color
				}
				list {
					_id
					updatedAt
					id
					title
					active {
						color
						backgroundColor
					}
					items {
						id
						title
						active
					}
					keepActiveDataset
					disableActiveState
					multiselect
					select
					defaultValue
				}
				toggles {
					_id
					updatedAt
					id
					items {
						group
						title
						items {
							public
							title
						}
					}
				}
				video {
					_id
					updatedAt
					id
					type
					videoId
					description
				}
				news {
					_id
					id
					bgColor
					textColor
					textHeadingColor
					textLinkColor
					align
					size
					title
					subTitle
					content
					imageSrc
					imageFile {
						_id
						data {
							fileName
							url
						}
					}
					imageLink
					imageZoom
					secondaryImageSrc
					secondaryImageFile {
						_id
						data {
							fileName
							url
						}
					}
					imageOrder
					secondaryImageLink
					secondaryImageZoom
					videoSrc
					videoType
					description
					links {
						color
						page
						title
						type
						text
						link
						url
					}
					updatedAt
				}
				legend {
					_id
					id
					title
					content {
						public
						title
						bold
						class
						color
						border
						borderColor
						svg
						image
						gradiant {
							color
							offset
							title
						}
						gradiantHeight
						layer {
							_id
							id
						}
						activeLayers {
							_id
							id
						}
						list {
							public
							title
							bold
							class
							color
							border
							borderColor
							svg
							image
							gradiant {
								color
								offset
								title
							}
							gradiantHeight
							layer {
								_id
								id
							}
							activeLayers {
								_id
								id
							}
						}
					}
					updatedAt
				}
				notification {
					_id
					id
					type
					title
					content
					updatedAt
				}
				imageButton {
					item {
						_id
						id
						size
						wide
						title
						file {
							_id
							data {
								url
							}
						}
						updatedAt
					}
					type
					link
					url
				}
				predefined {
					selector
					options
				}
				order
				orderMobile
				pageSide
			}
		}
	}
`;

export const DUPLICATE_PAGE = gql`
	mutation duplicatePages($_id: String!, $appId: String!) {
		duplicatePage(_id: $_id, appId: $appId) {
			_id
			id
			name
			type
		}
	}
`;

export type SavePageMutation = {
	savePage: Partial<Pages>;
};

export type DuplicatePageMutation = {
	duplicatePage: Partial<Pages>;
};
