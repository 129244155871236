import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { sortBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { EventForm } from '@yuno/admin/features/events';
import { TextfieldComponentForm } from '@yuno/admin/features/textfield-pages/data-access/textfield-editor.service';
import { newLanguageFormGroup } from '@yuno/angular/forms';
import {
	CdnFile,
	LanguageType,
	Pages,
	PagesComponentKeys,
	PagesComponents
} from '@yuno/api/interface';

import { ListItemForm } from '../feature/item-editors/list-editor/list.interface';
import {
	HeaderForm,
	ImageBannerForm
} from '../feature/item-editors/page-header-editor/page-header.interface';
import { PageItemForm } from '../feature/item-editors/page-item-editor/page-item.interface';
import { TextPageComponentService } from './components.service';

export interface PageForm {
	id: FormControl<string>;
	name: FormControl<string>;
	_id: FormControl<string | null>;
	// appId: FormControl<string>;
	banner: FormGroup<{
		active: FormControl<boolean>;
		imageBanner: FormGroup<ImageBannerForm>;
	}>;
	content?: FormGroup<{
		componentsLeft: FormArray<FormGroup<PageComponentForm>>;
		componentsRight: FormArray<FormGroup<PageComponentForm>>;
	}>;
	components: FormArray<FormGroup<PageComponentForm>>;
	header: FormGroup<HeaderForm>;
	type: FormControl<'default' | 'side-by-side' | 'iframe' | 'iframe-split'>;
	iframeSrc: FormControl<string | null>;
	iframeOptions: FormGroup<{
		backgroundColor: FormControl<string>;
	}>;
}

export interface PageComponentForm extends TextfieldComponentForm {
	item?: FormGroup<PageItemForm>;
	order?: FormControl<number | null>;
	orderMobile?: FormControl<number | null>;
	pageSide?: FormControl<'left' | 'right'>;
}

@Injectable({
	providedIn: 'root'
})
export class PageEditorService {
	private readonly componentsService = inject(TextPageComponentService);
	active$ = new BehaviorSubject<boolean>(false);

	form: FormGroup<PageForm>;
	language: LanguageType;
	disableClose = false;

	layoutSelectValues = ['default', 'side-by-side', 'iframe', 'iframe-split'];
	layoutSelectDisplay = ['One column', 'Two columns', 'iFrame', 'iFrame with Text'];

	// PAGE ITEM COMPONENT
	classSelectValues: string[] = ['title', 'theme'];
	classSelectDisplay: string[] = ['Title', 'Theme'];
	linkTypeSelectValues: string[] = ['url', 'page', 'navigation', 'privacy', 'privacy-client'];
	linkTypeSelectDisplay: string[] = ['Url', 'Page', 'Routes', 'Privacy', 'Privacy-client'];
	linkPageSelectValues: string[] = [];
	linkPageSelectDisplay: string[] = [];
	navigationSelectValues: string[] = [];
	navigationSelectDisplay: string[] = [];
	imageButtonSelectValues: string[] = [];
	imageButtonSelectDisplay: string[] = [];

	// BUTTON COMPONENT
	buttonAlignSelectValues: string[] = ['left', 'right', 'default'];
	buttonAlignSelectDisplay: string[] = ['Left', 'Right', 'Default'];

	// LINK COMPONENT
	linkSelectValues: string[] = [''];
	linkSelectDisplay: string[] = ['none'];

	// VIDEO COMPONENT
	videoSelectValues: string[] = ['vimeo', 'youtube'];
	videoSelectDisplay: string[] = ['Vimeo', 'YouTube'];

	// TEXTFIELD
	textfieldValues: string[] = [];
	textfieldDisplay: string[] = [];

	// LEGEND
	legendValues: string[] = [];
	legendDisplay: string[] = [];

	// FILES
	filesValues: CdnFile[] = [];
	filesDisplay: string[] = [];

	componentIndex: number | undefined;
	componentType: string | undefined;

	eventOrigin: 'button' | 'list' = 'button';
	eventType: 'events' | 'activeEvents' = 'events';
	eventIndex = 0;

	get components(): FormArray<FormGroup<PageComponentForm>> {
		return this.form.get('components') as FormArray<FormGroup<PageComponentForm>>;
	}

	get header(): FormGroup<HeaderForm> {
		return this.form.get('header') as FormGroup<HeaderForm>;
	}

	get logo(): FormGroup {
		return this.header.get('logo') as FormGroup;
	}

	get banner(): FormGroup<{
		active: FormControl<boolean>;
		imageBanner: FormGroup<ImageBannerForm>;
	}> {
		return this.form.get('banner') as FormGroup<{
			active: FormControl<boolean>;
			imageBanner: FormGroup<ImageBannerForm>;
		}>;
	}

	get component() {
		if (this.componentIndex === undefined || !this.componentType) {
			return null;
		}

		return this.components.controls
			.at(this.componentIndex as number)
			?.get(this.componentType) as FormGroup;
	}

	get itemLinks() {
		if (!this.component) {
			return null;
		}
		return this.component.get('link') as FormArray;
	}

	get imageButtons() {
		if (!this.component) {
			return null;
		}
		return this.component.get('imageButtons') as FormArray;
	}

	/* Current EVENT */
	get events() {
		if (!this.component) {
			return null;
		}

		if (this.eventOrigin === 'button') {
			return this.component.get(this.eventType) as FormArray<FormGroup<EventForm>>;
		}

		if (this.eventOrigin === 'list') {
			if (!this.listItems) {
				return null;
			}
			return this.listItems.controls[this.eventIndex].get(this.eventType) as FormArray<
				FormGroup<EventForm>
			>;
		}
		return new FormArray<FormGroup<EventForm>>([]);
	}

	// List
	get listItems() {
		if (!this.component) {
			return new FormArray<FormGroup<ListItemForm>>([]);
		}
		return this.component.get('items') as FormArray<FormGroup<ListItemForm>>;
	}

	createFormGroup(): void {
		this.form = new FormGroup<PageForm>({
			_id: new FormControl(null),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			name: new FormControl('', { nonNullable: true, validators: Validators.required }),
			// appId: new FormControl('', { nonNullable: true }),
			content: new FormGroup({
				componentsLeft: new FormArray<FormGroup<PageComponentForm>>([]),
				componentsRight: new FormArray<FormGroup<PageComponentForm>>([])
			}),
			components: new FormArray<FormGroup<PageComponentForm>>([]),
			banner: new FormGroup({
				active: new FormControl(false, { nonNullable: true }),
				imageBanner: new FormGroup<ImageBannerForm>({
					active: new FormControl(false, { nonNullable: true }),
					reactive: new FormControl(false, { nonNullable: true }),
					image: new FormControl('', { nonNullable: true }),
					src: new FormControl('', { nonNullable: true }),
					mobileSrc: new FormControl('', { nonNullable: true }),
					play: new FormGroup({
						active: new FormControl(false, { nonNullable: true }),
						description: new FormControl('', { nonNullable: true }),
						vimeoId: new FormControl('', { nonNullable: true })
					}),
					split: new FormGroup({
						active: new FormControl(false, { nonNullable: true }),
						description: newLanguageFormGroup(),
						backgroundColor: new FormControl(''),
						fontColor: new FormControl('')
					})
				})
			}),
			header: new FormGroup<HeaderForm>({
				public: new FormControl(true, { nonNullable: true }),
				logo: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					height: new FormControl(0),
					mobileHeight: new FormControl(0),
					mobileSrc: new FormControl(''),
					mobileWidth: new FormControl(0),
					position: new FormControl('right'),
					src: new FormControl(''),
					width: new FormControl(0)
				}),
				title: new FormGroup({
					color: new FormControl('#334155', { nonNullable: true }),
					description: newLanguageFormGroup(),
					projectTitle: newLanguageFormGroup()
				})
			}),
			type: new FormControl<'default' | 'side-by-side' | 'iframe'>('default', {
				nonNullable: true
			}),
			iframeSrc: new FormControl<string | null>(null, { nonNullable: false }),
			iframeOptions: new FormGroup({
				backgroundColor: new FormControl('#fff', { nonNullable: true })
			})
		});
	}

	// Available States
	remove(index: number, side: 'left' | 'right'): void {
		// returns index if the object is the one we are looking for
		const isIndex = (object: PagesComponents) =>
			object.order === index && object.pageSide === side;

		// finds the index of the object we are looking for
		const i = (this.components.value as PagesComponents[]).findIndex(isIndex);

		// removes the object from the array
		this.components.removeAt(i);
	}

	/**
	 * Creates and adds a component to the page
	 * when the component parameter is provided
	 * it will populate and patch all data
	 */
	addComponent(
		key: string,
		component?: PagesComponents
	): Promise<FormGroup<PageComponentForm> | null> {
		return new Promise(resolve => {
			if (!key && !component) {
				console.warn('addComponent: No key nor component');
				return resolve(null);
			}

			if (!key && component) {
				const newKey = this.componentsService.getComponentKey(component);
				if (!newKey) {
					console.warn('addComponent: No key');
					return resolve(null);
				}

				key = newKey;
			}

			const form = this.componentsService.componentCreator(
				key as PagesComponentKeys,
				component
			);

			if (form && form.type) {
				const group = this.createPageComponentForm(form.type, form.formControl, component);

				// set order inside the component, when it is a new component
				// not present in the page
				if (component?.order === undefined) {
					group.patchValue({
						order: this.components?.value.length,
						orderMobile: this.components?.value.length
					});
				}

				// adds the component to the components array at a specific index
				this.components.insert(component?.order || this.components?.value.length, group);

				// sort the components based on order
				this.updateComponentIndexOrder();
				return resolve(group);
			}

			return resolve(null);
		});
	}

	/**
	 * Adds multiple components to the page
	 */
	addComponents(components?: Pages['components']): void {
		this.components.clear();
		if (!components) {
			return;
		}

		for (const component of components) {
			const key = this.componentsService.getComponentKey(component);
			if (!key) {
				continue;
			}

			const form = this.componentsService.componentCreator(
				key as PagesComponentKeys,
				component
			);
			if (form && form.type) {
				const group = this.createPageComponentForm(form.type, form.formControl, component);
				// adds the component to the components array at a specific index
				this.components.insert(component?.order || this.components?.value.length, group);
			}
		}

		this.updateComponentIndexOrder();
	}

	/**
	 * Changes all components to the default view
	 * wich should be on the Left column
	 */
	changeAllToDefaultView(): void {
		const arr = this.components.controls as FormGroup<PageComponentForm>[];
		for (const value of arr) {
			value.patchValue({ pageSide: 'left' });
		}
	}

	/**
	 * Updates the order of the components
	 * used after every duplicate, remove and addition of components
	 */
	updateComponentIndexOrder(): void {
		const arr = this.components.controls as FormGroup<PageComponentForm>[];

		const left: FormGroup<PageComponentForm>[] = sortBy(
			arr.filter(comp => comp.value?.pageSide === 'left'),
			data => data.value?.order || 0
		);

		const right: FormGroup<PageComponentForm>[] = sortBy(
			arr.filter(comp => comp.value?.pageSide === 'right'),
			data => data.value?.order || 0
		);

		const mobile: FormGroup<PageComponentForm>[] = sortBy(
			arr,
			data => data.value?.orderMobile || 0
		);

		// Update order to Array index of the other Array
		for (const [index, value] of left.entries()) {
			value.patchValue({ order: index });
		}

		for (const [index, value] of right.entries()) {
			value.patchValue({ order: index });
		}

		for (const [index, value] of mobile.entries()) {
			value.patchValue({ orderMobile: index });
		}
	}

	createPageComponentForm(
		type: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		control: FormGroup<any> | FormControl<any>,
		component?: PagesComponents
	): FormGroup<PageComponentForm> {
		const form = new FormGroup<PageComponentForm>({
			[type]: control,
			order: new FormControl(this.components.length),
			orderMobile: new FormControl(this.components.length),
			pageSide: new FormControl('left', {
				nonNullable: true
			})
		});

		if (component) {
			form.patchValue(component as never);
		}

		return form;
	}

	async addPageItemButton(index?: number) {
		if (index !== undefined && index >= 0) {
			const component = this.components.controls.at(index);
			if (!component) {
				return;
			}
			const buttons = component.get('item')?.get('imageButtons') as FormArray;
			buttons.push(this.componentsService.createItemComponentImageBtnForm());
		}
		if (this.imageButtons) {
			this.imageButtons.push(this.componentsService.createItemComponentImageBtnForm());
		}
	}

	removePageItemLink(i: number): void {
		if (this.itemLinks) {
			this.itemLinks.removeAt(i);
		}
	}

	removePageItemButton(i: number): void {
		if (this.imageButtons) {
			this.imageButtons.removeAt(i);
		}
	}

	async addPageItemLink(index?: number) {
		if (index !== undefined && index >= 0) {
			const component = this.components.controls.at(index);
			if (!component) {
				return;
			}
			const links = component.get('item')?.get('link') as FormArray;
			const linkForm = this.componentsService.createItemComponentLinkForm();
			links.push(linkForm);
		}

		if (this.itemLinks) {
			const linkForm = this.componentsService.createItemComponentLinkForm();
			this.itemLinks.push(linkForm);
		}
	}

	// LIST OPTIONS
	removeListItem(i: number): void {
		if (this.listItems) {
			this.listItems.removeAt(i);
		}
	}

	addListItem(index?: number) {
		if (index !== undefined && index >= 0) {
			const component = this.components.controls.at(index);
			if (!component) {
				return;
			}
			const items = component.get('list')?.get('items') as FormArray;
			const control = this.componentsService.componentCreator('list')?.formControl;

			items.push(control as FormGroup<ListItemForm>);
		}

		if (!index && this.listItems) {
			const control = this.componentsService.componentCreator('list')?.formControl;
			control && this.listItems.push(control as FormGroup<ListItemForm>);
		}
	}
}
