<!-- Filter -->
@if (filterable) {
	<div class="mb-2 flex flex-row gap-2">
		<div class="col-span-2 flex flex-1 items-center justify-start">
			<ng-content></ng-content>
		</div>
		<div class="flex flex-row items-center justify-start gap-2">
			<ng-content select="[searchButtonsLeft]"></ng-content>
			<yuno-admin-search-bar
				[debounceTime]="100"
				[minimumLength]="0"
				(searches)="applyFilter($event)" />
			<ng-content select="[searchButtons]"></ng-content>
		</div>
	</div>
}

<!-- Table -->
<div class="mb-6 w-full overflow-x-auto">
	<table
		mat-table
		cdkDropList
		class="w-full !bg-card"
		[ngClass]="{
			'!table-fixed': fixedLayout
		}"
		[trackBy]="trackByFunction"
		[dataSource]="dataSource"
		[cdkDropListData]="dataSource.data"
		(cdkDropListDropped)="drop($event)"
		(matSortChange)="sortData($event)"
		matSort>
		<!-- Checkbox SELECT Column -->
		<ng-container matColumnDef="select" sticky>
			<th mat-header-cell *matHeaderCellDef [style.width.rem]="6" [style.max-width.rem]="6">
				<mat-checkbox
					(change)="$event ? onSelectAll() : null"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()" />
			</th>
			<td
				mat-cell
				*matCellDef="let row"
				[style.width.rem]="6"
				[style.max-width.rem]="6"
				class="group-hover:bg-yuno-blue-gray-300">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="onSelect(row, $event)"
					[checked]="selection.isSelected(row)" />
			</td>
		</ng-container>

		<!-- Data Columns -->
		@for (column of columns; track column.key; let i = $index) {
			<ng-container
				matColumnDef="{{ column.parent ? column.parent + '.' + column.key : column.key }}"
				[sticky]="column.sticky"
				[stickyEnd]="column.stickyEnd">
				<!-- TABLE HEADER -->
				<th
					mat-header-cell
					mat-sort-header
					*matHeaderCellDef
					class="select-none !font-semibold"
					[ngClass]="{
						'!hidden': column.hidden
					}"
					[style.width.rem]="
						['checkbox', 'visible'].includes(column.type || '')
							? 6
							: column.width || undefined
					"
					[style.max-width.rem]="
						['checkbox', 'visible'].includes(column.type || '')
							? 6
							: column.maxWidth || undefined
					"
					[disabled]="column.sortable !== undefined ? !column.sortable : !sortable">
					<!-- CHECKBOX FOR MASTER TOGGLE -->
					@if (column.type === 'masterToggle') {
						<mat-checkbox
							(click)="$event ? onMasterToggle($event) : null"
							[indeterminate]="checkSelectAll(columns[i].masterToggle)"
							[checked]="
								columns[i].masterToggle === 'true' ||
								columns[i].masterToggle === 'indeterminate'
							" />
					}
					<!-- END CHECKBOX MASTER TOGGLE -->
					{{ getHeader(column) }}
				</th>

				<!-- TABLE CELLS -->
				<td
					mat-cell
					*matCellDef="let element"
					class="!font-semibold group-hover:bg-yuno-blue-gray-300"
					[ngClass]="{
						'!hidden': column.hidden
					}"
					[style.width.rem]="
						['checkbox', 'visible'].includes(column.type || '')
							? 6
							: column.width || undefined
					"
					[style.max-width.rem]="
						['checkbox', 'visible'].includes(column.type || '')
							? 6
							: column.maxWidth || undefined
					">
					@switch (column.type) {
						@case ('date') {
							{{ getDataCell(element, column) | date: 'dd-MM-YYYY, HH:mm' }}
						}
						@case ('language') {
							{{ getDataCell(element, column) | languageSelect: language }}
						}
						@case ('visible') {
							<mat-checkbox
								class="checkbox-visibility"
								[disabled]="!!column.disableVisibility"
								[checked]="getDataCell(element, column)"
								(click)="$event ? onCheckbox(element, $event) : null" />
						}
						<!--  CHECKBOX AND MASTER CHECKER  -->
						@case ('checkbox') {
							<mat-checkbox
								[disabled]="!!column.disableCheckbox"
								[checked]="getDataCell(element, column)"
								(click)="$event ? onCheckbox(element, $event) : null" />
						}
						@case ('masterToggle') {
							<mat-checkbox
								[disabled]="!!column.disableToggle"
								[checked]="getDataCell(element, column)"
								(click)="$event ? onCheckbox(element, $event) : null" />
						}
						@case ('custom') {
							<ng-container
								[ngTemplateOutlet]="customTemplate"
								[ngTemplateOutletContext]="{ $implicit: element }" />
						}
						<!--  END CHECKBOX AND MASTER CHECKER -->
						<!-- DROPDOWN -->
						@case ('select') {
							<mat-select
								[disabled]="column.disableSelect"
								(click)="$event.stopPropagation()"
								[value]="element[column.key]"
								(valueChange)="onDropdown(element, $event, column.key)">
								@for (sel of column.selectValues; track sel; let i = $index) {
									<mat-option [value]="sel.value">
										{{ sel.display || sel.value }}
									</mat-option>
								}
							</mat-select>
						}
						@case ('transform') {
							@for (sel of column.transformValues; track sel; let i = $index) {
								@if (sel.value === element[column.key]) {
									{{ sel.display || sel.value }}
								}
							}
						}
						@default {
							{{ getDataCell(element, column) }}
						}
					}
				</td>
			</ng-container>
		}

		<!-- Buttons Column -->
		<ng-container matColumnDef="buttons" stickyEnd>
			<th
				mat-header-cell
				class="select-none !font-semibold"
				*matHeaderCellDef
				[style.max-width.rem]="
					getIsMobile() || buttonEllipsis ? undefined : buttonsWidth || undefined
				"
				[style.width.rem]="
					getIsMobile() || buttonEllipsis ? undefined : buttonsWidth || undefined
				"></th>
			@if (getIsMobile() || buttonEllipsis) {
				<td
					mat-cell
					class="relative w-14 group-hover:bg-yuno-blue-gray-300"
					aria-label="row actions"
					*matCellDef="let row; let i = index"
					(mouseenter)="buttonMouseMove()"
					(mouseleave)="buttonMouseMove(false)">
					<span class="flex flex-row items-center justify-end gap-2">
						<ng-container
							[ngTemplateOutlet]="preMobileButtons"
							[ngTemplateOutletContext]="{ $implicit: row }" />
						<button
							type="button"
							class="m-4 h-6 w-6"
							(click)="mobileActiveButtons = row"
							cdkOverlayOrigin
							#trigger="cdkOverlayOrigin">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="h-6 w-6">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
							</svg>
						</button>
					</span>

					<ng-template
						cdkConnectedOverlay
						(backdropClick)="disableEllipsisButton()"
						[cdkConnectedOverlayHasBackdrop]="true"
						[cdkConnectedOverlayBackdropClass]="'bg-transparent'"
						[cdkConnectedOverlayOrigin]="trigger"
						[cdkConnectedOverlayOpen]="
							!!mobileActiveButtons && mobileActiveButtons === row
						">
						<div
							(click)="handleEllipsisClick()"
							class="z-999 grid h-auto w-auto grid-cols-1 divide-y divide-yuno-blue-gray-400/60 overflow-x-auto rounded-md border border-yuno-blue-gray-400 bg-yuno-blue-gray-200 p-4 shadow-lg">
							<ng-container
								[ngTemplateOutlet]="buttons"
								[ngTemplateOutletContext]="{ $implicit: row }" />
						</div>
					</ng-template>
				</td>
			}

			@if (!getIsMobile() && !buttonEllipsis) {
				<td
					mat-cell
					aria-label="row actions"
					class="whitespace-nowrap !text-right group-hover:bg-yuno-blue-gray-300"
					*matCellDef="let row"
					[style.max-width.rem]="buttonsWidth || undefined"
					[style.width.rem]="buttonsWidth || undefined"
					(mouseenter)="buttonMouseMove()"
					(mouseleave)="buttonMouseMove(false)">
					<ng-container
						[ngTemplateOutlet]="buttons"
						[ngTemplateOutletContext]="{ $implicit: row }" />
				</td>
			}
		</ng-container>

		<!-- Displayed Columns -->
		<tr
			mat-header-row
			*matHeaderRowDef="displayedColumns; sticky: true"
			class="mb-0 flex-initial border-0"></tr>

		<!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
		<!-- The text is rendered with CSS for now, because of a long existing bug inside Angular Material -->
		<tr *matNoDataRow class="group !h-14 cursor-pointer !font-semibold">
			<td class="group !h-14 cursor-pointer px-4 !font-semibold" colspan="1000">
				No data available!
			</td>
		</tr>

		<!-- This renders the Data Rows -->
		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns; let i = index"
			class="group !h-14 cursor-pointer !font-normal"
			[ngClass]="{
				hide: !selectable && i === 0
			}"
			cdkDrag
			[cdkDragData]="row"
			[cdkDragDisabled]="!draggable"
			(click)="onClick(row)"></tr>
	</table>
</div>

<!-- PAGINATION -->
@if (pagination) {
	<mat-paginator
		class="!bg-transparent"
		[disabled]="!pagination"
		[hidePageSize]="pageOptions.hidePageSize"
		[pageSizeOptions]="pageOptions.pageSizeOptions"
		[pageSize]="pageOptions.pageSize"
		aria-label="Select page" />
}
