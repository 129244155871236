import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_FENCE,
	DUPLICATE_FENCE,
	DeleteFenceMutation,
	DuplicateFenceMutation,
	FencesQuery,
	GET_FENCES_BY_APPID,
	GET_FENCES_BY_ID,
	GET_FENCE_BY_ID,
	SAVE_FENCE,
	SaveFenceMutation,
	SelectFenceQuery,
	SelectFencesQuery
} from '../../utils';
import { fencesActions } from './fences.actions';
import { fencesFeature } from './fences.state';

export const loadFences = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(fencesActions.load, fencesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<FencesQuery>({
						query: GET_FENCES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(fences => {
							if (!fences.data.fences) {
								throw new Error('no fences found');
							}
							return fencesActions.loadFencesSuccess({
								fences: fences.data.fences
							});
						}),
						take(1),
						catchError(error => of(fencesActions.loadFencesFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectFences = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(fencesActions.select),
			exhaustMap(fence =>
				graphql
					.query<SelectFenceQuery>({
						query: GET_FENCE_BY_ID,
						variables: {
							_id: fence._id
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data.selectedFence) {
								message.sendToast(`Error selecting fence!`, 'error');
								throw new Error('no Fence with that id found');
							}

							return fencesActions.selectFenceSuccess({
								data: fence.data.selectedFence
							});
						}),
						take(1),
						catchError(error => of(fencesActions.selectFenceFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedFence = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating fence!`, 'error');
					throw new Error('no fence found');
				}
				return fencesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(fencesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveFence = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(fencesActions.save),
			withLatestFrom(
				store.pipe(select(fencesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveFenceMutation>({
						mutation: SAVE_FENCE,
						variables: {
							fence: selected,
							appId: appId
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data?.saveFence) {
								throw new Error('error saving fence to the database');
							}
							store.dispatch(
								fencesActions.updateSelect({
									data: fence.data.saveFence
								})
							);
							return fencesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(fencesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveDataToFence = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(fencesActions.saveData),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<SaveFenceMutation>({
						mutation: SAVE_FENCE,
						variables: {
							fence: data.fence,
							appId: appId
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data?.saveFence) {
								throw new Error('error saving fence to the database');
							}
							store.dispatch(
								fencesActions.updateSelect({
									data: fence.data.saveFence
								})
							);
							return fencesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(fencesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveFenceSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Fence successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveFenceFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving fence!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateFence = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(fencesActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([fence, appId]) =>
				graphql
					.mutate<DuplicateFenceMutation>({
						mutation: DUPLICATE_FENCE,
						variables: {
							_id: fence._id,
							appId: appId
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data?.duplicateFence) {
								throw new Error('error saving Fence to the database');
							}
							store.dispatch(fencesActions.reload());
							return fencesActions.duplicateSuccess({
								fence: fence.data.duplicateFence
							});
						}),
						take(1),
						catchError(error => of(fencesActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateFenceSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Fence successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateFenceFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating fence!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteFence = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(fencesActions.delete),
			switchMap(fence =>
				graphql
					.mutate<DeleteFenceMutation>({
						mutation: DELETE_FENCE,
						variables: {
							_id: fence._id
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data?.deleteFence) {
								throw new Error('error deleting Fence from the database');
							}
							store.dispatch(fencesActions.reload());
							return fencesActions.deleteSuccess({
								_id: fence.data.deleteFence
							});
						}),
						take(1),
						catchError(error => of(fencesActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteFenceSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Fence successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteFenceFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(fencesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting fence!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const multiFences = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(fencesActions.multi),
			exhaustMap(fence =>
				graphql
					.query<SelectFencesQuery>({
						query: GET_FENCES_BY_ID,
						variables: {
							ids: fence.ids
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data.getFencesById) {
								message.sendToast(`Error selecting fence!`, 'error');
								throw new Error('no Fence with that id found');
							}

							return fencesActions.multiSuccess({
								data: fence.data.getFencesById
							});
						}),
						take(1),
						catchError(error => of(fencesActions.selectFenceFailure({ error })))
					)
			)
		),
	{ functional: true }
);
