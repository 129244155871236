@if (data$ | async; as data) {
	@if (data.style.category) {
		@if (mapStyle$ | async; as mapStyle) {
			<mgl-map
				class="z-0 h-full w-full"
				(mapLoad)="mapLoad($event)"
				(moveEnd)="moveEnd($event)"
				[style]="mapStyle"
				[zoom]="[zoom]"
				[center]="center"
				[pitch]="[pitch]"
				[bearing]="[bearing]"
				[fitBounds]="data.bounds"
				[fitBoundsOptions]="data.boundsOptions">
				<ng-content></ng-content>
				@if (service.map$ | async; as map) {
					<!-- Markers other than the Selected Marker -->
					@for (marker of getMarkers(data.style.markers, map.getZoom()); track marker) {
						@if (data.style.marker?.data?.id !== marker.data.id) {
							<yuno-project-atlas-marker
								(mouseClickEvent)="markerMouseClickEvent($event)"
								[map]="map"
								[placeMarker]="true"
								[language]="data.language"
								[setMarkerType]="data.style.category.type || 'icon'"
								[geometry]="marker.geometry"
								[data]="marker.data"></yuno-project-atlas-marker>
						}
					}
					<!-- Selected Marker -->
					@if (data.style.marker; as marker) {
						<yuno-project-atlas-marker
							(mouseClickEvent)="markerMouseClickEvent($event)"
							(dragEnd)="dragEnd($event)"
							[map]="map"
							[draggable]="data.draggable"
							[language]="data.language"
							[setMarkerType]="data.style.category.type || 'icon'"
							[geometry]="marker.geometry"
							[data]="marker.data"></yuno-project-atlas-marker>
					}

					<!-- Show Fences -->
					@if (data.style.fences; as fences) {
						@if (service.getFenceSettings()?.value) {
							<mgl-geojson-source
								id="fences"
								[data]="data.style.fences.source"></mgl-geojson-source>
							@for (fence of fences.data; track fence) {
								<mgl-layer
									[id]="fence.id"
									type="fill"
									source="fences"
									[paint]="{
										'fill-color': '#1e70bf',
										'fill-opacity': 0.28
									}">
								</mgl-layer>
								<mgl-layer
									[id]="fence.id + '_line'"
									type="line"
									source="fences"
									[paint]="{
										'line-color': '#1e70bf',
										'line-width': 3
									}">
								</mgl-layer>
							}
						}
					}
				}
			</mgl-map>
		}
	}
}
