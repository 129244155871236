import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-admin-drag-row',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './drag-row.component.html',
	styleUrls: ['./drag-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragRowComponent {
	/**
	 * changes the color to use on darker cards
	 */
	@Input() light = false;

	/**
	 * When enabled, hides the dragHandles to use as a normal row
	 */
	@Input() hideDrag = false;

	/**
	 * When disabled, hides the buttons element
	 */
	@Input() keepButtons = false;

	/**
	 * When enabled, removes the Row Height
	 */
	@Input() disableHeight = false;
}
