@switch (data.selector) {
	@case ('h1') {
		<h1 yuno-predefined>
			{{ data.options.title | languageSelect: language }}
		</h1>
	}
	@case ('h2') {
		<h2 yuno-predefined>
			{{ data.options.title | languageSelect: language }}
		</h2>
	}
	@case ('h3') {
		<h3 yuno-predefined>
			{{ data.options.title | languageSelect: language }}
		</h3>
	}
	@case ('paragraph') {
		<yuno-predefined-paragraph>
			<ng-container heading>
				{{ data.options.title | languageSelect: language }}
			</ng-container>
			<ng-container>{{ data.options.content | languageSelect: language }}</ng-container>
		</yuno-predefined-paragraph>
	}
	@case ('summary') {
		<yuno-predefined-summary
			[title]="data.options.title"
			[list]="data.options.list"
			[language]="language" />
	}
	@case ('dateSummary') {
		<yuno-predefined-date-summary
			[title]="data.options.title"
			[list]="data.options.list"
			[language]="language" />
	}
	@case ('blockquote') {
		<yuno-predefined-blockquote [color]="data.options.color || '#31AACC'">
			"{{ data.options.content | languageSelect: language }}"
		</yuno-predefined-blockquote>
	}
	@case ('divider') {
		<yuno-predefined-divider [line]="data.options.line" />
	}
	@case ('file') {
		<yuno-text-file
			[language]="language"
			[title]="data.options.title"
			[data]="data.options.file" />
	}
}
