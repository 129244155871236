import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { RoutesVariantsDto } from '@yuno/api/dto';

import { variantsActions } from './variants.actions';

export interface VariantsState {
	loaded: boolean;
	navigation: RoutesVariantsDto | undefined;
	status: Status;
}

export const initialState: VariantsState = {
	loaded: false,
	navigation: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(variantsActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(variantsActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		navigation: data,
		status: Status.SUCCESS
	})),
	on(variantsActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(variantsActions.saveSuccess, state => ({
		...state
	})),

	// Update
	on(variantsActions.update, (state, { navigation }) => ({
		...state
		// navigation: { ...state.navigation, ...navigation }
	})),
	on(variantsActions.updateFailure, (state, { error }) => ({ ...state, error }))
);

export const variantsFeature = createFeature({
	name: 'variants',
	reducer
	// extraSelectors: ({ selectNavigation }) => ({
	// navigationId: createSelector(
	// 	selectNavigation,
	// 	(state: VariantsState['navigation']) => state?._id
	// )
	// })
});
