import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Theme } from '@yuno/api/interface';

import { ThemeFacade } from '../../data-access';
import { ThemesRoutingModule } from './themes-routing.module';


@Component({
	standalone: true,
	imports: [ThemesRoutingModule, YunoAdminTableComponent, YunoAdminButtonsModule, AsyncPipe],
	selector: 'yuno-admin-themes',
	templateUrl: './themes.component.html',
	styleUrls: ['./themes.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemesComponent extends AppDataComponent implements OnInit {
	private readonly themesFacade = inject(ThemeFacade);

	data$ = combineLatest({
		themes: this.themesFacade.themes$.pipe(
			map(data => {
				const list: { [key: string]: unknown }[] = data.map(theme => ({
					_id: theme?._id || '',
					id: theme?.id || '',
					datasets: theme.datasets?.length || 0,
					defaultState: theme.states?.default?.state || '',
					states: theme.states?.available?.length || 0,
					participate: theme.modules?.participation?.button ? 'true' : '',
					threejs: theme.modules?.panoramas?.threejs?.active ? 'true' : ''
				}));
				return list as { [key: string]: unknown }[];
			})
		),
		selected: this.themesFacade.selectedTheme$
	});

	ngOnInit(): void {
		this.themesFacade.get();
	}

	onSelect(row: Partial<Theme>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Theme>): void {
		this.themesFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Theme>): void {
		this.themesFacade.delete(row._id as string);
	}

	onCreate() {
		this.themesFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}
}
