<router-outlet></router-outlet>

@if (data$ | async; as data) {
	<div class="flex justify-between">
		<button yuno-admin-add-button (click)="onCreate()">New Panorama</button>
		<div class="flex gap-2">
			@if (minimalAppRole(userRoles.EDITOR)) {
				<button yuno-admin-button (click)="uploadGeojson(appId)">
					<div class="flex gap-2">
						<svg
							viewBox="0 0 24 24"
							class="h-5 w-5"
							fill="none"
							stroke-width="1.5"
							stroke="currentColor">
							<use href="#upload-icon" />
						</svg>
						<span>Upload GeoJSON</span>
					</div>
				</button>
			}
			<button yuno-admin-button (click)="downloadGeojson(appId)" color="secondary">
				<div class="flex gap-2">
					<svg
						viewBox="0 0 24 24"
						class="h-5 w-5"
						fill="none"
						stroke-width="1.5"
						stroke="currentColor">
						<use href="#download-icon" />
					</svg>
					<span> Download GeoJSON </span>
				</div>
			</button>
		</div>
	</div>
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="false"
		[pagination]="true"
		[pageOptions]="{
			pageSize: 100,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="data.panoramas"
		[buttons]="tableButtons"
		[buttonsWidth]="20"
		[columns]="[
			{ key: 'public', type: 'visible', disableVisibility: true },
			{ key: 'display', label: 'Display' },
			{ key: 'id', label: 'ID' },
			{ key: 'iconType', label: 'Type' },
			{ key: 'states', label: 'Text per state' },
			{
				key: 'selected',
				type: 'visible',
				label: 'Intern',
				disableVisibility: true
			}
		]"
		(clicked)="onSelect($event)">
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-button-table (click)="openPanoUrlGenerator(row)" color="muted">
		Generate Url
	</button>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
