import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { MapUiButtons } from '@yuno/api/interface';

import { mapUiButtonsActions } from './map-ui-buttons.actions';
import { mapUiButtonsFeature } from './map-ui-buttons.state';

@Injectable()
export class MapUiButtonsFacade {
	private readonly store = inject(Store);

	/**
	 * Combine pieces of state using createSelector,
	 * and expose them as observables through the facade.
	 */
	loaded$ = this.store.pipe(select(mapUiButtonsFeature.selectLoaded));
	mapButtons$ = this.store.pipe(select(mapUiButtonsFeature.selectUiButtons));
	selected$ = this.store.pipe(select(mapUiButtonsFeature.selectSelected));
	status$ = this.store.pipe(select(mapUiButtonsFeature.selectStatus));

	/**
	 * Use the initialization action to perform one
	 * or more tasks in your Effects.
	 */
	get() {
		this.store.dispatch(mapUiButtonsActions.load());
	}

	select(id: string | null) {
		if (!id) {
			return;
		}
		this.store.dispatch(mapUiButtonsActions.select({ id }));
	}

	update(data: Partial<MapUiButtons>) {
		this.store.dispatch(mapUiButtonsActions.update({ data }));
	}

	save() {
		this.store.dispatch(mapUiButtonsActions.save());
	}

	delete(_id: string) {
		this.store.dispatch(mapUiButtonsActions.delete({ _id }));
	}

	clearSelect() {
		this.store.dispatch(mapUiButtonsActions.clearSelect());
	}
}
