import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-predefined-divider',
	standalone: true,
	imports: [NgClass],
	template: `
		<div
			[ngClass]="{
				'border-b-2 border-gray-400/80': line
			}"
			class="ignore-hidden mb-10 mt-6 block w-full"></div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedDividerComponent {
	@Input() line = false;
}
