import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Injector,
	Input,
	Output,
	effect,
	inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { sortBy } from 'lodash';
import { combineLatest, filter, tap } from 'rxjs';

import { MatomoTrackingService } from '@yuno/angular/matomo';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { LanguageType, Pages, PagesComponents } from '@yuno/api/interface';
import { mobileScreenCutoff } from '@yuno/libs/shared/helpers';
import { HamburgerModule } from '@yuno/project-atlas/ui';

import { TextfieldDataService, TextfieldInjectorComponent } from '../components';

export interface PageColorInterface {
	textLinkColor: string;
	textHeadingColor: string;
	buttonColor: string | undefined;
	buttonBgColor: string | undefined;
}

interface PageHeaderLogo {
	src?: string;
	width?: number;
	height?: number;
}

@Component({
	standalone: true,
	imports: [
		CommonModule,
		AngularPipesModule,
		HamburgerModule,
		TranslocoModule,
		TextfieldInjectorComponent
	],
	// providers: [provideTranslocoScope('textfields')],
	animations: [
		trigger('slideIn', [
			transition('void => *', [
				style({ transform: 'translateY(-100%)', zIndex: 50 }),
				animate(
					'600ms cubic-bezier(0.18, 0.92, 0.45, 1.05)',
					style({ transform: 'translateY(0)' })
				)
			]),
			transition('* => void', [
				style({ transform: 'translateY(0)', zIndex: 50 }),
				animate(
					'600ms cubic-bezier(0.18, 0.92, 0.45, 1.05)',
					style({ transform: 'translateY(-100%)' })
				)
			])
		])
	],
	selector: 'yuno-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss']
})
export class PagesComponent {
	private readonly route = inject(ActivatedRoute);
	private readonly service = inject(TextfieldDataService);
	private readonly matomoTracking = inject(MatomoTrackingService);
	injector = inject(Injector);

	@HostBinding('@slideIn') slideIn: unknown;

	private _data: Pages;
	private _language: LanguageType = 'nl';
	private _sidemenuActive = false;

	@Input() textLinkColor = 'inherit';
	@Input() textHeadingColor = 'inherit';

	@Input() buttonColor: string | undefined = undefined;
	@Input() buttonBgColor: string | undefined = undefined;

	@Input() disableNavbar = false;

	@Input() set sidemenuActive(bool: boolean) {
		this._sidemenuActive = bool;
	}

	get sidemenuActive(): boolean {
		return this._sidemenuActive;
	}

	@Input() set language(lang: LanguageType) {
		this._language = lang;
	}

	get language(): LanguageType {
		return this._language;
	}

	@Input() set data(data: Pages) {
		this._data = { ...data };
		this.matomoTracking.trackEvent('pages', 'load', data.id);

		this.sideBySide = data.type === 'side-by-side';

		this.mainLogo = this.setupLogo();
		this.mobileLogo = this.setupMobileLogo();
		this.sortComponents();

		this.onResize();
	}

	get data(): Pages {
		return this._data;
	}

	sideBySide = false;
	logo?: PageHeaderLogo;

	mainLogo?: PageHeaderLogo;
	mobileLogo?: PageHeaderLogo;

	components?: PagesComponents[];
	componentsMobile?: PagesComponents[];
	componentsLeft?: PagesComponents[];
	componentsRight?: PagesComponents[];

	routerData = combineLatest({
		page: this.route.data.pipe(
			filter(e => !!e),
			tap(data => {
				this.data = data['page'];
				this.textLinkColor = data['colors']?.textLinkColor;
				this.textHeadingColor = data['colors']?.textHeadingColor;

				this.buttonColor = data['colors'].buttonColor;
				this.buttonBgColor = data['colors'].buttonBgColor;
			})
		)
	});

	@Output() toggleSidemenu = new EventEmitter<boolean>();

	@HostListener('window:resize', ['$event']) onResize() {
		this.logo = this.isMobile() ? this.mobileLogo : this.mainLogo;
	}

	constructor() {
		effect(() => {
			this.language = this.service.$language();
		});
	}

	setupLogo(): PageHeaderLogo | undefined {
		if (!this.data || !this.data.header) {
			return;
		}

		return {
			src: this.data.header.logo.src || undefined,
			width: this.data.header.logo.width || undefined,
			height: this.data.header.logo.height || undefined
		};
	}

	setupMobileLogo(): PageHeaderLogo | undefined {
		if (!this.data || !this.data.header) {
			return;
		}

		return {
			src: this.data.header.logo.mobileSrc || this.data.header.logo.src || undefined,
			width: this.data.header.logo.mobileWidth || this.data.header.logo.width || undefined,
			height: this.data.header.logo.mobileHeight || this.data.header.logo.height || undefined
		};
	}

	sortComponents(): void {
		this.components = [];
		this.componentsMobile = [];
		this.componentsLeft = [];
		this.componentsRight = [];

		if (!this.data.components || this.data.components.length < 1) {
			return;
		}

		this.componentsMobile = sortBy([...this.data.components], data => data.orderMobile || 0);
		this.components = sortBy([...this.data.components], data => data.order || 0);

		this.componentsLeft = sortBy(
			[...this.data.components].filter(comp => comp.pageSide === 'left'),
			data => data.order || 0
		);

		this.componentsRight = sortBy(
			[...this.data.components].filter(comp => comp.pageSide === 'right'),
			data => data.order || 0
		);
	}

	activateSideMenu(): void {
		this.toggleSidemenu.emit(!this.sidemenuActive);
	}

	isMobile(): boolean {
		return window.innerWidth < (mobileScreenCutoff || 640);
	}
}
