<div class="flex flex-col gap-4">
	@if (form) {
		<form [formGroup]="form" class="grid h-full grid-cols-2 gap-4">
			<ng-container formGroupName="options">
				<yuno-forms-number
					formControlName="bearing"
					label="Bearing"
					class="col-span-1"
					[min]="-360"
					[max]="360"
					[step]="1" />
				<yuno-forms-number
					formControlName="pitch"
					label="Pitch"
					class="col-span-1"
					[min]="0"
					[max]="60"
					[step]="1" />
				<yuno-forms-toggle formControlName="animate" label="Animate" />
			</ng-container>
		</form>
	}
	<yuno-forms-divider />
	<yuno-admin-annotation type="info">
		<yuno-forms-span>
			The editor will automatically fix some issues with a polygon. The data beneath the
			editor will be the data saved.
		</yuno-forms-span>
	</yuno-admin-annotation>
	<div class="mt-4">
		@if (polygonValue) {
			<yuno-forms-code-editor
				label="Polygon"
				language="json"
				[height]="420"
				[codeValue]="polygonValue"
				(codeValueChange)="polyChanges($event)">
				@if (invalidValue) {
					<div class="invalid-message relative mb-2">
						<yuno-admin-annotation type="warning">
							Current Polygon is not valid!
						</yuno-admin-annotation>
					</div>
				}
			</yuno-forms-code-editor>
		}
	</div>
	<yuno-forms-span class="text-sm font-semibold">To be saved: <br /></yuno-forms-span>
	<yuno-admin-annotation type="success">
		<pre class="text-xs font-light">{{ polygonSavedValue }}</pre>
	</yuno-admin-annotation>
</div>
