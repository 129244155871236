@if (containerTabs.length) {
	<div class="mb-4 flex max-w-full flex-nowrap justify-between overflow-x-auto">
		<div class="flex h-full items-end gap-2 whitespace-nowrap">
			@for (tab of containerTabs; track tab; let i = $index) {
				<button
					(click)="onTabClick(tab, i)"
					[routerLink]="[]"
					[queryParams]="{ tab: tab.key }"
					class="inline-flex w-full transform items-center rounded-full px-4 py-1 text-base transition duration-500 ease-in-out hover:bg-yuno-blue hover:text-gray-100 focus:bg-yuno-blue-hover focus:text-gray-100"
					[ngClass]="{
						'bg-yuno-blue text-gray-100': tab.isActive,
						'text-yuno-gray-400': !tab.isActive
					}">
					{{ tab.key }}
				</button>
			}
		</div>
	</div>
}
<div
	class="rounded-lg"
	(mouseenter)="setOpacity(true)"
	(mouseleave)="setOpacity(false)"
	[ngClass]="{
		'px-4 py-2': paddingCondensed,
		'p-0': noPadding,
		'p-4 sm:p-8': !paddingCondensed && !noPadding,
		'border-0': true,
		'h-full': fullHeight,
		'bg-yuno-blue-gray-300': !blue && !blueHover && !white && !transparent,
		'bg-white': white,
		'bg-transparent': transparent,
		'bg-yuno-blue text-yuno-blue-text': blue,
		'bg-yuno-blue/20': blueHover
	}">
	<!-- Card header -->
	<h1 id="title" class="break-words text-[1.313em] font-semibold empty:hidden">
		<ng-content select="[title]"></ng-content>
	</h1>
	<h2 id="subtitle" class="break-words text-lg font-semibold empty:hidden">
		<ng-content select="[subTitle]"></ng-content>
	</h2>

	<!-- Card contents -->
	<ng-content select="[content]"></ng-content>

	<!-- Card Footer -->
	@if (footer) {
		<div>
			<div
				class="bottom-0 right-0 flex justify-end gap-2 empty:hidden"
				[ngClass]="{
					'absolute px-3 pb-3': !relativeButtons,
					'relative mt-4': relativeButtons
				}"
				[ngStyle]="{ opacity: opacity }">
				<ng-content select="[buttons]"></ng-content>
			</div>
			<!-- If buttons -->
			@if (buttons.length) {
				<!-- populate userData -->
				@if (
					{
						user: user$ | async,
						appRole: appRole$ | async
					};
					as data
				) {
					<!-- Button container -->
					<div
						class="absolute bottom-0 right-0 flex justify-end gap-2 px-3 pb-3"
						[ngStyle]="{ opacity: opacity }">
						<!-- Create buttons -->
						@for (btn of buttons; track btn) {
							<!-- Button Style -->
							@if (
								(!btn.authRole && !btn.authAppRole) ||
								(data.user?.role &&
									hasAccess(
										data.user?.role,
										data.appRole,
										btn.authRole,
										btn.authAppRole
									))
							) {
								<button
									yuno-admin-button
									[color]="btn.type"
									(click)="onBtnClick({ type: btn.key })">
									{{ btn.key }}
								</button>
							}
						}
					</div>
				}
			}
		</div>
	}
</div>
