module.exports = {
  A: {
    A: {
      "2": "K D E F A B eC"
    },
    B: {
      "1": "4 5 6 7 8 9 G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "2": "C L M"
    },
    C: {
      "1": "4 5 6 7 8 9 LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "33": "0 1 2 3 fC GC J IB K D E F A B C L M G N O P JB y z KB hC iC"
    },
    D: {
      "1": "4 5 6 7 8 9 yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "33": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB"
    },
    E: {
      "1": "B C L M G AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "33": "J IB K D E F A jC MC kC lC mC nC NC"
    },
    F: {
      "1": "C nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x yC BC",
      "2": "F B uC vC wC xC AC cC",
      "33": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB"
    },
    G: {
      "2": "E MC zC dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "2": "GC J MD ND OD PD dC QD RD"
    },
    J: {
      "33": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "2": "9B"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "2": "0 1 2 3 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "2": "fD gD"
    }
  },
  B: 2,
  C: "CSS grab & grabbing cursors",
  D: true
};