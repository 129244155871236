import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoLanguageIconsComponent } from '../_language-icons/language-icons.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';
import { YunoWarningIconsComponent } from '../_warning-icons/warning-icons.component';

@Component({
	selector: 'yuno-forms-text',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<input
				class="truncate"
				type="text"
				[id]="ngControl.name"
				[name]="ngControl.name"
				[placeholder]="placeholder"
				[formControl]="getFormControl()"
				(change)="onChanged($event)"
				(blur)="onBlurred($event)" />

			<yuno-language-icons [language]="language" [padding]="!!label"></yuno-language-icons>
			<yuno-warning-icons [type]="icon" [padding]="!!label"></yuno-warning-icons>

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired,
		YunoWarningIconsComponent,
		YunoLanguageIconsComponent
	]
})
export class YunoFormsTextComponent extends YunoFormsDefaultComponent {
	@Output() changes = new EventEmitter<string>();
	@Input() language?: string;
	@Input() icon?: string;

	onChanged(event: Event): void {
		// Get the value of the selected option
		const selectedValue = (event.target as HTMLInputElement).value;

		// Outputs the changes
		this.changes.emit(selectedValue);
	}
}

@Component({
	selector: 'yuno-forms-text-template',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<input
				class="truncate"
				type="text"
				[pattern]="pattern"
				[ngModel]="value"
				(ngModelChange)="valueChange($event)"
				[disabled]="isDisabled"
				[placeholder]="placeholder"
				[required]="required" />

			<yuno-language-icons [language]="language" [padding]="!!label"></yuno-language-icons>
		</label>
	`,
	styles: `
		:host {
			display: inline-block;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsLabelRequired, YunoLanguageIconsComponent],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsTextTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsTextTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() language?: string;
}
