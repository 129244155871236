<div class="flex flex-col gap-2 p-4 sm:flex-row">
	@if (!router.url.includes('list')) {
		<yuno-admin-ui-selector-button (click)="backToBounds()">
			Back to overview
		</yuno-admin-ui-selector-button>

		@if (
			!router.url.includes('list') &&
			!router.url.includes('map/photo') &&
			minimalAppRole(userRoles.EDITOR)
		) {
			<yuno-admin-ui-selector-button
				color="primary"
				[add]="true"
				(click)="openEditor(['photo', 'create'])">
				Add Marker
			</yuno-admin-ui-selector-button>
		}
	}
</div>

<ng-template #sideOptions>
	<div class="flex flex-col gap-8">
		<section>
			<h4>Filters</h4>
			<yuno-admin-side-options-button
				[extraPadding]="true"
				(click)="filterMarkers('all')"
				[active]="$filter() === 'all'">
				All
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				[extraPadding]="true"
				(click)="filterMarkers('public')"
				[active]="$filter() === 'public'">
				Public
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				[extraPadding]="true"
				(click)="filterMarkers('non-public')"
				[active]="$filter() === 'non-public'">
				Non-public
			</yuno-admin-side-options-button>
		</section>
		<section>
			<h4>Display</h4>
			<yuno-admin-side-options-button
				[extraPadding]="true"
				#rla1="routerLinkActive"
				routerLinkActive
				[routerLink]="['../', 'map']"
				queryParamsHandling="merge"
				[active]="rla1.isActive">
				Map
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				[extraPadding]="true"
				#rla2="routerLinkActive"
				routerLinkActive
				[routerLink]="['../', 'list']"
				queryParamsHandling="merge"
				[active]="rla2.isActive">
				List
			</yuno-admin-side-options-button>
		</section>
	</div>
</ng-template>
