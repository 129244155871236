import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	PLATFORM_ID,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable, debounceTime, map } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { PanoramaUrlGeneratorService } from '@yuno/admin/features/panoramas/feature/url-generator/panorama-url-generator.service';
import { ThemeFacade } from '@yuno/admin/features/themes';
import {
	AnnotationComponent,
	YunoAdminButtonComponent,
	YunoAdminCardComponent,
	YunoAdminCardModalComponent
} from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { MessageService, ToastItem } from '@yuno/angular/notifications';

@Component({
	selector: 'yuno-panorama-url-generator',
	standalone: true,
	imports: [
		YunoAdminCardComponent,
		YunoFormsModule,
		ReactiveFormsModule,
		AnnotationComponent,
		YunoAdminButtonComponent,
		YunoAdminCardModalComponent,
		AsyncPipe
	],
	providers: [PanoramaUrlGeneratorService],
	template: `
		<yuno-card-modal>
			<ng-container title>Panorama url generator: {{ service.id.value }}</ng-container>
			<ng-container content>
				<form [formGroup]="service.form" class="z-1 grid grid-cols-1 gap-4">
					<div class="flex flex-col items-end gap-2">
						<div
							class="w-full max-w-full overflow-auto break-all rounded-md border-2 border-yuno-blue-gray-900 bg-yuno-blue-gray-800 p-4 text-xs text-yuno-blue-gray-400">
							<code>
								@if (service.iframe.value) {
									{{ iframe.trim() }}
								} @else {
									{{ url.trim() }}
								}
							</code>
						</div>
						<button yuno-admin-button color="secondary" (click)="copyToClipboard()">
							copy to clipboard
						</button>
					</div>
					@if (themes$ | async; as themes) {
						<yuno-forms-select
							class="flex-1"
							formControlName="theme"
							label="Theme"
							placeholder="select a theme"
							[selectValues]="themes.id"
							[display]="themes.display" />

						<yuno-forms-select
							class="flex-1"
							formControlName="state"
							label="State"
							[placeholder]="getPlaceholder()"
							[selectValues]="selectedThemeStates"
							[display]="selectedThemeStates" />
					}

					<div class="grid grid-cols-3">
						<yuno-forms-checkbox
							class="col-span-3"
							formControlName="iframe"
							label="Embed code" />

						<yuno-forms-checkbox formControlName="disableUi" label="Hide the ui" />

						<yuno-forms-checkbox
							formControlName="disableThree"
							label="Do not load ThreeJS" />

						<yuno-forms-checkbox
							formControlName="disableStates"
							label="Do not show states" />
					</div>

					<yuno-admin-annotation type="info" class="text-sm">
						ThreeJS and States will be loaded as they are inside the Theme. When you
						want to disable them for this panorama, you can use the checkboxes.
					</yuno-admin-annotation>
				</form>
			</ng-container>
			<ng-container buttons>
				<button yuno-admin-button color="secondary" (click)="close()">close</button>
			</ng-container>
		</yuno-card-modal>
	`,
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoramaUrlGeneratorComponent implements OnInit {
	private readonly environment = inject(ENVIRONMENT);
	private readonly destroyRef = inject(DestroyRef);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly message = inject(MessageService);

	private readonly platformId = inject(PLATFORM_ID);

	service = inject(PanoramaUrlGeneratorService);

	@Input() clientUrl: string;
	@Input() appUrl: string;

	@Input() set panoId(val: string | undefined) {
		this.service.form.patchValue({ id: val });
	}

	get panoId(): string | undefined {
		return this.service.form?.get('id')?.value;
	}

	@Output() closing = new EventEmitter<void>();

	url = '';
	iframe = '';
	themeStates: {
		[key: string]: {
			default: string | undefined;
			states: string[] | undefined;
		};
	} = {};
	selectedThemeStates: string[] = [];

	themes$: Observable<{ display: string[]; id: string[] }> = this.themeFacade.themes$.pipe(
		map(themes => {
			if (!themes || themes.length <= 0) return { display: [], id: [] };

			this.service.theme.patchValue(themes[0]._id as string);
			this.service.state.patchValue(themes[0].states?.default?.state as string);

			this.themeStates = Object.fromEntries(
				themes.map(theme => [
					theme._id,
					{
						default: theme.states?.default?.state,
						states: theme.states?.available?.map(state => state.state?.state)
					}
				])
			);

			return {
				id: themes.map(theme => theme._id as string) || [],
				display: themes.map(theme => theme.id as string) || []
			};
		})
	);

	ngOnInit(): void {
		this.themeFacade.get();
		this.onChanges();

		this.update();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.update();
			});
	}

	getPlaceholder(): string {
		return this.selectedThemeStates.length > 0 ? 'select a state' : 'no states available';
	}

	update(): void {
		this.selectedThemeStates = this.themeStates[this.service.theme.value]?.states || [];

		if (this.selectedThemeStates.length <= 0) {
			this.service.state.patchValue('');
			this.service.state.disable();
		} else {
			this.setDefaultState();
			this.service.state.enable();
		}

		this.updateUrl();
		this.updateIframe();

		this.cdr.detectChanges();
	}

	setDefaultState(): void {
		if (
			this.service.state.value &&
			this.selectedThemeStates.includes(this.service.state.value)
		) {
			return;
		}

		this.service.state.patchValue(
			this.themeStates[this.service.theme.value]?.default as string
		);
	}

	copyToClipboard(): void {
		if (isPlatformBrowser(this.platformId)) {
			let text = this.url;
			if (this.service.iframe.value) {
				text = this.iframe;
			}

			navigator.clipboard.writeText(text);
			const data: ToastItem = {
				message: `copied to clipboard`
			};
			this.message.showToast(data, 'success');
		}
	}

	updateUrl(): void {
		let url = `https://${this.clientUrl}.${this.environment['yuno-main']}/pano/${this.appUrl}/${this.service.id.value}/${this.service.theme.value}`;

		if (this.service.state.value) {
			url = url.concat(`/${this.service.state.value}`);
		}

		const queryParams = [];
		if (this.service.disableUi.value) {
			queryParams.push('disableUi=true');
		}

		if (this.service.disableStates.value) {
			queryParams.push('disableStates=true');
		}
		if (this.service.disableThree.value) {
			queryParams.push('disableThree=true');
		}

		if (queryParams.length >= 1) {
			url = url.concat('?', queryParams.join('&'));
		}

		this.url = url;
	}

	updateIframe(): void {
		this.iframe = `<iframe title="${this.panoId}" src="${this.url}" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer"></iframe>`;
	}

	close(): void {
		this.closing.emit();
	}
}
