import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { DashboardHelpItem } from '@yuno/api/interface';

import { dashboardActions } from './dashboard.actions';
import { dashboardFeature } from './dashboard.state';

@Injectable()
export class DashboardFacade {
	private readonly store = inject(Store);

	dashboard$ = this.store.pipe(select(dashboardFeature.selectDashboard));
	selected$ = this.store.pipe(select(dashboardFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(dashboardFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(dashboardFeature.selectStatus));

	getDashboardByAppId(key: 'dashboard' | 'planning' | 'deliverables' | 'tickets') {
		this.store.dispatch(dashboardActions.load({ key }));
	}

	addDashboardItem() {
		this.store.dispatch(dashboardActions.addItem());
	}

	removeDashboardItem(index: number) {
		this.store.dispatch(dashboardActions.removeItem({ index }));
	}

	getSelected(index: number | null) {
		if (index === null) {
			return;
		}
		this.store.dispatch(dashboardActions.select({ index }));
	}

	clearSelect() {
		this.store.dispatch(dashboardActions.clearSelect());
	}

	updateSelect(data: DashboardHelpItem) {
		this.store.dispatch(dashboardActions.updateSelect({ data }));
	}

	updatePublic(data: boolean) {
		this.store.dispatch(dashboardActions.updatePublic({ public: data }));
	}

	update() {
		this.store.dispatch(dashboardActions.update());
	}

	save() {
		this.store.dispatch(dashboardActions.save());
	}
}
