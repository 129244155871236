module.exports = {
  A: {
    A: {
      "2": "K D E F A B eC"
    },
    B: {
      "1": "4 5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "2": "C L M G N O P"
    },
    C: {
      "1": "4 5 6 7 8 9 iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "16": "fC",
      "33": "0 1 2 3 GC J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB hC iC"
    },
    D: {
      "1": "4 5 6 7 8 9 vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "16": "J IB K D E F A B C L M",
      "33": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB"
    },
    E: {
      "1": "F A B C L M G nC NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "16": "J IB K jC MC kC",
      "33": "D E lC mC"
    },
    F: {
      "1": "kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "33": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB"
    },
    G: {
      "1": "4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "16": "MC zC dC 0C",
      "33": "E 1C 2C 3C"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "16": "GC J MD ND OD PD dC",
      "33": "QD RD"
    },
    J: {
      "16": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z WD NC XD YD ZD aD bD DC EC FC cD",
      "16": "J",
      "33": "SD TD UD VD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "gD",
      "33": "fD"
    }
  },
  B: 5,
  C: "CSS :any-link selector",
  D: true
};