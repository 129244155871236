import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	ADD_APP_USER,
	AddUserMutation,
	AppQuery,
	AppUserClientQuery,
	AppUserQuery,
	GET_APP_USERS,
	GET_APP_USERS_CLIENT,
	GET_CLIENT_APP_AND_CONFIG,
	REMOVE_APP_USER,
	RemoveUserMutation,
	SAVE_CONFIG,
	SaveConfigMutation,
	UPDATE_APP_USER,
	UpdateUserMutation
} from './../../utils/graphql';
import { appActions } from './app.actions';
import { appFeature } from './app.state';

export const app = createEffect(
	(
		actions$ = inject(Actions),
		environment = inject(ENVIRONMENT),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(appActions.app),
			switchMap(app =>
				graphql
					.query<AppQuery>({
						query: GET_CLIENT_APP_AND_CONFIG,
						variables: {
							appId: app.appId
						}
					})
					.pipe(
						map(app => {
							if (!app.data.appById) {
								throw new Error('no app data');
							}

							const appUrl = `${app.data.clientByAppId?.url?.[0]}.${environment['yuno-main']}/${app.data.appById?.url?.[0]}`;
							return appActions.appSuccess({
								client: app.data.clientByAppId,
								app: app.data.appById,
								config: app.data.configByAppId,
								url: appUrl
							});
						}),
						take(1),
						catchError(error => of(appActions.appFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadAppUsers = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(appActions.loadAppUsers),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<AppUserQuery>({
						query: GET_APP_USERS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(app => {
							if (!app.data.appUsers) {
								throw new Error('No App Users');
							}

							return appActions.loadAppUsersSuccess({
								data: app.data.appUsers
							});
						}),
						take(1),
						catchError(error => of(appActions.loadAppUsersFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadAppUsersClient = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(appActions.loadAppUsersClient),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<AppUserClientQuery>({
						query: GET_APP_USERS_CLIENT,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(app => {
							if (!app.data.appUsersClient) {
								throw new Error('No App Users');
							}

							return appActions.loadAppUsersSuccess({
								data: app.data.appUsersClient
							});
						}),
						take(1),
						catchError(error => of(appActions.loadAppUsersFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedLayer = createEffect(
	(actions$ = inject(Actions), router = inject(Router)) =>
		actions$.pipe(
			ofType(appActions.appRedirect),
			tap(() => {
				router.navigateByUrl('/error/notfound');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveSettings = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(appActions.saveSettings),
			withLatestFrom(
				store.pipe(select(appFeature.selectApp)),
				store.pipe(select(appFeature.selectConfig)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, app, config, appId]) =>
				graphql
					.mutate<SaveConfigMutation>({
						mutation: SAVE_CONFIG,
						variables: {
							config,
							app: {
								_id: app?._id,
								id: app?.id,
								public: app?.public,
								url: app?.url,
								trackingId: app?.trackingId,
								data: app?.data
							},
							appId: appId
						}
					})
					.pipe(
						map(settings => {
							if (!settings.data?.saveConfigSettings) {
								throw new Error('error updating app config');
							}
							return appActions.saveSettingsSuccess();
						}),
						take(1),
						catchError(error => of(appActions.saveSettingsFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveSettingsSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(appActions.saveSettingsSuccess),
			tap(() => {
				message.sendToast(`App settings successfully updated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveSettingsFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(appActions.saveSettingsFailure),
			tap(() => {
				message.sendToast(`Error updating the app config!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const addAppUser = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		graphql = inject(GraphQLService),
		message = inject(MessageService)
	) =>
		actions$.pipe(
			ofType(appActions.addAppUser),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<AddUserMutation>({
						mutation: ADD_APP_USER,
						variables: {
							user: data.user,
							appId: appId
						}
					})
					.pipe(
						map(user => {
							if (!user.data?.addAppUser) {
								throw new Error('error updating app users');
							}

							store.dispatch(appActions.loadAppUsers());
							message.sendToast(`User successfully added!`, 'success');
							return appActions.saveAppUserSuccess();
						}),
						take(1),
						catchError(error => {
							if (error && error.length >= 1) {
								message.sendToast(`Error adding user to the app!`, 'error');
							}
							return of(appActions.saveAppUserFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateAppUser = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		graphql = inject(GraphQLService),
		message = inject(MessageService)
	) =>
		actions$.pipe(
			ofType(appActions.updateAppUser),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.mutate<UpdateUserMutation>({
						mutation: UPDATE_APP_USER,
						variables: {
							user: action.user,
							appId: appId
						}
					})
					.pipe(
						map(users => {
							if (!users.data?.updateAppUser) {
								throw new Error('error updating app users');
							}

							store.dispatch(appActions.loadAppUsers());
							message.sendToast(`User successfully updated!`, 'success');
							return appActions.saveAppUserSuccess();
						}),
						take(1),
						catchError(error => {
							if (error && error.length >= 1) {
								message.sendToast(`Error updating user's settings!`, 'error');
							}
							return of(appActions.saveAppUserFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const removeAppUser = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		graphql = inject(GraphQLService),
		message = inject(MessageService)
	) =>
		actions$.pipe(
			ofType(appActions.removeAppUser),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.mutate<RemoveUserMutation>({
						mutation: REMOVE_APP_USER,
						variables: {
							user: action.user,
							appId: appId
						}
					})
					.pipe(
						map(users => {
							if (!users.data?.removeAppUser) {
								throw new Error(`Error removing user`);
							}
							if (action.client) {
								store.dispatch(appActions.loadAppUsersClient());
							} else {
								store.dispatch(appActions.loadAppUsers());
							}
							message.sendToast(`User successfully removed!`, 'success');
							return appActions.saveAppUserSuccess();
						}),
						take(1),
						catchError(error => {
							if (error && error.length >= 1) {
								message.sendToast(
									`Error removing user, ${action.user.user?.displayName}`,
									'error'
								);
							}
							return of(appActions.saveAppUserFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);
