module.exports = {
  A: {
    A: {
      "1": "B",
      "2": "K D E F eC",
      "164": "A"
    },
    B: {
      "1": "4 5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "1": "4 5 6 7 8 9 HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB hC iC",
      "8": "0 1 2 3 K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB",
      "328": "ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB"
    },
    D: {
      "1": "4 5 6 7 8 9 nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "J IB K D E F A B C L M G N O P JB y z",
      "8": "0 1 2 3 KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB",
      "584": "kB lB mB"
    },
    E: {
      "1": "L M G oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB K jC MC kC",
      "8": "D E F A B C lC mC nC NC AC",
      "1096": "BC"
    },
    F: {
      "1": "aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "8": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB",
      "584": "XB YB ZB"
    },
    G: {
      "1": "DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "8": "E MC zC dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD",
      "6148": "CD"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "8": "GC J MD ND OD PD dC QD RD"
    },
    J: {
      "8": "D A"
    },
    K: {
      "1": "H",
      "2": "A",
      "8": "B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "1": "B",
      "36": "A"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z TD UD VD WD NC XD YD ZD aD bD DC EC FC cD",
      "2": "SD",
      "8": "J"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "gD",
      "328": "fD"
    }
  },
  B: 2,
  C: "Pointer events",
  D: true
};