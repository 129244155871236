module.exports = {
  "0": "22",
  "1": "23",
  "2": "24",
  "3": "25",
  "4": "115",
  "5": "116",
  "6": "117",
  "7": "118",
  "8": "119",
  "9": "120",
  A: "10",
  B: "11",
  C: "12",
  D: "7",
  E: "8",
  F: "9",
  G: "15",
  H: "80",
  I: "129",
  J: "4",
  K: "6",
  L: "13",
  M: "14",
  N: "16",
  O: "17",
  P: "18",
  Q: "79",
  R: "81",
  S: "83",
  T: "84",
  U: "85",
  V: "86",
  W: "87",
  X: "88",
  Y: "89",
  Z: "90",
  a: "91",
  b: "92",
  c: "93",
  d: "94",
  e: "95",
  f: "96",
  g: "97",
  h: "98",
  i: "99",
  j: "100",
  k: "101",
  l: "102",
  m: "103",
  n: "104",
  o: "105",
  p: "106",
  q: "107",
  r: "108",
  s: "109",
  t: "110",
  u: "111",
  v: "112",
  w: "113",
  x: "114",
  y: "20",
  z: "21",
  AB: "121",
  BB: "122",
  CB: "123",
  DB: "124",
  EB: "125",
  FB: "126",
  GB: "127",
  HB: "128",
  IB: "5",
  JB: "19",
  KB: "26",
  LB: "27",
  MB: "28",
  NB: "29",
  OB: "30",
  PB: "31",
  QB: "32",
  RB: "33",
  SB: "34",
  TB: "35",
  UB: "36",
  VB: "37",
  WB: "38",
  XB: "39",
  YB: "40",
  ZB: "41",
  aB: "42",
  bB: "43",
  cB: "44",
  dB: "45",
  eB: "46",
  fB: "47",
  gB: "48",
  hB: "49",
  iB: "50",
  jB: "51",
  kB: "52",
  lB: "53",
  mB: "54",
  nB: "55",
  oB: "56",
  pB: "57",
  qB: "58",
  rB: "60",
  sB: "62",
  tB: "63",
  uB: "64",
  vB: "65",
  wB: "66",
  xB: "67",
  yB: "68",
  zB: "69",
  "0B": "70",
  "1B": "71",
  "2B": "72",
  "3B": "73",
  "4B": "74",
  "5B": "75",
  "6B": "76",
  "7B": "77",
  "8B": "78",
  "9B": "130",
  AC: "11.1",
  BC: "12.1",
  CC: "15.5",
  DC: "16.0",
  EC: "17.0",
  FC: "18.0",
  GC: "3",
  HC: "59",
  IC: "61",
  JC: "82",
  KC: "131",
  LC: "132",
  MC: "3.2",
  NC: "10.1",
  OC: "15.2-15.3",
  PC: "15.4",
  QC: "16.1",
  RC: "16.2",
  SC: "16.3",
  TC: "16.4",
  UC: "16.5",
  VC: "17.1",
  WC: "17.2",
  XC: "17.3",
  YC: "17.4",
  ZC: "17.5",
  aC: "17.6",
  bC: "18.1",
  cC: "11.5",
  dC: "4.2-4.3",
  eC: "5.5",
  fC: "2",
  gC: "133",
  hC: "3.5",
  iC: "3.6",
  jC: "3.1",
  kC: "5.1",
  lC: "6.1",
  mC: "7.1",
  nC: "9.1",
  oC: "13.1",
  pC: "14.1",
  qC: "15.1",
  rC: "15.6",
  sC: "16.6",
  tC: "TP",
  uC: "9.5-9.6",
  vC: "10.0-10.1",
  wC: "10.5",
  xC: "10.6",
  yC: "11.6",
  zC: "4.0-4.1",
  "0C": "5.0-5.1",
  "1C": "6.0-6.1",
  "2C": "7.0-7.1",
  "3C": "8.1-8.4",
  "4C": "9.0-9.2",
  "5C": "9.3",
  "6C": "10.0-10.2",
  "7C": "10.3",
  "8C": "11.0-11.2",
  "9C": "11.3-11.4",
  AD: "12.0-12.1",
  BD: "12.2-12.5",
  CD: "13.0-13.1",
  DD: "13.2",
  ED: "13.3",
  FD: "13.4-13.7",
  GD: "14.0-14.4",
  HD: "14.5-14.8",
  ID: "15.0-15.1",
  JD: "15.6-15.8",
  KD: "16.6-16.7",
  LD: "all",
  MD: "2.1",
  ND: "2.2",
  OD: "2.3",
  PD: "4.1",
  QD: "4.4",
  RD: "4.4.3-4.4.4",
  SD: "5.0-5.4",
  TD: "6.2-6.4",
  UD: "7.2-7.4",
  VD: "8.2",
  WD: "9.2",
  XD: "11.1-11.2",
  YD: "12.0",
  ZD: "13.0",
  aD: "14.0",
  bD: "15.0",
  cD: "19.0",
  dD: "14.9",
  eD: "13.52",
  fD: "2.5",
  gD: "3.0-3.1"
};