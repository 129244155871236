import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { map } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { ApiService } from '@yuno/angular/api';
import { Fence } from '@yuno/api/interface';

import { FencesFacade } from '../../data-access';
import { FencesRoutingModule } from './fences-routing.module';
import { FenceService } from './fences.service';


@Component({
	selector: 'yuno-admin-fences',
	standalone: true,
	imports: [FencesRoutingModule, YunoAdminTableComponent, YunoAdminButtonsModule, AsyncPipe],
	providers: [FenceService, ApiService],
	templateUrl: './fences.component.html',
	styleUrls: ['./fences.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FencesComponent extends AppDataComponent {
	private readonly fenceFacade = inject(FencesFacade);
	private readonly service = inject(FenceService);

	id?: string;
	hoveredItem: Partial<Fence>;

	fences$ = this.fenceFacade.fences$.pipe(map(data => data as { [key: string]: unknown }[]));

	onCreate() {
		this.fenceFacade.clearSelect();

		this.id = undefined;
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.hoveredItem = { _id: '' };
	}

	onSelect(row: Partial<Fence>) {
		this.router.navigate(['edit', row._id as string], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Fence>) {
		this.fenceFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Fence>) {
		this.fenceFacade.delete(row._id as string);
	}

	downloadGeojson() {
		this.service.downloadGeojson(this.appId);
	}

	uploadGeojson() {
		this.service.uploadGeojson(this.appId);
	}
}
