/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, InjectionToken, inject } from "@angular/core";
import { ApolloError, ApolloQueryResult, MutationOptions, NetworkStatus } from "@apollo/client/core";
import { Apollo, MutationResult, WatchQueryOptions } from "apollo-angular";
import { GraphQLError } from "graphql";
import { Observable, ReplaySubject, catchError, of, switchMap, throwError } from "rxjs";



import { AuthService } from "@yuno/angular-auth";



import { AccessTokenStorageService } from "../../../auth/src/lib/services/access-token-storage.service";


export const API_URL = new InjectionToken('ApiUrl');

export class QueryResult<T> {
	data: T;
	errors?: readonly GraphQLError[] | undefined;
	error?: ApolloError | undefined;
	loading: true;
	networkStatus: NetworkStatus;
	partial?: boolean | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(obj: any) {
		this.data = obj.data;
		this.errors = obj.errors || undefined;
		this.error = obj.error || undefined;
		this.loading = obj.loading || false;
		this.networkStatus = obj.networkStatus || NetworkStatus.ready;
		this.partial = obj.partial || undefined;
	}
}

@Injectable({
	providedIn: 'root'
})
export class GraphQLService {
	private readonly apollo = inject(Apollo);
	private readonly authService = inject(AuthService); // Inject your AuthService to refresh tokens
	private readonly accessTokenStorage = inject(AccessTokenStorageService); // Inject your AccessTokenStorageService

	private isRefreshing = false;
	private refreshTokenSubject: ReplaySubject<string | null> = new ReplaySubject(1); // Hold the token refresh state

	query<T>(options: {
		query: WatchQueryOptions['query'];
		variables?: { [key: string]: unknown };
	}): Observable<ApolloQueryResult<T>> {
		return this.apollo
			.query({
				query: options.query,
				variables: options.variables
			})
			.pipe(
				switchMap(data => {
					if (!data.errors) {
						return of(data);
					}
					// Catch error and handle UNAUTHENTICATED
					if (data.errors && this.hasUnauthenticatedError(data.errors)) {
						// Pass the retry function so the query can be retried after refreshing
						return this.handleUnauthenticatedError(() => this.query<T>(options));
					}

					return throwError(() => data.errors); // Rethrow any non-UNAUTHENTICATED errors
				})
			);
	}

	mutate<T>(options: {
		mutation: MutationOptions['mutation'];
		variables?: { [key: string]: unknown };
	}): Observable<MutationResult<T>> {
		return this.apollo
			.mutate({
				mutation: options.mutation,
				variables: options.variables
			})
			.pipe(
				switchMap(data => {
					if (!data.errors) {
						return of(data);
					}

					// Catch error and handle UNAUTHENTICATED
					if (data.errors && this.hasUnauthenticatedError(data.errors)) {
						// Pass the retry function so the mutation can be retried after refreshing
						return this.handleUnauthenticatedError(() => this.mutate<T>(options));
					}
					return throwError(() => data.errors); // Rethrow any non-UNAUTHENTICATED errors
				})
			);
	}

	// Handle GraphQL Errors and allow for re-running the failed request
	// Helper function to check for UNAUTHENTICATED errors
	private hasUnauthenticatedError(errors: readonly any[]): boolean {
		return errors.some(error => error.extensions?.code === 'UNAUTHENTICATED');
	}

	// Handle UNAUTHENTICATED error and re-run the failed query or mutation
	private handleUnauthenticatedError(retry: () => Observable<any>): Observable<any> {
		if (!this.isRefreshing) {
			this.isRefreshing = true;

			// Notify other requests that the refresh process has started
			this.refreshTokenSubject.next(null);

			return this.authService.refreshAuth().pipe(
				switchMap(auth => {
					this.isRefreshing = false;
					this.accessTokenStorage.saveAccessToken(auth.accessToken); // Save new access token

					// Notify the subject that the token has been refreshed
					this.refreshTokenSubject.next(auth.accessToken);

					// Retry the original request and return the result
					return retry();
				}),
				catchError(error => {
					this.isRefreshing = false;
					this.refreshTokenSubject.error(error); // Notify all pending requests of the error
					return throwError(() => error); // Re-throw the error
				})
			);
		} else {
			// If token refresh is already in progress, wait for the result
			return this.refreshTokenSubject.pipe(
				switchMap(token => {
					if (token !== null) {
						// Once the token is refreshed, retry the original request
						return retry();
					}
					return throwError(() => new Error('Refresh failed or did not happen.'));
				})
			);
		}
	}
}
