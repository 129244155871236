module.exports = {
  A: {
    A: {
      "2": "K D E F A B eC"
    },
    B: {
      "1": "4 5 6 7 8 9 Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "2": "C L M G N O P",
      "1028": "W X",
      "1540": "Q H R S T U V"
    },
    C: {
      "1": "4 5 6 7 8 9 wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC",
      "164": "0 1 2 3 GC J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB hC iC",
      "1540": "ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB"
    },
    D: {
      "1": "4 5 6 7 8 9 Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "292": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB",
      "1028": "W X",
      "1540": "zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V"
    },
    E: {
      "1": "G qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "292": "J IB K D E F A B C jC MC kC lC mC nC NC AC",
      "1540": "L M BC oC",
      "3076": "pC"
    },
    F: {
      "1": "6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "292": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB",
      "1028": "4B 5B",
      "1540": "oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B"
    },
    G: {
      "1": "ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "292": "E MC zC dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD",
      "1540": "BD CD DD ED FD GD",
      "3076": "HD"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "292": "GC J MD ND OD PD dC QD RD"
    },
    J: {
      "292": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z bD DC EC FC cD",
      "292": "J SD TD UD VD WD",
      "1540": "NC XD YD ZD aD"
    },
    Q: {
      "1540": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "gD",
      "1540": "fD"
    }
  },
  B: 5,
  C: "CSS Logical Properties",
  D: true
};