<div class="pointer-events-none relative flex h-full w-full flex-col overflow-visible">
	@if (minimalAppRole(userRoles.ADMIN)) {
		<yuno-admin-navbar-buttons-container>
			<button yuno-admin-button color="secondary" (click)="openEditor(['edit'])">Edit</button>
		</yuno-admin-navbar-buttons-container>
	}
	<router-outlet />
	<div class="flex h-full flex-row">
		<div
			class="pointer-events-auto relative h-full w-full overflow-hidden bg-gray-500 p-4 shadow-sm">
			<yuno-admin-geophotos-map-viewer class="absolute left-0 top-0 z-0 h-full w-full" />
		</div>
		<yuno-admin-side-options-menu class="pointer-events-auto" [opened]="true" />
	</div>
</div>
