import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { YunoButtonModule } from '@yuno/project-atlas/ui';

import { ToolBarComponent } from './tool-bar/tool-bar.component';


@NgModule({
	imports: [CommonModule, YunoButtonModule, AngularSvgLoaderModule, ToolBarComponent],
	providers: [],
	exports: [ToolBarComponent]
})
export class DrawMapModule {}
