import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { PanoramasFacade } from '@yuno/admin/features/panoramas/data-access';
import { PanoramaUrlGeneratorComponent } from '@yuno/admin/features/panoramas/feature/url-generator/panorama-url-generator.component';
import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { ApiService } from '@yuno/angular/api';
import { Panorama } from '@yuno/api/interface';

import { PanoramasRoutingModule } from './panoramas-routing.module';
import { PanoramaService } from './panoramas.service';


@Component({
	standalone: true,
	imports: [YunoAdminTableComponent, PanoramasRoutingModule, YunoAdminButtonsModule, AsyncPipe],
	providers: [PanoramaService, ApiService],
	selector: 'yuno-admin-panoramas',
	templateUrl: './panoramas.component.html',
	styleUrls: ['./panoramas.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoramasComponent extends AppDataComponent implements OnInit {
	private readonly panoramaFacade = inject(PanoramasFacade);
	private readonly service = inject(PanoramaService);
	private overlay = inject(Overlay);
	overlayRef?: OverlayRef;

	data$ = combineLatest({
		panoramas: this.panoramaFacade.panoramas$.pipe(
			map(data => {
				const list: { [key: string]: unknown }[] = data.map(pano => ({
					_id: pano?._id || '',
					id: pano?.id || '',
					display: pano.map?.display || '',
					iconType: pano.map?.icon || '',
					public: pano.public,
					selected: pano.selected,
					states:
						pano.textfield?.states && pano.textfield?.states?.length >= 1 ? 'yes' : ''
				}));
				return list as { [key: string]: unknown }[];
			})
		),
		selected: this.panoramaFacade.selectedPanorama$
	});

	ngOnInit(): void {
		this.panoramaFacade.get();
	}

	onCreate() {
		this.panoramaFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onSelect(row: Partial<Panorama>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Panorama>): void {
		this.panoramaFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Panorama>): void {
		this.panoramaFacade.delete(row._id as string);
	}

	async downloadGeojson(appId: string): Promise<void> {
		await this.service.downloadGeojson(appId);
	}

	uploadGeojson(appId: string) {
		this.service.uploadGeojson(appId);
	}

	openPanoUrlGenerator(row: Partial<Panorama>): void {
		if (!this.app?.url?.[0] || !this.client?.url?.[0]) {
			return;
		}

		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			panelClass: 'pointer-events-none'
		});

		const portal = new ComponentPortal(PanoramaUrlGeneratorComponent);
		// portal.component.panoId = row._id as string;
		const ref = this.overlayRef.attach(portal);
		ref.instance.appUrl = this.app.url[0];
		ref.instance.panoId = row.id as string;
		ref.instance.clientUrl = this.client.url[0];

		ref.instance.closing.subscribe(() => this.overlayRef?.detach());
	}
}
