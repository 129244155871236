import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SideOptionsMenuService {
	/* eslint-disable @typescript-eslint/no-explicit-any */
	private sideOptionsTemplate = new BehaviorSubject<TemplateRef<any> | null>(null);

	sideOptionsTemplate$ = this.sideOptionsTemplate.asObservable();

	updateTemplate(data: any) {
		this.sideOptionsTemplate.next(data);
	}
}
