module.exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K D E F eC"
    },
    B: {
      "1": "4 5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "1": "4 5 6 7 8 9 UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC hC",
      "260": "0 1 2 3 N O P JB y z KB LB MB NB OB PB QB RB SB TB",
      "292": "J IB K D E F A B C L M G iC"
    },
    D: {
      "1": "4 5 6 7 8 9 KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "33": "0 1 2 3 A B C L M G N O P JB y z",
      "548": "J IB K D E F"
    },
    E: {
      "1": "PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "jC MC",
      "260": "D E F A B C L M G lC mC nC NC AC BC oC pC qC OC",
      "292": "K kC",
      "804": "J IB"
    },
    F: {
      "1": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x BC",
      "2": "F B uC vC wC xC",
      "33": "C yC",
      "164": "AC cC"
    },
    G: {
      "1": "PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "260": "E 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC",
      "292": "0C 1C",
      "804": "MC zC dC"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I QD RD",
      "33": "J PD dC",
      "548": "GC MD ND OD"
    },
    J: {
      "1": "A",
      "548": "D"
    },
    K: {
      "1": "H BC",
      "2": "A B",
      "33": "C",
      "164": "AC cC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 4,
  C: "CSS Gradients",
  D: true
};