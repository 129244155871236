import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const layoutActions = createActionGroup({
	source: 'Layout',
	events: {
		open: emptyProps(),
		close: emptyProps(),
		toggle: props<{ toggle: boolean }>(),

		optionsToggle: props<{ toggle: boolean }>(),
		optionsActive: props<{ active: boolean }>(),
		language: props<{ language: string }>(),
		mode: props<{ mode: 'dark' | 'light' }>()
	}
});
