// Based on:
// https://stackoverflow.com/questions/46141714/use-formcontrolname-for-custom-input-component-in-reactive-form/68353595#68353595
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DoCheck,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	Output,
	Self,
	inject
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NgControl,
	Validators
} from '@angular/forms';

import { NOOP_VALUE_ACCESSOR } from '../../types';

@Component({
	selector: 'yuno-forms-default-control',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class YunoFormsDefaultComponent implements DoCheck, OnInit {
	readonly cdr = inject(ChangeDetectorRef);

	@Input() label?: string;
	@Input() placeholder? = '';
	@Input() required?: boolean;

	@Output() blurred = new EventEmitter<FocusEvent>();

	@Self()
	@Optional()
	ngControl = inject(NgControl);

	constructor() {
		if (this.ngControl) {
			this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
		}
	}

	ngOnInit(): void {
		const required = this.ngControl.control?.hasValidator(Validators.required);
		if (required) {
			this.required = true;
		}
	}

	ngDoCheck(): void {
		this.markTouched();
	}

	markTouched(): void {
		if (!this.ngControl) {
			return;
		}

		if (this.ngControl.touched || this.ngControl.dirty) {
			this.cdr.markForCheck();
		}
	}

	onBlurred(event: FocusEvent): void {
		this.blurred.emit(event);
	}

	hasFormControl(): boolean {
		return !!this.ngControl;
	}

	getFormControl(): FormControl {
		return this.ngControl?.control as FormControl;
	}

	getFormGroup(): FormGroup {
		return this.ngControl?.control?.parent as FormGroup;
	}
}

@Component({
	selector: 'yuno-forms-default-template-control',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class YunoFormsDefaultTemplateComponent implements ControlValueAccessor {
	@Input() label?: string;
	@Input() placeholder? = '';
	@Input() required: boolean = false;

	_pattern: string | RegExp;
	@Input() set pattern(input: string | RegExp) {
		this._pattern = input;
	}

	get pattern(): string | RegExp {
		return this._pattern;
	}

	@Input() set disabled(bool: boolean) {
		this._isDisabled = bool;
	}

	value: string | number | Date = '';

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_onTouch = (_: unknown) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_onChange = (_: unknown) => {};

	private _isDisabled = false;

	public get isDisabled(): boolean {
		return this._isDisabled;
	}

	valueChange(value: Date | string | number): void {
		this.value = value;
		this._onChange(this.value);
	}

	writeValue(value: Date | string | number): void {
		this.value = value;
	}

	registerOnChange(fn: (_: unknown) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: (_: unknown) => void): void {
		this._onTouch = fn;
	}
}
