import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { Layer } from '@yuno/api/interface';

import { LayersFacade } from '../../data-access';
import { LayersRoutingModule } from './layers-routing.module';


@Component({
	standalone: true,
	imports: [YunoAdminTableComponent, LayersRoutingModule, YunoAdminButtonsModule, AsyncPipe],
	selector: 'yuno-admin-layers',
	templateUrl: './layers.component.html',
	styleUrls: ['./layers.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersComponent implements OnInit {
	private readonly layerFacade = inject(LayersFacade);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);

	private activeLayerId: string | null;

	data$ = combineLatest({
		layers: this.layerFacade.layers$.pipe(map(data => data as { [key: string]: unknown }[]))
	});

	ngOnInit(): void {
		this.layerFacade.get();
	}

	onSelect(row: Partial<Layer>) {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Layer>) {
		this.layerFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Layer>) {
		this.layerFacade.delete(row._id as string);
	}

	onCreate() {
		this.layerFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}
}
