import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
	ImageSourceSpecification,
	RasterDEMSourceSpecification,
	RasterSourceSpecification,
	VectorSourceSpecification
} from 'maplibre-gl';
import { BehaviorSubject, combineLatest, debounceTime, startWith, take, tap } from 'rxjs';

import { SourcesCustomFacade, SourcesFacade } from '@yuno/admin/features/sources/data-access';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminCodeEditorModule,
	YunoAdminSaveButtonComponent,
	YunoAdminTableComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { Layer, isCustomSource, isSource } from '@yuno/api/interface';
import { LoadersModule } from '@yuno/project-atlas/ui';

import { LayersFacade } from '../../data-access';
import { MapViewerComponent } from './layer-editor-map/map-viewer.component';
import { LayerEditorService } from './layer-editor.service';

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		UserRightsMessageComponent,
		MapViewerComponent,
		YunoAdminButtonComponent,
		YunoEditContainerModule,
		YunoAdminCodeEditorModule,
		YunoFormsModule,
		YunoAdminTableComponent,
		AsyncPipe,
		LoadersModule,
		YunoAdminSaveButtonComponent,
		YunoAdminCloseButtonComponent,
		EditContainerContentDirective
	],
	providers: [LayerEditorService],
	selector: 'yuno-admin-layer-editor',
	templateUrl: './layer-editor.component.html',
	styleUrls: ['./layer-editor.component.scss'],

	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerEditorComponent extends AppDataComponent implements OnInit {
	@HostBinding('class') private readonly className = 'editor-component-container';

	private readonly layerFacade = inject(LayersFacade);
	private readonly sourceFacade = inject(SourcesFacade);
	private readonly customSourceFacade = inject(SourcesCustomFacade);
	private readonly destroyRef = inject(DestroyRef);

	readonly service = inject(LayerEditorService);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private jsonData = new BehaviorSubject<{ json: any; key?: 'filter' | 'layout' | 'paint' }>({
		json: {},
		key: undefined
	});

	originalData: Partial<Layer>;
	url: string;
	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	data$ = combineLatest({
		layer: this.layerFacade.selectedLayer$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as Layer;

					this.filterControl = new UntypedFormControl(
						JSON.stringify(this.originalData?.filter || [])
					);
					this.layoutControl = new UntypedFormControl(
						JSON.stringify(this.originalData?.layout || {})
					);
					this.paintControl = new UntypedFormControl(
						JSON.stringify(this.originalData?.paint || {})
					);

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);
					if (this.originalData?.filter) {
						this.service.updateFormControls('filter', this.originalData.filter);
					}
					if (this.originalData?.layout) {
						this.service.updateFormControls('layout', this.originalData.layout);
					}
					if (this.originalData?.paint) {
						this.service.updateFormControls('paint', this.originalData.paint);
					}
				}

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		),
		source: this.layerFacade.selectedSource$.pipe(
			startWith(undefined),
			tap(data => {
				if (data && isSource(data)) {
					const sourceData = data?.sourceData as
						| VectorSourceSpecification
						| RasterSourceSpecification
						| RasterDEMSourceSpecification
						| ImageSourceSpecification;

					if (sourceData) {
						if (sourceData?.url) {
							this.sourceFacade.getSourceLayers(sourceData.url);
						} else {
							this.sourceFacade.clearSourceLayers();
						}
					}
				}

				if (data && isCustomSource(data)) {
					if (data.source?.options?.url) {
						this.sourceFacade.getSourceLayers(data.source?.options?.url);
					} else {
						this.sourceFacade.clearSourceLayers();
					}
				}
			})
		),
		sources: combineLatest(
			this.sourceFacade.sources$,
			this.customSourceFacade.customSources$
		).pipe(
			debounceTime(100),
			tap(([sources, customSources]) => {
				if (
					(sources && sources.length >= 1) ||
					(customSources && customSources.length >= 1)
				) {
					const combined = [
						...sources.map(object => object.id),
						...customSources.map(object => object.source?.name)
					];
					this.service.sources = combined as string[];
				}
			})
		),
		sourceLayers: this.sourceFacade.sourceLayers$.pipe(
			tap(data => {
				this.service.sourceLayers = data as string[];
			})
		),
		jsons: this.jsonData.asObservable().pipe(
			debounceTime(500),
			tap(data => {
				if (data.key) {
					this.service.updateFormControls(data.key, data.json);
				}
			})
		)
	});

	filterControl: UntypedFormControl;
	layoutControl: UntypedFormControl;
	paintControl: UntypedFormControl;

	ngOnInit(): void {
		this.sourceFacade.get();
		this.customSourceFacade.get();
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			this.layerFacade.select(data.get('id'));
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.layerFacade.updateSelect(
						this.service.form.getRawValue() as Partial<Layer>
					);
				},
				complete: () => {
					this.layerFacade.clearSelect();
				}
			});

		this.service.source.valueChanges
			.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: src => {
					this.layerFacade.selectSource(src);
				},
				complete: () => {
					this.layerFacade.clearSource();
				}
			});
	}

	onSave() {
		this.layerFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router);
	}

	updateControlForm(event: string, key: 'filter' | 'layout' | 'paint'): void {
		const json = JSON.parse(event);
		this.jsonData.next({ json, key });
	}
}
