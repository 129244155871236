<router-outlet></router-outlet>

@if (data$ | async; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="false"
		[pagination]="false"
		[pageOptions]="{
			pageSize: 25,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="getData(data.pages)"
		[buttons]="tableButtons"
		[columns]="[
			{ key: 'name', label: 'page' },
			{ key: 'id', label: 'link' }
		]"
		(clicked)="onEdit($event)">
		<button yuno-admin-add-button (click)="onCreate()">New Page</button>
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onEdit(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
