import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Textfield } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class TextfieldLibraryService {
	close$ = new ReplaySubject<void>();
	editor$ = new ReplaySubject<string | undefined>();
	key$ = new ReplaySubject<string | undefined>();
	selected$ = new ReplaySubject<Textfield | undefined>();
	filter$ = new ReplaySubject<string>();

	onClose() {
		this.close$.next();
	}

	editTextfield(id: string) {
		this.editor$.next(id);
	}

	selectTextfield(text: Textfield) {
		this.selected$.next(text);
	}

	filter(filter: string) {
		this.filter$.next(filter);
	}
}
