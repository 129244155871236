import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, take, tap } from 'rxjs';

import { EventsFormsComponent } from '@yuno/admin/features/events/feature/events.forms.component';
import { TextfieldDynamicComponent } from '@yuno/admin/features/textfield-pages/feature/textfields/editor/textfield-dynamic/textfield-dynamic.component';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { TextfieldInjectorComponent } from '@yuno/angular/textfield';
import { YunoUserRolesEnum } from '@yuno/api/interface';

import { AddItemsComponent, BrowseItemsComponent } from '../../../content';
import { EditorDynamicComponent } from '../editor-dynamic/editor-dynamic.component';
import { TextfieldEditorComponentService } from '../textfield-editor-component.service';

@Component({
	selector: 'yuno-admin-textfield-editor',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DragDropModule,
		EditorDynamicComponent,
		TextfieldDynamicComponent,
		YunoEditContainerModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		UserRightsMessageComponent,
		TextfieldInjectorComponent,
		EditContainerContentDirective,
		AddItemsComponent,
		BrowseItemsComponent,
		EventsFormsComponent
	],
	providers: [TextfieldEditorComponentService],
	templateUrl: './textfield-editor.component.html',
	styleUrls: ['./textfield-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly cdr = inject(ChangeDetectorRef);
	protected readonly redirectTo = redirectTo;
	readonly service = inject(TextfieldEditorComponentService);

	@Input() customId: string | undefined;

	data$ = combineLatest({
		textfield: this.service.textfield$.pipe(
			tap(data => {
				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
					this.service.form.get('_id')?.patchValue(data._id);
				}

				if (data?._id && this.router.url.includes('new')) {
					redirectTo(this.route, this.router, ['textfield', `${data._id}`]);
					this.service.form.get('_id')?.patchValue(data._id);
				}
			})
		),
		toggle: this.service.toggle$,
		templates: this.service.templates$
	});

	ngOnInit(): void {
		this.service.textfieldEditorService.createFormGroup();
		this.service.onChanges();

		if (!this.minimalAppRole(YunoUserRolesEnum.EDITOR)) {
			this.service.form.disable();
		}

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			let id: string | null = null;
			if (!this.customId) {
				id = data.get('id');
			} else if (this.customId !== 'new') {
				id = this.customId;
			}
			this.service.onSelect(id);
		});
	}

	detectChanges(ms: number) {
		setTimeout(() => {
			this.cdr.detectChanges();
		}, ms);
	}

	ngOnDestroy() {
		this.service.onDestroy();
	}
}
