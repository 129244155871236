import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Pages } from '@yuno/api/interface';

import { PagesFacade } from '../../../data-access';
import { PagesRoutingModule } from './pages-routing.module';


@Component({
	standalone: true,
	imports: [PagesRoutingModule, YunoAdminTableComponent, YunoAdminButtonsModule, AsyncPipe],
	selector: 'yuno-admin-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesComponent extends AppDataComponent implements OnInit {
	private pagesFacade = inject(PagesFacade);

	data$ = combineLatest({
		pages: this.pagesFacade.pages$,
		selected: this.pagesFacade.selectedPages$
	});

	ngOnInit(): void {
		this.pagesFacade.get();
	}

	getData(data: Partial<Pages>[] | null | undefined) {
		return data as { [key: string]: unknown }[];
	}

	onCreate() {
		this.pagesFacade.clearSelect();

		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onEdit(row: Partial<Pages>): void {
		this.router.navigate(['edit', row._id as string], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Pages>): void {
		this.pagesFacade.duplicate(row._id as string);
	}

	onDelete(row: Partial<Pages>): void {
		this.pagesFacade.delete(row._id as string);
	}
}
