import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { combineLatest, debounceTime, delay, startWith, take, tap } from 'rxjs';
import slugify from 'slugify';

import {
	AnnotationComponent,
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminCodeEditorModule,
	YunoAdminTableComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { YunoFormsModule } from '@yuno/angular/forms';
import { LanguageAll, TilesetCustom } from '@yuno/api/interface';

import { TilesetsCustomFacade } from '../../data-access';
import { CustomTilesetService, JsonData } from './custom-tileset.service';

slugify.extend({ '-': '_' });

@Component({
	selector: 'yuno-admin-tileset-custom-editor',
	standalone: true,
	imports: [
		CommonModule,
		JsonFormsModule,
		YunoEditContainerModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		YunoAdminCodeEditorModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		UserRightsMessageComponent,
		YunoFormsModule,
		AnnotationComponent,
		EditContainerContentDirective
	],
	templateUrl: './tileset-custom-editor.component.html',
	styleUrls: ['./tileset-custom-editor.component.scss'],
	providers: [CustomTilesetService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TilesetCustomEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly facade = inject(TilesetsCustomFacade);
	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(CustomTilesetService);

	private delete: JsonData;
	protected readonly LanguageAll = LanguageAll;

	originalData: TilesetCustom;

	data$ = combineLatest({
		customTileset: this.facade.selected$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as TilesetCustom;

					this.service.addJsons(data?.geojson);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(data as any);

					if (this.originalData?.id.length >= 1) {
						this.service.id?.disable();
					}

					if (
						this.originalData?.tippecanoe?.preset === 'custom' &&
						this.originalData?.tippecanoe?.command
					) {
						this.service.command.patchValue(this.originalData.tippecanoe.command);
					} else {
						this.service.changePreset(this.originalData?.tippecanoe?.preset);
					}
				}
			})
		),
		progress: this.service.progress$.pipe(startWith(null))
	});

	progressComplete$ = this.service.progressComplete$.pipe(
		startWith(false),
		delay(3000),
		tap(bool => {
			if (bool) {
				this.service._progress.next(null);
				this.service._progressComplete.next(false);
			}
		})
	);

	ngOnInit() {
		this.service.createFormGroup();
		this.onChanges();

		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.facade.select(id);
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.facade.updateSelect(this.service.form.getRawValue() as Partial<TilesetCustom>);
			});
	}

	onSave() {
		this.facade.save();
	}

	onClose() {
		redirectTo(this.route, this.router);
		this.facade.clearSelect();
	}

	onFile() {
		const inputFile = document.getElementById('inputfile') as HTMLInputElement;
		inputFile.click();
	}

	updateEditor(val: string | null) {
		val && this.service.changePreset(val);
	}

	onRemoveJson(name: string, index: number, clientId: string, appId: string, id: string) {
		this.delete = new JsonData();
		this.delete.geojson = name;
		this.delete.index = index;
		this.service.openRemoveDialog(clientId, appId, id, this.delete);
	}

	ngOnDestroy() {
		this.facade.clearSelect();
	}
}
