@if (form) {
	<form [formGroup]="form">
		<yuno-forms-title>Datasets</yuno-forms-title>
		<yuno-forms-span class="my-2">
			Add datasets to load automatically when navigating to this theme. If you want to
			dynamically load/remove datasets, e.g. via a dropdown, use Events instead.
		</yuno-forms-span>
		<div formArrayName="datasets" class="my-2 grid grid-cols-1 gap-1">
			@for (alias of service.datasets.controls; track alias; let i = $index) {
				<yuno-admin-item-list [formGroupName]="i">
					<yuno-forms-select
						title
						formControlName="_id"
						class="flex-1"
						placeholder="Choose Dataset..."
						[selectValues]="service.datasetValues"
						[display]="service.datasetDisplay"></yuno-forms-select>
					<button yuno-admin-delete-admin-button (click)="removeItemAt(i)"></button>
				</yuno-admin-item-list>
			}
		</div>
		<button yuno-admin-add-button class="self-end" (click)="addItem()">Add Item</button>
	</form>
}
