import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import { catchError, firstValueFrom } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	LanguageType,
	Layer,
	Legend,
	LegendOptions,
	LegendOptionsDefault,
	LegendOptionsGradiant
} from '@yuno/api/interface';

export interface LegendForm {
	id: FormControl<string>;
	_id?: FormControl<string>;
	// appId: FormControl<string>;
	title?: FormGroup<LanguageFormType>;
	size?: FormControl<number>;
	openOnStartup: FormControl<boolean>;
	content: FormArray<FormGroup<ContentForm>>;
}

export interface ContentForm {
	public: FormControl<boolean>;
	iconHeight?: FormControl<number>;
	title?: FormGroup<LanguageFormType>;
	bold: FormControl<boolean>;
	class?: FormControl<string>;
	color?: FormControl<string>;
	svg?: FormControl<string>;
	border?: FormControl<string>;
	borderColor?: FormControl<string>;
	image?: FormControl<string>;
	gradiant?: FormArray<FormGroup<legendGradiant>>;
	gradiantHeight?: FormControl<number>;
	activeLayers?: FormArray<FormGroup<legendLayers>>;
	list?: FormArray<FormGroup<legendList>>;
}

export type legendList = Omit<ContentForm, 'list'>;
type legendLayers = { _id: FormControl<string> };
type legendGradiant = {
	color?: FormControl<string>;
	offset?: FormControl<number>;
	title?: FormControl<string>;
};

export type tabLegendContent = 'Icon' | 'SVG' | 'Image' | 'List' | 'Gradient';

@Injectable()
export class LegendEditorService {
	private http = inject(HttpClient);
	private environment = inject(ENVIRONMENT);

	editList = false;
	editContent = false;
	editContentData: FormGroup<ContentForm>;

	form: FormGroup<LegendForm>;
	language: LanguageType;

	layerValues: string[] = [];
	layerDisplay: string[] = [];

	iconValues: string[] = [];
	iconDisplay: string[] = [];

	contentIndex = 0;
	listIndex = 0;
	tabValue: tabLegendContent = 'SVG';
	tabListValue: tabLegendContent = 'SVG';

	sizeSelectorValues = [24, 40, 64, 80, 104];
	sizeSelectorDisplay = ['default (24 px)', '40 px', '64 px', '80 px', '104 px'];

	get content(): FormArray {
		return (
			(this.form?.get('content') as FormArray) || new FormArray<FormGroup<ContentForm>>([])
		);
	}

	get list(): FormArray {
		return (
			(this.content.controls[this.contentIndex].get('list') as FormArray) ||
			new FormArray<FormGroup<legendList>>([])
		);
	}

	get activeContent(): FormGroup {
		return this.content.controls[this.contentIndex] as FormGroup<ContentForm>;
	}

	get activeList(): FormGroup {
		return this.list.controls[this.listIndex] as FormGroup<legendList>;
	}

	get activeLayers(): FormArray {
		return (
			(this.activeContent.get('activeLayers') as FormArray) ||
			new FormArray<FormGroup<legendLayers>>([])
		);
	}

	get activeListLayers(): FormArray {
		return (
			(this.activeList.get('activeLayers') as FormArray) ||
			new FormArray<FormGroup<legendLayers>>([])
		);
	}

	get gradiants(): FormArray {
		return (
			(this.activeList.get('gradiant') as FormArray) ||
			new FormArray<FormGroup<legendLayers>>([])
		);
	}

	createFormGroup(disabled: boolean): void {
		this.form = new FormGroup<LegendForm>({
			_id: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			size: new FormControl(24, { nonNullable: true, validators: Validators.required }),
			// appId: new FormControl('', { nonNullable: true }),
			title: newLanguageFormGroup(),
			openOnStartup: new FormControl(false, { nonNullable: true }),
			content: new FormArray<FormGroup<ContentForm>>([])
		});

		disabled && this.form.disable();
	}

	removeContent(i: number): void {
		this.content.removeAt(i);
	}

	addContent(content?: LegendOptions): void {
		const newContent = new FormGroup<ContentForm>({
			public: new FormControl(true, { nonNullable: true }),
			iconHeight: new FormControl(16, { nonNullable: true }),
			title: newLanguageFormGroup(),
			bold: new FormControl(false, { nonNullable: true }),
			class: new FormControl('', { nonNullable: true }),
			color: new FormControl('', { nonNullable: true }),
			svg: new FormControl('', { nonNullable: true }),
			border: new FormControl('', { nonNullable: true }),
			borderColor: new FormControl('', { nonNullable: true }),
			image: new FormControl('', { nonNullable: true }),
			gradiant: new FormArray<FormGroup<legendGradiant>>([]),
			gradiantHeight: new FormControl(0, { nonNullable: true }),
			activeLayers: new FormArray<FormGroup<legendLayers>>(
				this.createActiveLayerFormData(content?.activeLayers)
			),
			list: new FormArray<FormGroup<legendList>>(this.createListFormData(content?.list))
		});
		if (content) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newContent.patchValue(content as any);
		}
		this.content.push(newContent);
	}

	addContents(content: Legend['content']): void {
		if (!content) {
			return;
		}
		const count = content.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addContent(content[i]);
			}
		}
	}

	createActiveLayerFormData(layers?: Layer[]): FormGroup<legendLayers>[] {
		if (!layers) {
			return [];
		}
		const arr: FormGroup<legendLayers>[] = [];

		for (const layer of layers) {
			const newLayer = new FormGroup<legendLayers>({
				_id: new FormControl('', { nonNullable: true })
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newLayer.patchValue(layer as any);
			arr.push(newLayer);
		}
		return arr;
	}

	removeLayer(activeLayers: FormArray, i: number): void {
		activeLayers.removeAt(i);
	}

	addLayer(activeLayers: FormArray): void {
		activeLayers.push(
			new FormGroup<legendLayers>({
				_id: new FormControl('', { nonNullable: true })
			})
		);
	}

	createListFormData(list?: LegendOptionsDefault[]): FormGroup<legendList>[] {
		if (!list) {
			return [];
		}
		const arr: FormGroup<legendList>[] = [];

		for (const item of list) {
			const newList = new FormGroup<legendList>({
				public: new FormControl(true, { nonNullable: true }),
				iconHeight: new FormControl(16, { nonNullable: true }),
				title: newLanguageFormGroup(),
				bold: new FormControl(false, { nonNullable: true }),
				class: new FormControl('', { nonNullable: true }),
				color: new FormControl('', { nonNullable: true }),
				svg: new FormControl('', { nonNullable: true }),
				border: new FormControl('', { nonNullable: true }),
				borderColor: new FormControl('', { nonNullable: true }),
				image: new FormControl('', { nonNullable: true }),
				gradiant: new FormArray<FormGroup<legendGradiant>>([]),
				gradiantHeight: new FormControl(0, { nonNullable: true }),
				activeLayers: new FormArray<FormGroup<legendLayers>>(
					this.createActiveLayerFormData(item.activeLayers)
				)
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newList.patchValue(item as any);
			arr.push(newList);
		}
		return arr;
	}

	removeList(list: FormArray, i: number): void {
		list.removeAt(i);
	}

	addList(list: FormArray): void {
		list.push(
			new FormGroup<legendList>({
				public: new FormControl(true, { nonNullable: true }),
				iconHeight: new FormControl(16, { nonNullable: true }),
				title: newLanguageFormGroup(),
				bold: new FormControl(false, { nonNullable: true }),
				class: new FormControl('', { nonNullable: true }),
				color: new FormControl('', { nonNullable: true }),
				svg: new FormControl('', { nonNullable: true }),
				border: new FormControl('', { nonNullable: true }),
				borderColor: new FormControl('', { nonNullable: true }),
				image: new FormControl('', { nonNullable: true }),
				gradiant: new FormArray<FormGroup<legendGradiant>>([]),
				gradiantHeight: new FormControl(0, { nonNullable: true }),
				activeLayers: new FormArray<FormGroup<legendLayers>>([])
			})
		);
	}

	createGradiantFormData(gradiant?: LegendOptionsGradiant[]): FormGroup<legendGradiant>[] {
		if (!gradiant) {
			return [];
		}
		const arr: FormGroup<legendGradiant>[] = [];

		for (const data of gradiant) {
			const newGradiant = new FormGroup<legendGradiant>({
				color: new FormControl('', { nonNullable: true }),
				offset: new FormControl(0, { nonNullable: true }),
				title: new FormControl('', { nonNullable: true })
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newGradiant.patchValue(data as any);
			arr.push(newGradiant);
		}
		return arr;
	}

	removeGradiant(gradiant: FormArray, i: number): void {
		gradiant.removeAt(i);
	}

	addGradiant(gradiant: FormArray): void {
		if (gradiant.length === 0) {
			gradiant.push(
				new FormGroup<legendGradiant>({
					color: new FormControl('#206DFF', { nonNullable: true }),
					offset: new FormControl(100, { nonNullable: true }),
					title: new FormControl('100%', { nonNullable: true })
				})
			);
			gradiant.push(
				new FormGroup<legendGradiant>({
					color: new FormControl('#2121E5', { nonNullable: true }),
					offset: new FormControl(0, { nonNullable: true }),
					title: new FormControl('0%', { nonNullable: true })
				})
			);
		} else {
			gradiant.push(
				new FormGroup<legendGradiant>({
					color: new FormControl('#fff', { nonNullable: true }),
					offset: new FormControl(0, { nonNullable: true }),
					title: new FormControl('', { nonNullable: true })
				})
			);
		}
	}

	toHttpParams(params: Params) {
		return Object.getOwnPropertyNames(params).reduce(
			(p, key) => p.set(key, params[key]),
			new HttpParams()
		);
	}

	async get<T>(requestUrl: string, params?: Params) {
		const requestOptions = {
			params: this.toHttpParams({ ...params }),
			headers: new HttpHeaders().set('Content-Type', 'application/json')
		};

		return await firstValueFrom(
			this.http.get<T>(`${this.environment['yuno-api']}/${requestUrl}`, requestOptions).pipe(
				catchError((err: HttpErrorResponse) => {
					throw err;
				})
			)
		);
	}

	getStartTab(form: FormGroup): tabLegendContent {
		const { list, class: className, image, gradiant } = form.value;

		if (list?.length >= 1) {
			return 'List';
		}

		if (className) {
			return 'Icon';
		}

		if (image) {
			return 'Image';
		}

		if (gradiant?.length >= 1) {
			return 'Gradient';
		}

		return 'SVG';
	}
}
