import { gql } from 'apollo-angular';

import { MapUiButtons } from '@yuno/api/interface';

export const SAVE_MAPBUTTON = gql`
	mutation saveMapButton($mapUiButton: MapUiButtonsInput!, $appId: String!) {
		saveMapUiButton(mapUiButton: $mapUiButton, appId: $appId) {
			_id
			id
			icon
			desktop
			mobile
			events {
				type
				options
			}
		}
	}
`;

export type SaveMapButtonsQuery = {
	saveMapUiButton: Partial<MapUiButtons>[];
};
