import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoDirective, TranslocoModule } from '@ngneat/transloco';

import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminNavbarButtonsComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LanguageAll, LegendOptions } from '@yuno/api/interface';
import { LegendComponent } from '@yuno/project-atlas/ui';

import { LegendActiveLayerEditorComponent } from '../legend-active-layer-editor/legend-active-layer-editor.component';
import { ContentForm, LegendEditorService } from '../legend-editor.service';
import { LegendGradientEditorComponent } from '../legend-gradient-editor/legend-gradient-editor.component';
import { LegendIconEditorComponent } from '../legend-icon-editor/legend-icon-editor.component';
import { LegendImageEditorComponent } from '../legend-image-editor/legend-image-editor.component';
import { LegendListEditorComponent } from '../legend-list-editor/legend-list-editor.component';
import { LegendSvgEditorComponent } from '../legend-svg-editor/legend-svg-editor.component';


@Component({
	selector: 'yuno-admin-legend-main-container',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		TranslocoModule,
		AngularPipesModule,
		ReactiveFormsModule,
		DragDropModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		YunoFormsModule,
		AngularSvgLoaderModule,
		LegendComponent,
		LegendIconEditorComponent,
		LegendImageEditorComponent,
		LegendListEditorComponent,
		LegendSvgEditorComponent,
		LegendGradientEditorComponent,
		LegendActiveLayerEditorComponent,
		YunoAdminNavbarButtonsComponent,
		EditContainerContentDirective,
		TranslocoDirective
	],
	template: `
		<h2>Legend</h2>
		@if (service.form) {
			<form [formGroup]="service.form" class="flex flex-col gap-4 xl:grid-cols-1">
				<yuno-forms-text formControlName="id" label="Legend name" />
				<section formGroupName="title">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								[language]="lang"
								[formControlName]="lang"
								label="Title text (optional)"></yuno-forms-text>
						}
					}
				</section>

				<yuno-forms-select
					formControlName="size"
					[isNumber]="true"
					[selectValuesNumber]="service.sizeSelectorValues"
					[display]="service.sizeSelectorDisplay"
					label="Size" />

				<yuno-forms-toggle
					labelPos="side"
					formControlName="openOnStartup"
					label="Open on Startup" />
				<div
					cdkDropList
					[cdkDropListData]="service.content"
					(cdkDropListDropped)="drop($event)"
					formArrayName="content">
					<div class="mb-1 mt-6">
						<span class="text-sm font-semibold">Content</span>
					</div>
					@for (alias of service.content.controls; track alias; let i = $index) {
						<div
							cdkDrag
							[formGroupName]="i"
							class="mb-2 flex h-15 w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-yuno-blue-gray-200 p-2">
							<div cdkDragHandle class="hover:cursor-move">
								<svg
									viewBox="0 0 16 24"
									class="mx-2 h-6 w-4 text-gray-400 group-hover:text-yuno-blue-text"
									fill="currentColor">
									<use href="#drag-icon" />
								</svg>
							</div>
							<!-- SVG -->
							@if (!alias.value.image && alias.value.svg) {
								<yuno-svg
									class="mt-1 h-4 w-6"
									[src]="alias.value.svg"
									[fill]="alias.value.color"
									[parentScale]="true"></yuno-svg>
							}
							<span
								[innerHTML]="
									alias.value.title | languageSelect: language | safeHtml
								"></span>
							<div class="flex-1"></div>
							<div class="flex justify-end gap-2">
								<button
									yuno-admin-duplicate-button
									(click)="addContent(alias.value)"></button>
								<button yuno-admin-edit-button (click)="editContentAt(i)"></button>
								<button
									yuno-admin-delete-button
									(click)="removeContentAt(i)"></button>
							</div>
						</div>
					}
					<button yuno-admin-add-button (click)="addContent()">Legend Item</button>
				</div>
			</form>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendMainEditorContainerComponent extends AppDataComponent {
	protected readonly languages = LanguageAll;
	readonly service = inject(LegendEditorService);

	/* CDK DragList Drop Event */
	drop(event: CdkDragDrop<FormArray<FormGroup<ContentForm>>>): void {
		moveItemInFormArray(this.service.content, event.previousIndex, event.currentIndex);
	}

	addContent(content?: LegendOptions): void {
		this.service.addContent(content);
	}

	removeContentAt(i: number): void {
		this.service.removeContent(i);
	}

	editContentAt(index: number): void {
		this.service.contentIndex = index;
		if (this.service.activeContent.value.image) {
			this.service.tabValue = 'Image';
		}
		if (this.service.activeContent.value.class) {
			this.service.tabValue = 'Icon';
		}
		if (
			this.service.activeContent.value.list &&
			this.service.activeContent.value.list.length >= 1
		) {
			this.service.tabValue = 'List';
		}
		this.service.editContent = true;
	}
}
