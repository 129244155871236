import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';

import { layoutActions } from './layout.actions';
import { layoutFeature } from './layout.state';


@Injectable()
export class LayoutFacade {
	private readonly store = inject(Store);

	sidemenu$ = this.store.pipe(select(layoutFeature.selectSidemenu));
	sideoptions$ = this.store.pipe(select(layoutFeature.selectSideoptions));
	language$ = this.store.pipe(select(layoutFeature.selectLanguage));
	theme$ = this.store.pipe(select(layoutFeature.selectMode));

	$sideoptions = toSignal(this.sideoptions$);

	toggleSidemenu(bool: boolean) {
		this.store.dispatch(layoutActions.toggle({ toggle: bool }));
	}

	toggleSideoptions(bool: boolean) {
		this.store.dispatch(layoutActions.optionsToggle({ toggle: bool }));
	}

	activateSideoptions(bool: boolean) {
		this.store.dispatch(layoutActions.optionsActive({ active: bool }));
	}
}
