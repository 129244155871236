import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-predefined-blockquote',
	standalone: true,
	imports: [NgStyle],
	template: `
		<div
			[ngStyle]="{
				color: color || '#31AACC',
				'border-color': color || '#31AACC'
			}"
			class="my-4 border-l-4 pl-4 text-xl">
			<ng-content></ng-content>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedBlockQuoteEditorComponent {
	@Input() color: string = '#31AACC';
}
