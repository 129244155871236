module.exports = {
  A: {
    A: {
      "2": "K D E F eC",
      "1028": "B",
      "1316": "A"
    },
    B: {
      "1": "4 5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "1": "4 5 6 7 8 9 MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "164": "fC GC J IB K D E F A B C L M G N O P JB y z hC iC",
      "516": "0 1 2 3 KB LB"
    },
    D: {
      "1": "4 5 6 7 8 9 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "33": "0 1 2 3 z KB LB MB",
      "164": "J IB K D E F A B C L M G N O P JB y"
    },
    E: {
      "1": "F A B C L M G nC NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "33": "D E lC mC",
      "164": "J IB K jC MC kC"
    },
    F: {
      "1": "0 1 2 3 O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x BC",
      "2": "F B C uC vC wC xC AC cC yC",
      "33": "G N"
    },
    G: {
      "1": "4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "33": "E 2C 3C",
      "164": "MC zC dC 0C 1C"
    },
    H: {
      "1": "LD"
    },
    I: {
      "1": "I QD RD",
      "164": "GC J MD ND OD PD dC"
    },
    J: {
      "1": "A",
      "164": "D"
    },
    K: {
      "1": "H BC",
      "2": "A B C AC cC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "1": "B",
      "292": "A"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 4,
  C: "CSS Flexible Box Layout Module",
  D: true
};