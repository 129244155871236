import { AsyncPipe, NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationEnd, RouterLink, RouterLinkActive } from '@angular/router';
import { filter } from 'rxjs';

import { LayoutFacade } from '@yuno/admin/features/layout';
import {
	AdminSearchBarComponent,
	PillItem,
	SideOptionsButtonComponent,
	YunoAdminButtonComponent,
	YunoAdminUiSelectorButtonComponent,
	YunoAdminUiSelectorComponent,
	YunoAdminUiSelectorDropdownComponent,
	YunoAdminUiSelectorDropdownItemComponent,
	YunoAdminUiSelectorDropdownItemCounterComponent,
	YunoAdminUiSelectorItemComponent
} from '@yuno/admin/ui';
import { SideOptionsMenuService } from '@yuno/admin/ui/side-options-menu/side-options-menu.service';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsToggleComponent } from '@yuno/angular/forms/components';

import { MarkerCategoriesFacade } from '../../../../data-access';
import { MapViewerService } from '../map-viewer/map-viewer.service';

interface PillItemUrl extends PillItem {
	url: string;
	exact: boolean;
}

@Component({
	selector: 'yuno-admin-place-marker-viewer-ui-selectors',
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		RouterLink,
		YunoAdminButtonComponent,
		YunoAdminUiSelectorComponent,
		YunoAdminUiSelectorItemComponent,
		YunoAdminUiSelectorDropdownComponent,
		YunoAdminUiSelectorDropdownItemComponent,
		YunoAdminUiSelectorButtonComponent,
		AdminSearchBarComponent,
		YunoAdminUiSelectorDropdownItemCounterComponent,
		SideOptionsButtonComponent,
		RouterLinkActive,
		YunoFormsToggleComponent,
		FormsModule,
		ReactiveFormsModule
	],
	templateUrl: './ui-selectors.component.html',
	styleUrls: ['./ui-selectors.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceMarkerViewerUiSelectorsComponent
	extends AppDataComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private readonly mapService = inject(MapViewerService);
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly cdr = inject(ChangeDetectorRef);

	showAddMarker = true;

	selector: PillItemUrl[] = [
		{
			key: 'Map',
			url: 'map',
			exact: false,
			selected: true
		},
		{
			key: 'List',
			url: 'list',
			exact: false,
			selected: false
		}
	];

	form = this.mapService.sideOptionsForm;
	$filter = this.mapService.$filter;
	$allCount = toSignal(this.categoryFacade.getMarkerCount('all'));
	$publicCount = toSignal(this.categoryFacade.getMarkerCount('public'));
	$nonPublicCount = toSignal(this.categoryFacade.getMarkerCount('non-public'));

	private layoutFacade = inject(LayoutFacade);
	private sideMenuService = inject(SideOptionsMenuService);
	/* eslint-disable @typescript-eslint/no-explicit-any */
	@ViewChild('sideOptions') sideOptionsTemplate!: TemplateRef<any>;

	ngOnInit() {
		this.showAddMarker =
			!this.router.url.includes('list') && !this.router.url.includes('map/marker');
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				const url = (event as NavigationEnd).url;
				this.showAddMarker = !url.includes('list') && !url.includes('map/marker');
				this.cdr.detectChanges();
			});
	}

	ngAfterViewInit() {
		this.sideMenuService.updateTemplate(this.sideOptionsTemplate);
		this.layoutFacade.activateSideoptions(true);
		this.layoutFacade.toggleSideoptions(true);
	}

	activeDisplay(string: 'all' | 'public' | 'non-public'): string {
		if (string.length === 0) {
			return string; // return input if it's not a string or is an empty string
		}

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	filterMarkers(filter: 'all' | 'public' | 'non-public'): void {
		this.mapService.setMarkerFilter(filter);
	}

	openEditor(url: string[]): void {
		// An array of route segments to navigate to.
		let navigateTo = ['./'];

		// Get the first child route segment, if it exists.
		const child = this.route.firstChild?.snapshot.url[0].path;
		// Add the child route segment to the navigation array, if it exists.
		if (child) {
			navigateTo.push(child);
		}
		// Add the 'edit' route segment to the navigation array.
		navigateTo = [...navigateTo, ...url];

		// Clear the selected item from the facade.
		this.categoryFacade.clearMarker();

		// Navigate to the specified route segments relative to the current route.
		this.router.navigate(navigateTo, { relativeTo: this.route });
	}

	backToBounds(): void {
		this.mapService.updateBounds();
	}

	ngOnDestroy() {
		this.sideMenuService.updateTemplate(null);
		this.layoutFacade.toggleSideoptions(false);
		this.layoutFacade.activateSideoptions(false);
	}
}
