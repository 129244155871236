import { AsyncPipe, NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	InjectionToken,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Subject, combineLatest, debounceTime, startWith, takeUntil, tap } from 'rxjs';

import {
	GifPlayComponent,
	YunoAdminButtonComponent,
	YunoAdminCardComponent,
	YunoAdminNavbarButtonsComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { ApiService } from '@yuno/angular/api';
import { YunoFormsModule } from '@yuno/angular/forms';
import { DialogItem, MessageDialogComponent } from '@yuno/angular/notifications';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { TextVideoComponent } from '@yuno/angular/textfield';
import { RoutesVariantsDto } from '@yuno/api/dto';
import { Dashboard } from '@yuno/api/interface';

import { DashboardFacade } from '../../data-access';
import { UrlGeneratorComponent } from '../url-generator/url-generator.component';
import { UrlGeneratorService } from '../url-generator/url-generator.service';
import { DashboardService } from './dashboard.service';

export const MATOMO_AUTH_TOKEN = new InjectionToken<string>('MatomoAuthToken');

@Component({
	standalone: true,
	imports: [
		RouterModule,
		GifPlayComponent,
		TextVideoComponent,
		AngularPipesModule,
		YunoAdminCardComponent,
		YunoAdminButtonComponent,
		YunoAdminNavbarButtonsComponent,
		TranslocoModule,
		AsyncPipe,
		NgClass,
		ReactiveFormsModule,
		YunoFormsModule,
		UrlGeneratorComponent
	],
	providers: [DashboardService],
	selector: 'yuno-admin-dashboard',
	templateUrl: './dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardInternalViewComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly matomoAuthToken = inject(MATOMO_AUTH_TOKEN);
	private readonly dialog = inject(MatDialog);
	private readonly dashboardFacade = inject(DashboardFacade);
	private readonly api = inject(ApiService);
	readonly service = inject(DashboardService);
	readonly urlGeneratorService = inject(UrlGeneratorService);

	private destroyed$ = new Subject<boolean>();

	currentPage: 'dashboard' | 'planning' | 'deliverables' | 'tickets' = 'dashboard';
	originalFile: Partial<Dashboard>;

	welcomeLinks: { key: string; href: string }[] = [
		{
			key: 'Live',
			href: `https://${this.clientUrl}.${this.environment['yuno-main']}/${this.appUrl}/`
		},
		{
			key: 'Internal',
			href: `https://${this.clientUrl}.${this.environment['yuno-main']}/${this.appUrl}/intern/`
		}
	];

	variants: { key: string; href: string }[] = [];

	// Links
	links = [
		{
			key: 'editText',
			href: `${this.environment['yuno-cdn']}/content/cms/manuals/${this.language}/edit-text.pdf`
		},
		{
			key: 'participate',
			href: `${this.environment['yuno-cdn']}/content/cms/manuals/${this.language}/participate.pdf`
		},
		{
			key: 'placeMarkers',
			href: `${this.environment['yuno-cdn']}/content/cms/manuals/${this.language}/place-markers.pdf`
		}
	];

	get matomoLink(): string | undefined {
		const trackingId = this.route.snapshot.data['app'].trackingId;
		if (!trackingId) {
			return undefined;
		}

		return `${this.environment['yuno-matomo']}/index.php?module=Widgetize&action=iframe&containerId=VisitOverviewWithGraph&disableLink=0&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=${trackingId}&period=month&date=yesterday&disableLink=1&widget=1&token_auth=${this.matomoAuthToken}`;
	}

	data$ = combineLatest({
		routes: this.api.getObservable<RoutesVariantsDto>(`routes/variants/${this.appId}`).pipe(
			tap((data: RoutesVariantsDto) => {
				this.urlGeneratorService.$variants.set(data.variants);
				this.variants = [
					...data.variants
						.filter(val => {
							return !(val.id === 'public' || val.id === 'intern');
						})
						.map(val => {
							return {
								key: val.id,
								href: `https://${this.clientUrl}.${this.environment['yuno-main']}/${this.appUrl}/v/${val.url}`
							};
						})
				];
			})
		),
		dashboard: this.dashboardFacade.dashboard$.pipe(
			startWith(null),
			tap(dashboard => {
				if (!this.originalFile && dashboard?.public) {
					this.service.dashboardPublic.setValue(dashboard.public);
					this.originalFile = dashboard;
				}
			})
		),
		itemLoaded: this.dashboardFacade.selectedLoaded$.pipe(
			tap(selected => {
				if (Number(selected) >= 0) {
					this.router.navigate(['edit', selected], {
						relativeTo: this.route
					});
				}
			})
		)
	});

	private dialogRef: MatDialogRef<MessageDialogComponent>;

	ngOnInit(): void {
		if (this.route?.snapshot?.data['type']) {
			this.currentPage = this.route.snapshot.data['type'];
		}
		this.service.createFormGroup();
		this.dashboardFacade.getDashboardByAppId(this.currentPage);
		this.onChanges();
	}

	onChanges(): void {
		let isFirstChange = true;
		this.service.dashboardForm.valueChanges
			.pipe(takeUntil(this.destroyed$), debounceTime(500))
			.subscribe(data => {
				this.dashboardFacade.updatePublic(data.public || false);
				if (!isFirstChange) {
					this.dashboardFacade.save();
				}
				isFirstChange = false;
			});
	}

	onEdit(index: number): void {
		this.dashboardFacade.getSelected(index);
	}

	onDelete(index: number): void {
		const data: DialogItem = {
			title: 'Confirm',
			message: 'Are you sure you want to remove this Item?',
			buttons: [
				{
					key: 'delete',
					type: 'danger',
					confirm: true
				},
				{
					key: 'Cancel'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: data
		});

		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.dashboardFacade.removeDashboardItem(index);
				this.dashboardFacade.save();
			}
		});
	}

	addHelpItem(): void {
		this.dashboardFacade.addDashboardItem();
	}

	ngOnDestroy() {
		// First destroy the Subscription to the Form
		this.destroyed$.next(true);
		this.destroyed$.complete();

		// reset the raw form data
		this.service.dashboardForm.reset();
	}
}
