module.exports = {
  A: {
    A: {
      "2": "K D E F A eC",
      "548": "B"
    },
    B: {
      "1": "4 5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "516": "C L M G N O P"
    },
    C: {
      "1": "4 5 6 7 8 9 uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB K D E F hC iC",
      "676": "0 1 2 3 A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB",
      "1700": "fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB"
    },
    D: {
      "1": "4 5 6 7 8 9 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "J IB K D E F A B C L M",
      "676": "G N O P JB",
      "804": "0 1 2 3 y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B"
    },
    E: {
      "1": "TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB jC MC",
      "548": "PC CC rC DC QC RC SC",
      "676": "kC",
      "804": "K D E F A B C L M G lC mC nC NC AC BC oC pC qC OC"
    },
    F: {
      "1": "uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x BC",
      "2": "F B C uC vC wC xC AC cC yC",
      "804": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB"
    },
    G: {
      "2": "E MC zC dC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C",
      "2052": "AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC"
    },
    H: {
      "2": "LD"
    },
    I: {
      "2": "GC J I MD ND OD PD dC QD RD"
    },
    J: {
      "2": "D",
      "292": "A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2": "A",
      "548": "B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z NC XD YD ZD aD bD DC EC FC cD",
      "804": "J SD TD UD VD WD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 1,
  C: "Fullscreen API",
  D: true
};