import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Fence } from '@yuno/api/interface';

import { fencesActions } from './fences.actions';
import { fencesFeature } from './fences.state';

@Injectable()
export class FencesFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(fencesFeature.selectLoaded));
	fences$ = this.store.pipe(select(fencesFeature.selectFences));
	multi$ = this.store.pipe(select(fencesFeature.selectMulti));
	selectedFences$ = this.store.pipe(select(fencesFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(fencesFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(fencesFeature.selectStatus));

	get() {
		this.store.dispatch(fencesActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}

		this.store.dispatch(fencesActions.select({ _id }));
	}

	selectMulti(ids: string[] | null) {
		if (!ids || ids.length < 1) {
			return;
		}

		this.store.dispatch(fencesActions.multi({ ids }));
	}

	updateSelect(data: Partial<Fence>) {
		this.store.dispatch(fencesActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(fencesActions.clearSelect());
	}

	save() {
		this.store.dispatch(fencesActions.save());
	}

	saveData(fence: Partial<Fence>) {
		this.store.dispatch(fencesActions.saveData({ fence }));
	}

	duplicate(_id: string) {
		this.store.dispatch(fencesActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(fencesActions.delete({ _id }));
	}
}
