import { AsyncPipe, NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	TemplateRef,
	ViewChild,
	inject
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import {
	SideOptionsButtonComponent,
	YunoAdminButtonComponent,
	YunoAdminUiSelectorButtonComponent,
	YunoAdminUiSelectorComponent,
	YunoAdminUiSelectorDropdownComponent,
	YunoAdminUiSelectorDropdownItemComponent,
	YunoAdminUiSelectorItemComponent
} from '@yuno/admin/ui';
import { SideOptionsMenuService } from '@yuno/admin/ui/side-options-menu/side-options-menu.service';
import { AppDataComponent } from '@yuno/admin/utils';

import { GeoPhotoFacade } from '../../../../data-access';
import { GeoPhotoMapViewerService } from '../map-viewer/map-viewer.service';

@Component({
	selector: 'yuno-admin-geophoto-viewer-ui-selectors',
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		RouterLink,
		YunoAdminButtonComponent,
		YunoAdminUiSelectorComponent,
		YunoAdminUiSelectorItemComponent,
		YunoAdminUiSelectorDropdownComponent,
		YunoAdminUiSelectorDropdownItemComponent,
		YunoAdminUiSelectorButtonComponent,
		SideOptionsButtonComponent,
		RouterLinkActive
	],
	templateUrl: './ui-selectors.component.html',
	styleUrls: ['./ui-selectors.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoPhotoViewerUiSelectorsComponent extends AppDataComponent implements AfterViewInit {
	private readonly sideMenuService = inject(SideOptionsMenuService);
	@ViewChild('sideOptions') sideOptionsTemplate!: TemplateRef<HTMLElement>;

	private readonly cdr = inject(ChangeDetectorRef);
	private readonly mapService = inject(GeoPhotoMapViewerService);
	private readonly photosFacade = inject(GeoPhotoFacade);

	$filter = this.mapService.$filter;

	ngAfterViewInit(): void {
		this.sideMenuService.updateTemplate(this.sideOptionsTemplate);
	}

	activeDisplay(string: 'all' | 'public' | 'non-public'): string {
		if (string.length === 0) {
			return string; // return input if it's not a string or is an empty string
		}

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	filterMarkers(filter: 'all' | 'public' | 'non-public'): void {
		this.mapService.setMarkerFilter(filter);
		this.cdr.detectChanges();
	}

	openEditor(url: string[]): void {
		// An array of route segments to navigate to.
		let navigateTo = ['./'];

		// Get the first child route segment, if it exists.
		const child = this.route.firstChild?.snapshot.url[0].path;
		// Add the child route segment to the navigation array, if it exists.
		if (child) {
			navigateTo.push(child);
		}
		// Add the 'edit' route segment to the navigation array.
		navigateTo = [...navigateTo, ...url];

		// Clear the selected item from the facade.
		this.photosFacade.clearSelected();

		// Navigate to the specified route segments relative to the current route.
		this.router.navigate(navigateTo, { relativeTo: this.route });
	}

	backToBounds(): void {
		this.mapService.updateBounds();
	}
}
