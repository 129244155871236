import { gql } from 'apollo-angular';

import { Legend } from '@yuno/api/interface';

export const SAVE_LEGEND = gql`
	mutation getLegendById($legend: LegendInput!, $appId: String!) {
		saveLegend(legend: $legend, appId: $appId) {
			_id
			id
			title
			size
			openOnStartup
			content {
				public
				title
				iconHeight
				bold
				class
				color
				border
				borderColor
				svg
				image
				gradiant {
					color
					offset
					title
				}
				gradiantHeight
				layer {
					_id
					id
				}
				activeLayers {
					_id
					id
				}
				list {
					public
					iconHeight
					title
					bold
					class
					color
					border
					borderColor
					svg
					image
					gradiant {
						color
						offset
						title
					}
					gradiantHeight
					layer {
						_id
						id
					}
					activeLayers {
						_id
						id
					}
				}
			}
		}
	}
`;

export const DUPLICATE_LEGEND = gql`
	mutation duplicateLegend($_id: String!, $appId: String!) {
		duplicateLegend(_id: $_id, appId: $appId) {
			_id
			id
		}
	}
`;

export type SaveLegendMutation = {
	saveLegend: Legend;
};

export type DuplicateLegendMutation = {
	duplicateLegend: Partial<Legend>;
};
