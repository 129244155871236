module.exports = {
  A: {
    A: {
      "2": "K D E F eC",
      "132": "A B"
    },
    B: {
      "1": "4 5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB K D E F hC iC",
      "33": "A B C L M G"
    },
    D: {
      "1": "4 5 6 7 8 9 UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "J IB K D E F A B",
      "33": "0 1 2 3 C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB"
    },
    E: {
      "1": "PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "jC MC",
      "33": "J IB K D E kC lC mC",
      "257": "F A B C L M G nC NC AC BC oC pC qC OC"
    },
    F: {
      "1": "1 2 3 KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "33": "0 G N O P JB y z"
    },
    G: {
      "1": "PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "33": "E MC zC dC 0C 1C 2C 3C",
      "257": "4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "2": "MD ND OD",
      "33": "GC J PD dC QD RD"
    },
    J: {
      "33": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 5,
  C: "CSS3 3D Transforms",
  D: true
};