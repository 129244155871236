/* eslint-disable @typescript-eslint/no-explicit-any */
import { ControlValueAccessor, FormArray, FormControl, FormGroup } from "@angular/forms";



import { LanguagesModel } from "@yuno/api/interface";


// ChatGPT
// type AbstractControlOrGroup<T> = AbstractControl<T> | FormGroup<FormControls<T>>;
// ChatGPT
// type FormControls<T> = {
// 	[P in keyof T]: T[P] extends Record<any, any>
// 		? AbstractControlOrGroup<T[P]>
// 		: FormControl<T[P]>;
// };

// ChatGPT
// type FormControls<T> = {
// 	[P in keyof T]: FormControl | FormGroup | FormControls<T[P]>;
// };
// ChatGPT
// export type ControlsOf<T extends Record<string, any>> = {
// 	[K in keyof T]: T[K] extends Record<any, any>
// 		? FormGroup<FormControls<T[K]>>
// 		: FormControl<T[K]>;
// };

// Netanal Basal
// https://netbasal.com/typed-reactive-forms-in-angular-no-longer-a-type-dream-bf6982b0af28
// export type ControlsOf<T extends Record<string, any>> = {
// 	[K in keyof T]: T[K] extends Record<any, any> ? FormGroup<ControlsOf<T[K]>> : FormControl<T[K]>;
// };

// Netanal Basal Comments
export type ControlsOf<T extends Record<string, any>> = {
	[K in keyof T]: T[K] extends string | null | undefined | number | boolean
		? FormControl<T[K]>
		: T[K] extends Array<any>
			? FormArray<any>
			: FormGroup<ControlsOf<T[K]>>;
};

export interface LanguageFormType {
	nl?: FormControl<string>;
	'nl-BE'?: FormControl<string>;
	de?: FormControl<string>;
	en?: FormControl<string>;
	fr?: FormControl<string>;
	it?: FormControl<string>;
	es?: FormControl<string>;
	da?: FormControl<string>;
	sv?: FormControl<string>;
}

export interface LanguageFormTypeContent {
	nl?: FormGroup<{ content: FormControl<string> }>;
	'nl-BE'?: FormGroup<{ content: FormControl<string> }>;
	de?: FormGroup<{ content: FormControl<string> }>;
	en?: FormGroup<{ content: FormControl<string> }>;
	fr?: FormGroup<{ content: FormControl<string> }>;
	it?: FormGroup<{ content: FormControl<string> }>;
	es?: FormGroup<{ content: FormControl<string> }>;
	da?: FormGroup<{ content: FormControl<string> }>;
	sv?: FormGroup<{ content: FormControl<string> }>;
}

export const LanguageFormControls = {
	nl: new FormControl('', { nonNullable: false }),
	'nl-BE': new FormControl('', { nonNullable: false }),
	de: new FormControl('', { nonNullable: false }),
	en: new FormControl('', { nonNullable: false }),
	fr: new FormControl('', { nonNullable: false }),
	it: new FormControl('', { nonNullable: false }),
	es: new FormControl('', { nonNullable: false }),
	da: new FormControl('', { nonNullable: false }),
	sv: new FormControl('', { nonNullable: false })
};

export function newLanguageFormGroup(str = ''): FormGroup<LanguageFormType> {
	return new FormGroup<LanguageFormType>({
		nl: new FormControl(str, { nonNullable: true }),
		'nl-BE': new FormControl(str, { nonNullable: true }),
		de: new FormControl(str, { nonNullable: true }),
		en: new FormControl(str, { nonNullable: true }),
		fr: new FormControl(str, { nonNullable: true }),
		it: new FormControl(str, { nonNullable: true }),
		es: new FormControl(str, { nonNullable: true }),
		da: new FormControl(str, { nonNullable: true }),
		sv: new FormControl(str, { nonNullable: true })
	});
}

export function newLanguageFormGroupContent(str = ''): FormGroup<LanguageFormTypeContent> {
	return new FormGroup<LanguageFormTypeContent>({
		nl: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		'nl-BE': new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		de: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		en: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		fr: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		it: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		es: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		da: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		}),
		sv: new FormGroup({
			content: new FormControl<string>(str, { nonNullable: true })
		})
	});
}

export const defaultNewLanguageFormGroup = (str = ''): LanguagesModel => ({
	nl: str,
	'nl-BE': str,
	de: str,
	en: str,
	fr: str,
	it: str,
	es: str,
	da: str,
	sv: str
});

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
	writeValue(): void {
		//
	},
	registerOnChange(): void {
		//
	},
	registerOnTouched(): void {
		//
	}
};

/**
 * Moves an item in a FormArray to another position.
 * @param formArray FormArray instance in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which he item should be moved.
 *
 * https://stackoverflow.com/questions/56149461/draggable-formgroups-in-formarray-reactive-forms/60831464#60831464
 */

export function moveItemInFormArray(
	formArray: FormArray,
	fromIndex: number,
	toIndex: number
): void {
	const dir = toIndex > fromIndex ? 1 : -1;

	const item = formArray.at(fromIndex);
	for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
		const current = formArray.at(i + dir);
		formArray.setControl(i, current);
	}
	formArray.setControl(toIndex, item);
}

/**
 * Transfers an item from the currentFormArray to the targetFormArray at the specified indices.
 * This function inserts the item at the targetIndex in the targetFormArray, and removes it from the currentFormArray
 * at the currentIndex. This is useful when you need to transfer a FormGroup or FormControl between two FormArrays.
 *
 * @param currentFormArray The FormArray from which to transfer the item.
 * @param targetFormArray The FormArray to which to transfer the item.
 * @param currentIndex The index of the item to transfer in the currentFormArray.
 * @param targetIndex The index at which to insert the item in the targetFormArray.
 */
export function transferFormArrayItem(
	currentFormArray: FormArray,
	targetFormArray: FormArray,
	currentIndex: number,
	targetIndex: number
): void {
	const current = currentFormArray.at(currentIndex);
	targetFormArray.insert(targetIndex, current);

	currentFormArray.removeAt(currentIndex);
}
