import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export interface FenceForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	maxZoom: FormControl<number | null>;
	minZoom: FormControl<number | null>;
	bearing: FormControl<number | null>;
	pitch: FormControl<number | null>;
	style?: FormGroup<{
		color: FormControl<string | null>;
		opacity: FormControl<number | null>;
	}>;
	polygon?: FormArray<FormArray<FormControl<number>>>;
	featureId?: FormControl<string | null>;
}

@Injectable({
	providedIn: 'root'
})
export class FencesEditorService {
	form: FormGroup<FenceForm>;

	get polygon(): FormArray<FormArray<FormControl<number>>> {
		return this.form.get('polygon') as FormArray<FormArray<FormControl<number>>>;
	}

	get polygonValue(): string {
		return JSON.stringify(this.polygon.getRawValue(), null, 4) || '[]';
	}

	get color(): FormControl<string> {
		return this.form.get('style.color') as FormControl<string>;
	}

	get opacity(): FormControl<number> {
		return this.form.get('style.opacity') as FormControl<number>;
	}

	createFormGroup(): void {
		this.form = new FormGroup<FenceForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			style: new FormGroup({
				color: new FormControl<string | null>(''),
				opacity: new FormControl<number | null>(null)
			}),
			minZoom: new FormControl(null),
			maxZoom: new FormControl(null),
			pitch: new FormControl(0),
			bearing: new FormControl(0),
			polygon: new FormArray<FormArray<FormControl<number>>>([])
		});
	}

	updatePolygon(polygon: number[][]): Promise<boolean> {
		return new Promise(resolve => {
			// removes all values inside the polygon array
			this.polygon.clear();

			if (polygon.length === 0) {
				resolve(true);
				return;
			}

			for (const item of polygon) {
				const polyPart = new FormArray<FormControl<number>>([
					new FormControl<number>(item[0], { nonNullable: true }),
					new FormControl<number>(item[1], { nonNullable: true })
				]);

				this.polygon.push(polyPart);
			}

			resolve(true);
		});
	}
}
