import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { MapUiButtons } from '@yuno/api/interface';

export const mapUiButtonsActions = createActionGroup({
	source: 'MapUiButtons',
	events: {
		load: emptyProps(),
		loadSuccess: props<{
			data: Partial<MapUiButtons>[];
		}>(),
		loadFailure: props<{
			error: Error;
		}>(),
		reload: emptyProps(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{
			error: Error;
		}>(),
		select: props<{
			id: string;
		}>(),
		selectSuccess: props<{
			data: Partial<MapUiButtons>;
		}>(),
		selectFailure: props<{
			error: Error;
		}>(),
		update: props<{ data: Partial<MapUiButtons> }>(),
		updateSuccess: props<{ data: Partial<MapUiButtons> }>(),
		updateFailure: props<{ error: Error }>(),
		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),
		clearSelect: emptyProps()
	}
});
