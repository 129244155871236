module.exports = {
  A: {
    A: {
      "2": "K eC",
      "2340": "D E F A B"
    },
    B: {
      "2": "C L M G N O P",
      "1025": "4 5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I"
    },
    C: {
      "1": "4 5 6 7 8 9 c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC hC",
      "513": "vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b",
      "545": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB iC"
    },
    D: {
      "2": "0 1 2 3 J IB K D E F A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB",
      "1025": "4 5 6 7 8 9 ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC"
    },
    E: {
      "1": "A B C L M G NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB jC MC kC",
      "164": "K",
      "4644": "D E F lC mC nC"
    },
    F: {
      "2": "0 1 2 3 F B G N O P JB y z KB LB uC vC wC xC AC cC",
      "545": "C yC BC",
      "1025": "MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "6C 7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "2": "MC zC dC",
      "4260": "0C 1C",
      "4644": "E 2C 3C 4C 5C"
    },
    H: {
      "2": "LD"
    },
    I: {
      "2": "GC J MD ND OD PD dC QD RD",
      "1025": "I"
    },
    J: {
      "2": "D",
      "4260": "A"
    },
    K: {
      "2": "A B AC cC",
      "545": "C BC",
      "1025": "H"
    },
    L: {
      "1025": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "2340": "A B"
    },
    O: {
      "1025": "CC"
    },
    P: {
      "1025": "0 1 2 3 J y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD"
    },
    Q: {
      "1025": "dD"
    },
    R: {
      "1025": "eD"
    },
    S: {
      "1": "gD",
      "4097": "fD"
    }
  },
  B: 4,
  C: "Crisp edges/pixelated images",
  D: true
};