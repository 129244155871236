import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'h1[yuno-predefined]',
	standalone: true,
	template: ` <ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedHeading1Component {}

@Component({
	selector: 'h2[yuno-predefined]',
	standalone: true,
	template: ` <ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedHeading2Component {}

@Component({
	selector: 'h3[yuno-predefined]',
	standalone: true,
	template: ` <ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedHeading3Component {}
