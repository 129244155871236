import { gql } from 'apollo-angular';

export const DELETE_MAPUIBUTTON = gql`
	mutation deleteMapUiButtonById($_id: String!) {
		deleteMapUiButton(_id: $_id)
	}
`;

export type DeleteMapUiButtonMutation = {
	deleteMapUiButton: string;
};
