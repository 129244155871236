module.exports = {
  A: {
    A: {
      K: 0,
      D: 0,
      E: 0.0563043,
      F: 0.0422282,
      A: 0.0140761,
      B: 0.478586,
      eC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "eC", "K", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      eC: 962323200,
      K: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      "4": 0.007166,
      "5": 0.007166,
      "6": 0.010749,
      "7": 0.007166,
      "8": 0.010749,
      "9": 0.039413,
      C: 0,
      L: 0,
      M: 0.003583,
      G: 0,
      N: 0,
      O: 0.007166,
      P: 0.057328,
      Q: 0,
      H: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0.014332,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0.003583,
      r: 0.007166,
      s: 0.064494,
      t: 0.007166,
      u: 0.007166,
      v: 0.007166,
      w: 0.010749,
      x: 0.014332,
      AB: 0.017915,
      BB: 0.025081,
      CB: 0.014332,
      DB: 0.025081,
      EB: 0.053745,
      FB: 0.254393,
      GB: 3.38594,
      HB: 0.917248,
      I: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "L", "M", "G", "N", "O", "P", "Q", "H", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "I", "", "", ""],
    E: "Edge",
    F: {
      "4": 1689897600,
      "5": 1692576000,
      "6": 1694649600,
      "7": 1697155200,
      "8": 1698969600,
      "9": 1701993600,
      C: 1438128000,
      L: 1447286400,
      M: 1470096000,
      G: 1491868800,
      N: 1508198400,
      O: 1525046400,
      P: 1542067200,
      Q: 1579046400,
      H: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      j: 1649635200,
      k: 1651190400,
      l: 1653955200,
      m: 1655942400,
      n: 1659657600,
      o: 1661990400,
      p: 1664755200,
      q: 1666915200,
      r: 1670198400,
      s: 1673481600,
      t: 1675900800,
      u: 1678665600,
      v: 1680825600,
      w: 1683158400,
      x: 1685664000,
      AB: 1706227200,
      BB: 1708732800,
      CB: 1711152000,
      DB: 1713398400,
      EB: 1715990400,
      FB: 1718841600,
      GB: 1721865600,
      HB: 1724371200,
      I: 1726704000
    },
    D: {
      C: "ms",
      L: "ms",
      M: "ms",
      G: "ms",
      N: "ms",
      O: "ms",
      P: "ms"
    }
  },
  C: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0.351134,
      "5": 0,
      "6": 0.007166,
      "7": 0.089575,
      "8": 0,
      "9": 0.007166,
      fC: 0,
      GC: 0,
      J: 0.003583,
      IB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0.014332,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      JB: 0,
      y: 0,
      z: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0.003583,
      cB: 0.007166,
      dB: 0.003583,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0.003583,
      jB: 0,
      kB: 0.042996,
      lB: 0,
      mB: 0.007166,
      nB: 0.003583,
      oB: 0.017915,
      pB: 0,
      qB: 0,
      HC: 0.003583,
      rB: 0,
      IC: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      "7B": 0,
      "8B": 0.014332,
      Q: 0,
      H: 0,
      R: 0,
      JC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0.007166,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0.003583,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.007166,
      m: 0.010749,
      n: 0,
      o: 0.003583,
      p: 0,
      q: 0,
      r: 0,
      s: 0.007166,
      t: 0,
      u: 0,
      v: 0,
      w: 0.007166,
      x: 0,
      AB: 0.007166,
      BB: 0.003583,
      CB: 0.007166,
      DB: 0.007166,
      EB: 0.014332,
      FB: 0.032247,
      GB: 0.042996,
      HB: 0.447875,
      I: 1.08923,
      "9B": 0.007166,
      KC: 0,
      LC: 0,
      gC: 0,
      hC: 0,
      iC: 0
    },
    B: "moz",
    C: ["fC", "GC", "hC", "iC", "J", "IB", "K", "D", "E", "F", "A", "B", "C", "L", "M", "G", "N", "O", "P", "JB", "y", "z", "0", "1", "2", "3", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "HC", "rB", "IC", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "Q", "H", "R", "JC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "I", "9B", "KC", "LC", "gC"],
    E: "Firefox",
    F: {
      "0": 1368489600,
      "1": 1372118400,
      "2": 1375747200,
      "3": 1379376000,
      "4": 1688428800,
      "5": 1690848000,
      "6": 1693267200,
      "7": 1695686400,
      "8": 1698105600,
      "9": 1700524800,
      fC: 1161648000,
      GC: 1213660800,
      hC: 1246320000,
      iC: 1264032000,
      J: 1300752000,
      IB: 1308614400,
      K: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      L: 1335225600,
      M: 1338854400,
      G: 1342483200,
      N: 1346112000,
      O: 1349740800,
      P: 1353628800,
      JB: 1357603200,
      y: 1361232000,
      z: 1364860800,
      KB: 1386633600,
      LB: 1391472000,
      MB: 1395100800,
      NB: 1398729600,
      OB: 1402358400,
      PB: 1405987200,
      QB: 1409616000,
      RB: 1413244800,
      SB: 1417392000,
      TB: 1421107200,
      UB: 1424736000,
      VB: 1428278400,
      WB: 1431475200,
      XB: 1435881600,
      YB: 1439251200,
      ZB: 1442880000,
      aB: 1446508800,
      bB: 1450137600,
      cB: 1453852800,
      dB: 1457395200,
      eB: 1461628800,
      fB: 1465257600,
      gB: 1470096000,
      hB: 1474329600,
      iB: 1479168000,
      jB: 1485216000,
      kB: 1488844800,
      lB: 1492560000,
      mB: 1497312000,
      nB: 1502150400,
      oB: 1506556800,
      pB: 1510617600,
      qB: 1516665600,
      HC: 1520985600,
      rB: 1525824000,
      IC: 1529971200,
      sB: 1536105600,
      tB: 1540252800,
      uB: 1544486400,
      vB: 1548720000,
      wB: 1552953600,
      xB: 1558396800,
      yB: 1562630400,
      zB: 1567468800,
      "0B": 1571788800,
      "1B": 1575331200,
      "2B": 1578355200,
      "3B": 1581379200,
      "4B": 1583798400,
      "5B": 1586304000,
      "6B": 1588636800,
      "7B": 1591056000,
      "8B": 1593475200,
      Q: 1595894400,
      H: 1598313600,
      R: 1600732800,
      JC: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      j: 1651536000,
      k: 1653955200,
      l: 1656374400,
      m: 1658793600,
      n: 1661212800,
      o: 1663632000,
      p: 1666051200,
      q: 1668470400,
      r: 1670889600,
      s: 1673913600,
      t: 1676332800,
      u: 1678752000,
      v: 1681171200,
      w: 1683590400,
      x: 1686009600,
      AB: 1702944000,
      BB: 1705968000,
      CB: 1708387200,
      DB: 1710806400,
      EB: 1713225600,
      FB: 1715644800,
      GB: 1718064000,
      HB: 1720483200,
      I: 1722902400,
      "9B": 1725321600,
      KC: null,
      LC: null,
      gC: null
    }
  },
  D: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0.03583,
      "5": 0.168401,
      "6": 0.10749,
      "7": 0.07166,
      "8": 0.068077,
      "9": 0.10749,
      J: 0,
      IB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      JB: 0,
      y: 0,
      z: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0.010749,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0.003583,
      eB: 0,
      fB: 0.007166,
      gB: 0.025081,
      hB: 0.021498,
      iB: 0.007166,
      jB: 0.003583,
      kB: 0.003583,
      lB: 0.007166,
      mB: 0,
      nB: 0,
      oB: 0.032247,
      pB: 0.003583,
      qB: 0.007166,
      HC: 0,
      rB: 0,
      IC: 0.003583,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0.025081,
      xB: 0.007166,
      yB: 0,
      zB: 0.028664,
      "0B": 0.028664,
      "1B": 0,
      "2B": 0,
      "3B": 0.007166,
      "4B": 0.010749,
      "5B": 0.010749,
      "6B": 0.007166,
      "7B": 0.021498,
      "8B": 0.017915,
      Q: 0.103907,
      H: 0.014332,
      R: 0.021498,
      S: 0.032247,
      T: 0.010749,
      U: 0.014332,
      V: 0.025081,
      W: 0.075243,
      X: 0.017915,
      Y: 0.010749,
      Z: 0.014332,
      a: 0.053745,
      b: 0.014332,
      c: 0.014332,
      d: 0.050162,
      e: 0.010749,
      f: 0.010749,
      g: 0.017915,
      h: 0.046579,
      i: 0.025081,
      j: 0.021498,
      k: 0.021498,
      l: 0.017915,
      m: 0.111073,
      n: 0.085992,
      o: 0.017915,
      p: 0.028664,
      q: 0.03583,
      r: 0.046579,
      s: 1.42603,
      t: 0.025081,
      u: 0.039413,
      v: 0.050162,
      w: 0.10749,
      x: 0.103907,
      AB: 0.10749,
      BB: 0.118239,
      CB: 0.14332,
      DB: 0.229312,
      EB: 0.369049,
      FB: 1.49053,
      GB: 12.777,
      HB: 2.30745,
      I: 0.014332,
      "9B": 0.003583,
      KC: 0,
      LC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "J", "IB", "K", "D", "E", "F", "A", "B", "C", "L", "M", "G", "N", "O", "P", "JB", "y", "z", "0", "1", "2", "3", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "HC", "rB", "IC", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "Q", "H", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "I", "9B", "KC", "LC"],
    E: "Chrome",
    F: {
      "0": 1343692800,
      "1": 1348531200,
      "2": 1352246400,
      "3": 1357862400,
      "4": 1689724800,
      "5": 1692057600,
      "6": 1694476800,
      "7": 1696896000,
      "8": 1698710400,
      "9": 1701993600,
      J: 1264377600,
      IB: 1274745600,
      K: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      L: 1312243200,
      M: 1316131200,
      G: 1316131200,
      N: 1319500800,
      O: 1323734400,
      P: 1328659200,
      JB: 1332892800,
      y: 1337040000,
      z: 1340668800,
      KB: 1361404800,
      LB: 1364428800,
      MB: 1369094400,
      NB: 1374105600,
      OB: 1376956800,
      PB: 1384214400,
      QB: 1389657600,
      RB: 1392940800,
      SB: 1397001600,
      TB: 1400544000,
      UB: 1405468800,
      VB: 1409011200,
      WB: 1412640000,
      XB: 1416268800,
      YB: 1421798400,
      ZB: 1425513600,
      aB: 1429401600,
      bB: 1432080000,
      cB: 1437523200,
      dB: 1441152000,
      eB: 1444780800,
      fB: 1449014400,
      gB: 1453248000,
      hB: 1456963200,
      iB: 1460592000,
      jB: 1464134400,
      kB: 1469059200,
      lB: 1472601600,
      mB: 1476230400,
      nB: 1480550400,
      oB: 1485302400,
      pB: 1489017600,
      qB: 1492560000,
      HC: 1496707200,
      rB: 1500940800,
      IC: 1504569600,
      sB: 1508198400,
      tB: 1512518400,
      uB: 1516752000,
      vB: 1520294400,
      wB: 1523923200,
      xB: 1527552000,
      yB: 1532390400,
      zB: 1536019200,
      "0B": 1539648000,
      "1B": 1543968000,
      "2B": 1548720000,
      "3B": 1552348800,
      "4B": 1555977600,
      "5B": 1559606400,
      "6B": 1564444800,
      "7B": 1568073600,
      "8B": 1571702400,
      Q: 1575936000,
      H: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      j: 1648512000,
      k: 1650931200,
      l: 1653350400,
      m: 1655769600,
      n: 1659398400,
      o: 1661817600,
      p: 1664236800,
      q: 1666656000,
      r: 1669680000,
      s: 1673308800,
      t: 1675728000,
      u: 1678147200,
      v: 1680566400,
      w: 1682985600,
      x: 1685404800,
      AB: 1705968000,
      BB: 1708387200,
      CB: 1710806400,
      DB: 1713225600,
      EB: 1715644800,
      FB: 1718064000,
      GB: 1721174400,
      HB: 1724112000,
      I: 1726531200,
      "9B": null,
      KC: null,
      LC: null
    }
  },
  E: {
    A: {
      J: 0,
      IB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0.003583,
      A: 0,
      B: 0,
      C: 0,
      L: 0.007166,
      M: 0.028664,
      G: 0.007166,
      jC: 0,
      MC: 0,
      kC: 0,
      lC: 0,
      mC: 0,
      nC: 0,
      NC: 0,
      AC: 0.007166,
      BC: 0.010749,
      oC: 0.057328,
      pC: 0.078826,
      qC: 0.025081,
      OC: 0.010749,
      PC: 0.021498,
      CC: 0.028664,
      rC: 0.218563,
      DC: 0.028664,
      QC: 0.03583,
      RC: 0.032247,
      SC: 0.182733,
      TC: 0.021498,
      UC: 0.042996,
      sC: 0.290223,
      EC: 0.017915,
      VC: 0.039413,
      WC: 0.039413,
      XC: 0.042996,
      YC: 0.118239,
      ZC: 1.44753,
      aC: 0.415628,
      FC: 0.017915,
      bC: 0,
      tC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "jC", "MC", "J", "IB", "kC", "K", "lC", "D", "mC", "E", "F", "nC", "A", "NC", "B", "AC", "C", "BC", "L", "oC", "M", "pC", "G", "qC", "OC", "PC", "CC", "rC", "DC", "QC", "RC", "SC", "TC", "UC", "sC", "EC", "VC", "WC", "XC", "YC", "ZC", "aC", "FC", "bC", "tC", ""],
    E: "Safari",
    F: {
      jC: 1205798400,
      MC: 1226534400,
      J: 1244419200,
      IB: 1275868800,
      kC: 1311120000,
      K: 1343174400,
      lC: 1382400000,
      D: 1382400000,
      mC: 1410998400,
      E: 1413417600,
      F: 1443657600,
      nC: 1458518400,
      A: 1474329600,
      NC: 1490572800,
      B: 1505779200,
      AC: 1522281600,
      C: 1537142400,
      BC: 1553472000,
      L: 1568851200,
      oC: 1585008000,
      M: 1600214400,
      pC: 1619395200,
      G: 1632096000,
      qC: 1635292800,
      OC: 1639353600,
      PC: 1647216000,
      CC: 1652745600,
      rC: 1658275200,
      DC: 1662940800,
      QC: 1666569600,
      RC: 1670889600,
      SC: 1674432000,
      TC: 1679875200,
      UC: 1684368000,
      sC: 1690156800,
      EC: 1695686400,
      VC: 1698192000,
      WC: 1702252800,
      XC: 1705881600,
      YC: 1709596800,
      ZC: 1715558400,
      aC: 1722211200,
      FC: 1726444800,
      bC: null,
      tC: null
    }
  },
  F: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      F: 0,
      B: 0,
      C: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      JB: 0,
      y: 0,
      z: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0.003583,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0.017915,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      "7B": 0,
      "8B": 0,
      Q: 0,
      H: 0,
      R: 0,
      JC: 0,
      S: 0.028664,
      T: 0.003583,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0.039413,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.032247,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0,
      s: 0.154069,
      t: 0,
      u: 0.060911,
      v: 0,
      w: 0,
      x: 0,
      uC: 0,
      vC: 0,
      wC: 0,
      xC: 0,
      AC: 0,
      cC: 0,
      yC: 0,
      BC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "uC", "vC", "wC", "xC", "B", "AC", "cC", "yC", "C", "BC", "G", "N", "O", "P", "JB", "y", "z", "0", "1", "2", "3", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "Q", "H", "R", "JC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "", "", ""],
    E: "Opera",
    F: {
      "0": 1401753600,
      "1": 1405987200,
      "2": 1409616000,
      "3": 1413331200,
      F: 1150761600,
      uC: 1223424000,
      vC: 1251763200,
      wC: 1267488000,
      xC: 1277942400,
      B: 1292457600,
      AC: 1302566400,
      cC: 1309219200,
      yC: 1323129600,
      C: 1323129600,
      BC: 1352073600,
      G: 1372723200,
      N: 1377561600,
      O: 1381104000,
      P: 1386288000,
      JB: 1390867200,
      y: 1393891200,
      z: 1399334400,
      KB: 1417132800,
      LB: 1422316800,
      MB: 1425945600,
      NB: 1430179200,
      OB: 1433808000,
      PB: 1438646400,
      QB: 1442448000,
      RB: 1445904000,
      SB: 1449100800,
      TB: 1454371200,
      UB: 1457308800,
      VB: 1462320000,
      WB: 1465344000,
      XB: 1470096000,
      YB: 1474329600,
      ZB: 1477267200,
      aB: 1481587200,
      bB: 1486425600,
      cB: 1490054400,
      dB: 1494374400,
      eB: 1498003200,
      fB: 1502236800,
      gB: 1506470400,
      hB: 1510099200,
      iB: 1515024000,
      jB: 1517961600,
      kB: 1521676800,
      lB: 1525910400,
      mB: 1530144000,
      nB: 1534982400,
      oB: 1537833600,
      pB: 1543363200,
      qB: 1548201600,
      rB: 1554768000,
      sB: 1561593600,
      tB: 1566259200,
      uB: 1570406400,
      vB: 1573689600,
      wB: 1578441600,
      xB: 1583971200,
      yB: 1587513600,
      zB: 1592956800,
      "0B": 1595894400,
      "1B": 1600128000,
      "2B": 1603238400,
      "3B": 1613520000,
      "4B": 1612224000,
      "5B": 1616544000,
      "6B": 1619568000,
      "7B": 1623715200,
      "8B": 1627948800,
      Q: 1631577600,
      H: 1633392000,
      R: 1635984000,
      JC: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200,
      j: 1687219200,
      k: 1690329600,
      l: 1692748800,
      m: 1696204800,
      n: 1699920000,
      o: 1699920000,
      p: 1702944000,
      q: 1707264000,
      r: 1710115200,
      s: 1711497600,
      t: 1716336000,
      u: 1719273600,
      v: 1721088000,
      w: 1724284800,
      x: 1727222400
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      uC: "o",
      vC: "o",
      wC: "o",
      xC: "o",
      AC: "o",
      cC: "o",
      yC: "o",
      BC: "o"
    }
  },
  G: {
    A: {
      E: 0,
      MC: 0,
      zC: 0,
      dC: 0.00447708,
      "0C": 0.00149236,
      "1C": 0.00746181,
      "2C": 0.00895417,
      "3C": 0,
      "4C": 0.00746181,
      "5C": 0.0298472,
      "6C": 0.00895417,
      "7C": 0.0462632,
      "8C": 0.117897,
      "9C": 0.0149236,
      AD: 0.0119389,
      BD: 0.199976,
      CD: 0.00298472,
      DD: 0.0656639,
      ED: 0.00895417,
      FD: 0.037309,
      GD: 0.152221,
      HD: 0.105958,
      ID: 0.0567097,
      OC: 0.0567097,
      PC: 0.0671563,
      CC: 0.0790952,
      JD: 0.741704,
      DC: 0.150729,
      QC: 0.317873,
      RC: 0.15819,
      SC: 0.264148,
      TC: 0.0656639,
      UC: 0.10745,
      KD: 0.920787,
      EC: 0.0850646,
      VC: 0.131328,
      WC: 0.120881,
      XC: 0.179083,
      YC: 0.419354,
      ZC: 8.55869,
      aC: 1.44162,
      FC: 0.156698,
      bC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "MC", "zC", "dC", "0C", "1C", "2C", "E", "3C", "4C", "5C", "6C", "7C", "8C", "9C", "AD", "BD", "CD", "DD", "ED", "FD", "GD", "HD", "ID", "OC", "PC", "CC", "JD", "DC", "QC", "RC", "SC", "TC", "UC", "KD", "EC", "VC", "WC", "XC", "YC", "ZC", "aC", "FC", "bC", "", ""],
    E: "Safari on iOS",
    F: {
      MC: 1270252800,
      zC: 1283904000,
      dC: 1299628800,
      "0C": 1331078400,
      "1C": 1359331200,
      "2C": 1394409600,
      E: 1410912000,
      "3C": 1413763200,
      "4C": 1442361600,
      "5C": 1458518400,
      "6C": 1473724800,
      "7C": 1490572800,
      "8C": 1505779200,
      "9C": 1522281600,
      AD: 1537142400,
      BD: 1553472000,
      CD: 1568851200,
      DD: 1572220800,
      ED: 1580169600,
      FD: 1585008000,
      GD: 1600214400,
      HD: 1619395200,
      ID: 1632096000,
      OC: 1639353600,
      PC: 1647216000,
      CC: 1652659200,
      JD: 1658275200,
      DC: 1662940800,
      QC: 1666569600,
      RC: 1670889600,
      SC: 1674432000,
      TC: 1679875200,
      UC: 1684368000,
      KD: 1690156800,
      EC: 1694995200,
      VC: 1698192000,
      WC: 1702252800,
      XC: 1705881600,
      YC: 1709596800,
      ZC: 1715558400,
      aC: 1722211200,
      FC: 1726444800,
      bC: null
    }
  },
  H: {
    A: {
      LD: 0.05
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "LD", "", "", ""],
    E: "Opera Mini",
    F: {
      LD: 1426464000
    }
  },
  I: {
    A: {
      GC: 0,
      J: 0.0000327216,
      I: 0.326169,
      MD: 0,
      ND: 0,
      OD: 0,
      PD: 0.000130886,
      dC: 0.000130886,
      QD: 0,
      RD: 0.000523546
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "MD", "ND", "OD", "GC", "J", "PD", "dC", "QD", "RD", "I", "", "", ""],
    E: "Android Browser",
    F: {
      MD: 1256515200,
      ND: 1274313600,
      OD: 1291593600,
      GC: 1298332800,
      J: 1318896000,
      PD: 1341792000,
      dC: 1374624000,
      QD: 1386547200,
      RD: 1401667200,
      I: 1726531200
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      H: 1.24603,
      AC: 0,
      cC: 0,
      BC: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "AC", "cC", "C", "BC", "H", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      AC: 1314835200,
      cC: 1318291200,
      C: 1330300800,
      BC: 1349740800,
      H: 1709769600
    },
    D: {
      H: "webkit"
    }
  },
  L: {
    A: {
      I: 44.331
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "", "", ""],
    E: "Chrome for Android",
    F: {
      I: 1726531200
    }
  },
  M: {
    A: {
      "9B": 0.365712
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "9B", "", "", ""],
    E: "Firefox for Android",
    F: {
      "9B": 1725321600
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      CC: 1.13563
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "CC", "", "", ""],
    E: "UC Browser for Android",
    F: {
      CC: 1710115200
    },
    D: {
      CC: "webkit"
    }
  },
  P: {
    A: {
      "0": 0.0647361,
      "1": 0.0647361,
      "2": 0.0755255,
      "3": 1.27314,
      J: 0.0971042,
      y: 0.0215787,
      z: 0.0431574,
      SD: 0.0107894,
      TD: 0.0107894,
      UD: 0.0323681,
      VD: 0,
      WD: 0,
      NC: 0,
      XD: 0.0107894,
      YD: 0,
      ZD: 0.0107894,
      aD: 0,
      bD: 0,
      DC: 0,
      EC: 0.0215787,
      FC: 0,
      cD: 0.0215787
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "J", "SD", "TD", "UD", "VD", "WD", "NC", "XD", "YD", "ZD", "aD", "bD", "DC", "EC", "FC", "cD", "y", "z", "0", "1", "2", "3", "", "", ""],
    E: "Samsung Internet",
    F: {
      "0": 1689292800,
      "1": 1697587200,
      "2": 1711497600,
      "3": 1715126400,
      J: 1461024000,
      SD: 1481846400,
      TD: 1509408000,
      UD: 1528329600,
      VD: 1546128000,
      WD: 1554163200,
      NC: 1567900800,
      XD: 1582588800,
      YD: 1593475200,
      ZD: 1605657600,
      aD: 1618531200,
      bD: 1629072000,
      DC: 1640736000,
      EC: 1651708800,
      FC: 1659657600,
      cD: 1667260800,
      y: 1677369600,
      z: 1684454400
    }
  },
  Q: {
    A: {
      dD: 0.3208
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "dD", "", "", ""],
    E: "QQ Browser",
    F: {
      dD: 1710288000
    }
  },
  R: {
    A: {
      eD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "eD", "", "", ""],
    E: "Baidu Browser",
    F: {
      eD: 1710201600
    }
  },
  S: {
    A: {
      fD: 0.051328,
      gD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "fD", "gD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      fD: 1527811200,
      gD: 1631664000
    }
  }
};