import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Textfield, TextfieldComponents } from '@yuno/api/interface';

import { textfieldsActions } from './textfield.actions';
import { textfieldsFeature } from './textfield.state';

@Injectable()
export class TextfieldFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(textfieldsFeature.selectLoaded));
	allTextfields$ = this.store.pipe(select(textfieldsFeature.selectTextfields));
	selectedTextfield$ = this.store.pipe(select(textfieldsFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(textfieldsFeature.selectSelectedLoaded));
	templates$ = this.store.pipe(select(textfieldsFeature.selectTemplates));
	status$ = this.store.pipe(select(textfieldsFeature.selectStatus));

	get() {
		this.store.dispatch(textfieldsActions.load());
	}

	search(filter: string) {
		this.store.dispatch(textfieldsActions.loadFiltered({ filter }));
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(textfieldsActions.select({ _id }));
	}

	clearSelect() {
		this.store.dispatch(textfieldsActions.clearSelect());
	}

	getCount(
		variable: 'public' | 'non-public' | 'client' | 'non-client' | 'all'
	): Observable<number> {
		return this.store.pipe(select(textfieldsFeature.getCount(variable)));
	}

	setTemplate(data: TextfieldComponents[] | undefined) {
		if (!data) {
			return;
		}
		this.store.dispatch(textfieldsActions.setTemplate({ data }));
	}

	updateSelect(data: Partial<Textfield>) {
		this.store.dispatch(textfieldsActions.updateSelect({ data }));
	}

	updateTextfield(data: Partial<Textfield>, index: number) {
		this.store.dispatch(textfieldsActions.updateTextfield({ data, index }));
	}

	updateAllTextfields(bool: boolean, filter: string) {
		this.store.dispatch(textfieldsActions.updateAllTextfields({ bool, filter }));
	}

	save() {
		this.store.dispatch(textfieldsActions.save());
	}

	delete(_id: string) {
		this.store.dispatch(textfieldsActions.delete({ _id }));
	}
}
