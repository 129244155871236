<router-outlet></router-outlet>
@if (toggleTemplates) {
	<yuno-admin-templates
		[selector]="'textfield'"
		(bgClicked)="toggleTemplates = false"
		(clicked)="onCreate($event)" />
}
@if (data$ | async; as data) {
	<div class="block h-full w-full overflow-hidden">
		<div class="flex h-full flex-row overflow-hidden">
			<section class="h-full w-full overflow-x-auto p-4 shadow-sm">
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="true"
					[pagination]="true"
					[pageOptions]="{
						pageSize: 25,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="data.textfields"
					[buttons]="tableButtons"
					[buttonsWidth]="9"
					[columns]="[
						{ key: 'id', label: 'textfield' },
						{
							key: 'public',
							label: 'Client',
							type: 'masterToggle',
							masterToggle: masterToggle,
							hidden: disableUserAccess()
						},
						{ key: 'updatedBy', label: 'last modified by', child: 'displayName' },
						{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
					]"
					(changed)="onUpdatePublic($event)"
					(clicked)="onSelectTextfield($event)"
					(outputFilter)="toggles.filter = $event"
					(masterToggled)="onToggleAllPublic($event)">
					@if (minimalAppRole(userRoles.EDITOR)) {
						<button yuno-admin-button (click)="onSelectTemplate(true)" [add]="true">
							New Textfield
						</button>
					}
				</yuno-admin-table>
			</section>
			@if (data.sideoptions && data.sideoptions.visible) {
				<yuno-admin-side-options-menu [(opened)]="data.sideoptions.open" />
			}
		</div>
	</div>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onSelectTextfield(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDeleteTextfield(row)"></button>
</ng-template>

<ng-template #sideOptions>
	<div class="flex flex-col gap-8">
		<section>
			<h4>Filter</h4>
			<yuno-admin-side-options-button
				(click)="setFilter('all')"
				[active]="$filter() === 'all'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#000'"
					class="text-white">
					{{ $allCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				All
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				(click)="setFilter('public')"
				[active]="$filter() === 'public'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#27AE60'"
					class="text-white">
					{{ $publicCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				Public
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				(click)="setFilter('non-public')"
				[active]="$filter() === 'non-public'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#C0392B'"
					class="text-white">
					{{ $nonPublicCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				Non-public
			</yuno-admin-side-options-button>
		</section>
		<section>
			<h4>CMS Visibility</h4>
			<yuno-admin-side-options-button
				(click)="setFilter('all')"
				[active]="$filter() === 'all'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#000'"
					class="text-white">
					{{ $allCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				All
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				(click)="setFilter('public')"
				[active]="$filter() === 'public'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#27AE60'"
					class="text-white">
					{{ $clientCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				Visible to client
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				(click)="setFilter('non-public')"
				[active]="$filter() === 'non-public'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#C0392B'"
					class="text-white">
					{{ $nonClientCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				Not visible to client
			</yuno-admin-side-options-button>
		</section>
	</div>
</ng-template>
