import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { RoutesVariantsDto } from '@yuno/api/dto';

import { variantsActions } from './variants.actions';
import { variantsFeature } from './variants.state';

@Injectable()
export class VariantsFacade {
	private readonly store = inject(Store);

	/**
	 * Combine pieces of state using createSelector,
	 * and expose them as observables through the facade.
	 */
	loaded$ = this.store.pipe(select(variantsFeature.selectLoaded));
	navigation$ = this.store.pipe(select(variantsFeature.selectNavigation));
	status$ = this.store.pipe(select(variantsFeature.selectStatus));

	/**
	 * Use the initialization action to perform one
	 * or more tasks in your Effects.
	 */
	getNavigationsByAppId() {
		this.store.dispatch(variantsActions.load());
	}

	update(navigation: RoutesVariantsDto) {
		this.store.dispatch(variantsActions.update({ navigation }));
	}

	save() {
		this.store.dispatch(variantsActions.save());
	}
}
