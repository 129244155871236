import { gql } from 'apollo-angular';

import { MapUiButtons } from '@yuno/api/interface';

export const GET_MAPBUTTONS_BY_APPID = gql`
	query getMapButtonsByAppId($appId: String!) {
		mapUiButtons(appId: $appId) {
			_id
			id
			icon
			updatedAt
		}
	}
`;

export const GET_MAPBUTTON_BY_ID = gql`
	query getMapButtonById($id: String!) {
		selectedMapUiButton(id: $id) {
			_id
			id
			icon
			desktop
			mobile
			events {
				type
				options
			}
		}
	}
`;

export type MapButtonsQuery = {
	mapUiButtons: Partial<MapUiButtons>[];
};

export type SelectMapButtonQuery = {
	selectedMapUiButton: Partial<MapUiButtons>;
};
