import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NavbarGuard, ResetStateGuard } from '@yuno/admin/core';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				canActivate: [NavbarGuard('Dashboard', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'dashboard' },
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardClientViewComponent)
			},
			{
				path: 'planning',
				canActivate: [NavbarGuard('Planning', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'planning' },
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardClientViewComponent)
			},
			{
				path: 'deliverables',
				canActivate: [NavbarGuard('Deliverables', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'deliverables' },
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardClientViewComponent)
			},
			{
				path: 'tickets',
				canActivate: [NavbarGuard('Tickets', 2)],
				canDeactivate: [ResetStateGuard],
				data: { type: 'tickets' },
				loadComponent: () =>
					import('./dashboard.component').then(c => c.DashboardClientViewComponent)
			},
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardClientRoutingModule {}
