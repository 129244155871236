import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { ObjectsFacade } from '@yuno/admin/features/objects/data-access';
import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { DataObjects } from '@yuno/api/interface';

import { ObjectsRoutingModule } from './objects-routing.module';


@Component({
	selector: 'yuno-admin-objects',
	standalone: true,
	imports: [ObjectsRoutingModule, YunoAdminTableComponent, YunoAdminButtonsModule, AsyncPipe],
	templateUrl: './objects.component.html',
	styleUrls: ['./objects.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectsComponent implements OnInit {
	private readonly objectsFacade = inject(ObjectsFacade);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);

	data$ = combineLatest({
		objects: this.objectsFacade.objects$.pipe(
			map(data => data as { [key: string]: unknown }[])
		),
		selected: this.objectsFacade.selectedObjects$
	});

	ngOnInit(): void {
		this.objectsFacade.get();
	}

	onCreate() {
		this.objectsFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onSelect(row: Partial<DataObjects>): void {
		this.router.navigate(['edit', row.data?._id as string], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<DataObjects>): void {
		this.objectsFacade.duplicate(row);
	}

	onDelete(row: Partial<DataObjects>): void {
		this.objectsFacade.delete(row);
	}
}
