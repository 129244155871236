module.exports = {
  A: {
    D: {
      "1": "4 5 6 7 8 9 gB hB iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "J IB K D E F A B C L M G",
      "33": "0 1 2 3 N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB"
    },
    L: {
      "1": "I"
    },
    B: {
      "1": "4 5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "2": "C L M G N O P"
    },
    C: {
      "1": "4 5 6 7 8 9 iB jB kB lB mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB K D E F hC iC",
      "33": "0 1 2 3 A B C L M G N O P JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB"
    },
    M: {
      "1": "9B"
    },
    A: {
      "2": "K D E F A B eC"
    },
    F: {
      "1": "TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C uC vC wC xC AC cC yC BC",
      "33": "0 1 2 3 G N O P JB y z KB LB MB NB OB PB QB RB SB"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    E: {
      "1": "B C L M G AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC",
      "2": "J IB jC MC kC tC",
      "33": "K D E F A lC mC nC NC"
    },
    G: {
      "1": "8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "2": "MC zC dC 0C",
      "33": "E 1C 2C 3C 4C 5C 6C 7C"
    },
    P: {
      "1": "0 1 2 3 y z SD TD UD VD WD NC XD YD ZD aD bD DC EC FC cD",
      "2": "J"
    },
    I: {
      "1": "I",
      "2": "GC J MD ND OD PD dC QD RD"
    }
  },
  B: 6,
  C: "isolate from unicode-bidi",
  D: undefined
};