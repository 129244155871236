import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ENVIRONMENT } from '@yuno/admin/core';
import { AuthFacade } from '@yuno/angular-auth';
import {
	App,
	Client,
	Config,
	UserRoles,
	UserRolesEnum,
	YunoUserRoles,
	YunoUserRolesEnum
} from '@yuno/api/interface';

import { LanguageFormService } from '../services';

@Component({
	selector: 'yuno-admin-app-data',
	standalone: true,
	imports: [],
	template: ``
})
export class AppDataComponent {
	readonly route = inject(ActivatedRoute);
	readonly router = inject(Router);
	readonly auth = inject(AuthFacade);

	readonly languageService = inject(LanguageFormService);
	readonly environment = inject(ENVIRONMENT);

	readonly userRoles = YunoUserRolesEnum;
	readonly roles = UserRolesEnum;

	// Users
	get xkpMember(): boolean {
		return this.route.snapshot.data['xkp'];
	}

	get appRole(): YunoUserRolesEnum {
		return this.route.snapshot.data['appRole'] as YunoUserRoles;
	}

	get userRole(): UserRolesEnum {
		return this.route.snapshot.data['userRole'] as UserRoles;
	}

	get isMinimalReader(): boolean {
		return this.minimalAppRole(this.userRoles.READER);
	}

	get isMinimalEditor(): boolean {
		return this.minimalAppRole(this.userRoles.EDITOR);
	}

	get isMinimalPublisher(): boolean {
		return this.minimalAppRole(this.userRoles.PUBLISHER);
	}

	get isMinimalAdmin(): boolean {
		return this.minimalAppRole(this.userRoles.ADMIN);
	}

	// Current Application
	get app(): Partial<App> {
		return this.route.snapshot.data['app'];
	}

	get appId(): string {
		return this.route.snapshot.data['app']._id;
	}

	get appUrl(): string {
		return this.route.snapshot.data['app'].url[0];
	}

	get appName(): string {
		return this.route.snapshot.data['app'].id;
	}

	get config(): Partial<Config> {
		return this.route.snapshot.data['config'];
	}

	get client(): Partial<Client> {
		return this.route.snapshot.data['app']['client'];
	}

	get clientId(): string {
		return this.route.snapshot.data['app']['client']._id;
	}

	get clientUrl(): string {
		return this.route.snapshot.data['app']['client'].url[0];
	}

	// Additional Info
	get trackingId(): string {
		return this.route.snapshot.data['app'].trackingId;
	}

	get language(): string {
		return this.route.snapshot.data['config']?.language;
	}

	languageSelector() {
		const language = this.route.snapshot.data['config']?.language;
		const languages = this.route.snapshot.data['config']?.languages;

		if (language && languages && languages.length >= 1) {
			if (!this.languageService.isActive) {
				this.languageService.openLanguageSelector();
			}
		}
	}

	destroyLanguageSelector() {
		this.languageService.isActive && this.languageService.destroyLanguageSelector();
	}

	minimalAppRole(minimal: YunoUserRoles, role?: YunoUserRolesEnum): boolean {
		return this.auth.userHasMinimalAppRole(minimal, role || this.appRole);
	}

	minimalRole(minimal: UserRoles, role?: UserRolesEnum): boolean {
		return this.auth.userHasMinimalRole(minimal, role || this.userRole);
	}
}
