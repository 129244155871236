import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'yuno-predefined-paragraph',
	standalone: true,
	template: `
		<h4 class="font-semibold empty:hidden">
			<ng-content select="[heading]"></ng-content>
		</h4>
		<p>
			<ng-content></ng-content>
		</p>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedParagraphComponent {}
