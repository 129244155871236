import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoDirective, TranslocoModule } from '@ngneat/transloco';

import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminNavbarButtonsComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LanguageAll } from '@yuno/api/interface';
import { LegendComponent } from '@yuno/project-atlas/ui';

import { LegendActiveLayerEditorComponent } from '../legend-active-layer-editor/legend-active-layer-editor.component';
import { LegendEditorService, tabLegendContent } from '../legend-editor.service';
import { LegendGradientEditorComponent } from '../legend-gradient-editor/legend-gradient-editor.component';
import { LegendIconEditorComponent } from '../legend-icon-editor/legend-icon-editor.component';
import { LegendImageEditorComponent } from '../legend-image-editor/legend-image-editor.component';
import { LegendListEditorComponent } from '../legend-list-editor/legend-list-editor.component';
import { LegendSvgEditorComponent } from '../legend-svg-editor/legend-svg-editor.component';
import { LegendMainEditorContainerComponent } from './main-editor.component';


@Component({
	selector: 'yuno-admin-legend-item-container',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		TranslocoModule,
		AngularPipesModule,
		ReactiveFormsModule,
		DragDropModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		YunoFormsModule,
		AngularSvgLoaderModule,
		LegendComponent,
		LegendIconEditorComponent,
		LegendImageEditorComponent,
		LegendListEditorComponent,
		LegendSvgEditorComponent,
		LegendGradientEditorComponent,
		LegendActiveLayerEditorComponent,
		YunoAdminNavbarButtonsComponent,
		EditContainerContentDirective,
		TranslocoDirective,
		LegendMainEditorContainerComponent
	],
	template: `
		<yuno-edit-container
			[col]="12"
			[pos]="3"
			[span]="8"
			[tabs]="['Icon', 'SVG', 'Image', 'List']"
			[startTab]="service.getStartTab(service.activeContent)"
			(outputTab)="onSwitchTab($event.key)">
			<ng-container buttons>
				<button yuno-admin-close-button (click)="service.editContent = false"></button>
			</ng-container>
			<div edit-container-content [hasTabs]="true">
				@if (service.activeContent) {
					<form [formGroup]="service.activeContent" class="grid grid-cols-1 gap-4">
						<yuno-forms-toggle
							labelPos="side"
							formControlName="public"
							label="Public" />
						<section formGroupName="title" class="mt-4">
							@for (lang of languages; track lang) {
								@if (lang === language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										label="Legend item name" />
								}
							}
						</section>
						<yuno-forms-number
							label="Icon container height"
							formControlName="iconHeight" />
						<yuno-forms-toggle
							labelPos="side"
							formControlName="bold"
							label="Bold Text" />
						<yuno-forms-divider />
					</form>
				}
				@if (service.tabValue === 'Icon') {
					<yuno-admin-legend-icon-editor [form]="service.activeContent" />
				}
				@if (service.tabValue === 'SVG') {
					<yuno-admin-legend-svg-editor [form]="service.activeContent" />
				}
				@if (service.tabValue === 'Image') {
					<yuno-admin-legend-image-editor [form]="service.activeContent" />
				}
				@if (service.tabValue === 'List') {
					<yuno-admin-legend-list-editor
						[form]="service.activeContent"
						[language]="language"
						(list)="service.editList = true" />
				}
				<yuno-admin-legend-active-layer-editor [form]="service.activeContent" />
			</div>
		</yuno-edit-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendItemEditorContainerComponent extends AppDataComponent {
	protected readonly languages = LanguageAll;
	readonly service = inject(LegendEditorService);

	onSwitchTab(key: string) {
		this.service.tabValue = key as tabLegendContent;
	}
}
