import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { MapUiButtons } from '@yuno/api/interface';

import { mapUiButtonsActions } from './map-ui-buttons.actions';

export interface MapUiButtonsState {
	loaded: boolean;
	uiButtons: Partial<MapUiButtons>[];
	selected: Partial<MapUiButtons> | undefined;
	status: Status;
}

export const initialState: MapUiButtonsState = {
	loaded: false,
	uiButtons: [],
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(mapUiButtonsActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(mapUiButtonsActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		uiButtons: data,
		status: Status.SUCCESS
	})),
	on(mapUiButtonsActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(mapUiButtonsActions.saveSuccess, state => ({
		...state
	})),

	// Update
	on(mapUiButtonsActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data }
	})),
	on(mapUiButtonsActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(mapUiButtonsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),

	on(mapUiButtonsActions.updateSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data }
	})),
	on(mapUiButtonsActions.updateFailure, (state, { error }) => ({ ...state, error }))
);

export const mapUiButtonsFeature = createFeature({
	name: 'mapUiButtons',
	reducer
});
