import { gql } from 'apollo-angular';

import { Theme } from '@yuno/api/interface';

export const GET_THEMES_BY_APPID = gql`
	query getThemesByAppId($appId: String!) {
		themes(appId: $appId) {
			_id
			id
			datasets {
				_id
				id
			}
			states {
				default {
					_id
					state
				}
				available {
					state {
						_id
						state
					}
				}
			}
			modules {
				participation {
					button
				}
				panoramas {
					threejs {
						active
					}
				}
			}
		}
	}
`;

export const GET_THEME_BY_ID = gql`
	query getThemeById($_id: String!) {
		selectedTheme(_id: $_id) {
			_id
			id
			keepView
			transition
			controls {
				breadcrumbs {
					active
				}
				backToOverview {
					active
				}
				controlbar {
					location
					zoom
					measure
					mapStyles
				}
				mapButtons {
					_id
					id
				}
				mapscale
			}
			sidemenu {
				_id
				id
			}
			datasets {
				_id
				id
			}
			modules {
				measure {
					color
				}
				searchAdress {
					active
					country
				}
				disclaimer {
					active
					when
					textblock {
						_id
						id
					}
				}
				panoramas {
					vr
					popup
					calibrate
					splitscreen {
						active
						keepView
						dial
					}
					threejs {
						active
						range
					}
					hotspots {
						active
						range
						type
					}
				}
				participation {
					button
					buttonText
					startTitle
					startText
					dataTitle
					showData
					data {
						data
					}
				}
			}
			textfield {
				default {
					text
					when
				}
				isClosed
				wide
				visibility
				width
			}
			legend
			states {
				default {
					_id
					state
				}
				available {
					defaultText
					events
					state {
						_id
						state
					}
				}
			}
			mapStyles {
				style {
					_id
					id
				}
				description
			}
			view {
				bounds
				maxBounds
				minZoom
				zoom
				maxZoom
				minPitch
				pitch
				maxPitch
				bearing
				boxZoom
				doubleClickZoom
				interactive
				dragPan
				dragRotate
				keyboard
				scrollZoom
				touchZoom
				touchPitch
				touchRotate
				panoramaPopup
				clustering {
					mp {
						active
						minZoom
					}
				}
			}
		}
	}
`;

export type ThemeQuery = {
	themes: Partial<Theme>[];
};

export type SelectThemeQuery = {
	selectedTheme: Partial<Theme>;
};
