import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { sortBy } from 'lodash';
import { map } from 'rxjs';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import { YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { MarkerCategory } from '@yuno/api/interface';

import { PlaceMarkersRoutingModule } from './place-markers.routing.module';


@Component({
	selector: 'yuno-admin-place-markers-internal-view',
	standalone: true,
	imports: [
		PlaceMarkersRoutingModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		AsyncPipe
	],
	templateUrl: './place-markers.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceMarkersComponent extends AppDataComponent {
	private readonly facade = inject(MarkerCategoriesFacade);

	categories$ = this.facade.markerCategories$.pipe(
		map(data => {
			const list: { [key: string]: unknown }[] = data.map(category => ({
				_id: category?._id || '',
				id: category?.id || '',
				order: category.order,
				public: category.public,
				style: category?.type,
				inputs: category.inputs?.length,
				events:
					category.events?.onClick?.length + ' | ' + category.events?.onMouseMove?.length,
				intern: category.intern,
				cluster: category.cluster?.active ? 'yes' : ''
			}));
			return sortBy(list, data => {
				return data['order'] || 1;
			});
		})
	);

	onView(row: Partial<MarkerCategory>): void {
		this.router.navigate(['view', row?._id as string], {
			relativeTo: this.route
		});
	}

	onEdit(row: Partial<MarkerCategory>): void {
		this.router.navigate(['view', row?._id as string, 'map', 'edit'], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<MarkerCategory>): void {
		this.facade.duplicate(row?._id as string);
	}

	onDelete(row: Partial<MarkerCategory>): void {
		this.facade.delete(row?._id as string);
	}
}
