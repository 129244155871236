import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_MAPUIBUTTON,
	DeleteMapUiButtonMutation,
	GET_MAPBUTTONS_BY_APPID,
	GET_MAPBUTTON_BY_ID,
	MapButtonsQuery,
	SAVE_MAPBUTTON,
	SaveMapButtonsQuery,
	SelectMapButtonQuery
} from '../../utils';
import { mapUiButtonsActions } from './map-ui-buttons.actions';
import { mapUiButtonsFeature } from './map-ui-buttons.state';

export const load = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(mapUiButtonsActions.load, mapUiButtonsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.mutate<MapButtonsQuery>({
						mutation: GET_MAPBUTTONS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.mapUiButtons) {
								throw new Error('no Map UI Buttons found');
							}
							return mapUiButtonsActions.loadSuccess({
								data: data.data?.mapUiButtons
							});
						}),
						take(1),
						catchError(error => of(mapUiButtonsActions.loadFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const selected = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(mapUiButtonsActions.select),
			switchMap(selection =>
				graphql
					.mutate<SelectMapButtonQuery>({
						mutation: GET_MAPBUTTON_BY_ID,
						variables: {
							id: selection.id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.selectedMapUiButton) {
								throw new Error('no Map UI Buttons found');
							}
							return mapUiButtonsActions.selectSuccess({
								data: data.data?.selectedMapUiButton
							});
						}),
						take(1),
						catchError(error => of(mapUiButtonsActions.selectFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const save = createEffect(
	(
		actions$ = inject(Actions),
		graphql = inject(GraphQLService),
		message = inject(MessageService),
		store = inject(Store)
	) => {
		return actions$.pipe(
			ofType(mapUiButtonsActions.save),
			withLatestFrom(
				store.pipe(select(mapUiButtonsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, button, appId]) =>
				graphql
					.mutate<SaveMapButtonsQuery>({
						mutation: SAVE_MAPBUTTON,
						variables: {
							mapUiButton: button,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveMapUiButton) {
								message.sendToast(`Error saving Map UI Button!`, 'error');
								throw new Error('error saving Map UI Button to the database');
							}
							return mapUiButtonsActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(mapUiButtonsActions.saveFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const update = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(mapUiButtonsActions.update),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Map UI Button!`, 'error');
					throw new Error('no Map UI Button found');
				}

				return mapUiButtonsActions.updateSuccess({
					data: data.data
				});
			}),
			catchError(error => of(mapUiButtonsActions.updateFailure({ error })))
		),
	{ functional: true }
);

export const deleteMapUiBtn = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(mapUiButtonsActions.delete),
			switchMap(button =>
				graphql
					.mutate<DeleteMapUiButtonMutation>({
						mutation: DELETE_MAPUIBUTTON,
						variables: {
							_id: button._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteMapUiButton) {
								throw new Error('error deleting Map Ui Button from the database');
							}
							store.dispatch(mapUiButtonsActions.reload());
							return mapUiButtonsActions.deleteSuccess({
								_id: data.data.deleteMapUiButton
							});
						}),
						take(1),
						catchError(error => of(mapUiButtonsActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(mapUiButtonsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Map UI Button successfully saved!`, 'success');
			}),
			switchMap(() => of(mapUiButtonsActions.load()))
		),
	{ functional: true }
);

export const deleteSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(mapUiButtonsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Map UI Button successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(mapUiButtonsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Map UI Button!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
