import { gql } from 'apollo-angular';

import { AppUser } from '@yuno/api/interface';

export const GET_APP_USERS = gql`
	query getAppUsers($appId: String!) {
		appUsers(appId: $appId) {
			id
			role
			mailing {
				participation
				addedTo
			}
			user {
				displayName
				email
			}
			variants {
				_id
				id
			}
		}
	}
`;

export const GET_APP_USERS_CLIENT = gql`
	query getAppUsersClient($appId: String!) {
		appUsersClient(appId: $appId) {
			role
			mailing {
				participation
			}
			user {
				displayName
				email
			}
			variants {
				_id
				id
			}
		}
	}
`;

export type AppUserQuery = {
	appUsers: Partial<AppUser>[];
};

export type AppUserClientQuery = {
	appUsersClient: Partial<AppUser>[];
};
