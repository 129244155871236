import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Dashboard, DashboardHelpItem } from '@yuno/api/interface';

export const dashboardActions = createActionGroup({
	source: 'Dashboard',
	events: {
		load: props<{ key: 'dashboard' | 'planning' | 'deliverables' | 'tickets' }>(),
		loadSuccess: props<{ data: Partial<Dashboard> }>(),
		loadFailure: props<{ error: Error }>(),

		select: props<{ index: number }>(),
		clearSelect: emptyProps(),
		updateSelect: props<{ data: Partial<DashboardHelpItem> }>(),
		updatePublic: props<{ public: boolean }>(),

		addItem: emptyProps(),
		removeItem: props<{ index: number }>(),

		update: emptyProps(),
		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>()
	}
});
