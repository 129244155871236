<div class="mb-4 flex flex-col">
	@if (data$ | async; as data) {
		@if (data.mapStyle) {
			<div class="h-96 w-full">
				<mgl-map
					(mapLoad)="mapLoad($event)"
					(styleImageMissing)="mapOnMissingImage($event)"
					class="z-0 h-full w-full"
					[style]="data.mapStyle">
					@if (coordinates) {
						<mgl-marker
							[draggable]="draggabble"
							(markerDragEnd)="dragEnd($event)"
							[lngLat]="getLngLat(coordinates)">
						</mgl-marker>
					}
				</mgl-map>
			</div>
		}
	}
</div>
