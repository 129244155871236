<div
	class="flex w-full flex-row items-center justify-center gap-2 rounded-md border border-yuno-blue-gray-400 bg-yuno-blue-gray-300 p-2">
	<ng-content></ng-content>

	@if (buttonEllipsis) {
		<button
			type="button"
			class="m-4 h-6 w-6"
			(click)="activeButtons = true"
			cdkOverlayOrigin
			#trigger="cdkOverlayOrigin">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="h-6 w-6">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
			</svg>
		</button>

		<ng-template
			cdkConnectedOverlay
			(backdropClick)="disableEllipsisButton()"
			[cdkConnectedOverlayHasBackdrop]="true"
			[cdkConnectedOverlayBackdropClass]="'bg-transparent'"
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayOpen]="activeButtons">
			<div
				(click)="handleEllipsisClick()"
				class="z-999 grid h-auto w-auto grid-cols-1 divide-y divide-yuno-blue-gray-400/60 overflow-x-auto rounded-md border border-yuno-blue-gray-400 bg-yuno-blue-gray-200 p-4 shadow-lg">
				<ng-container
					[ngTemplateOutlet]="buttons"
					[ngTemplateOutletContext]="{ $implicit: index }" />
			</div>
		</ng-template>
	}
</div>
