import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export const MATOMO_URL = new InjectionToken('MotomoUrl');
export const COOKIE_DOMAIN = new InjectionToken('CookieDomain');

@Injectable()
export class MatomoInjectorService {
	initialized = false;
	trackingId: number;

	constructor(
		@Optional() @Inject(MATOMO_URL) private readonly matomo_url: string,
		@Optional() @Inject(COOKIE_DOMAIN) private readonly cookie_domain: string | undefined
	) {
		window._paq = window?._paq || [];
		window._mtm = window?._mtm || [];
	}

	// setup Matomo Tracking script
	init(trackingId = 1): void {
		try {
			// remove trailing slash from urls
			const url = this.matomo_url.replace(/\/+$/, '');

			if (this.initialized && trackingId === this.trackingId) {
				return;
			}

			// When it is initialised but the TrackingId is changing
			// for example when switching projects
			// This will find the current SiteId and replaces it with the new Tracking Id
			if (this.initialized && trackingId !== this.trackingId) {
				this.trackingId = trackingId;
				const select = window._paq.find((arr: string[]) => arr[0] === 'setSiteId');
				if (select) {
					select[1] = trackingId;
				}
				return;
			}

			this.initialized = true;
			this.trackingId = trackingId;

			/* Initialize Matomo */
			window._paq.push(['requireCookieConsent']);

			window._paq.push(['trackPageView']);
			window._paq.push(['enableLinkTracking']);

			window._paq.push(['setTrackerUrl', url + '/matomo.php']);

			/* Set SiteId */
			window._paq.push(['setSiteId', trackingId]);

			// Share the tracking cookie across example.com, www.example.com, subdomain.example.com, ...
			window._paq.push(['setCookieDomain', `*.${this.cookie_domain}`]);

			// Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
			window._paq.push(['setDomains', `*.${this.cookie_domain}`]);

			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.defer = true;

			script.src = `${url}/matomo.js`;

			const head = document.getElementsByTagName('head')[0];
			head.appendChild(script);
		} catch (error) {
			if (!(error instanceof ReferenceError)) {
				throw error;
			}
		}
	}
}
