@if (service$ | async; as service) {
	@if (editService.data$ | async; as data) {
		@if (form) {
			<form [formGroup]="form">
				<div class="flex flex-1 p-2">
					<!-- for draghandle -->
					<ng-content></ng-content>
					<!-- end -->
					<div class="flex flex-1 gap-4">
						<yuno-forms-toggle
							formControlName="active"
							labelPos="side"
							label="Active at startup"></yuno-forms-toggle>
					</div>
					<div class="justify-end">
						<button
							yuno-admin-delete-admin-button
							(click)="service.removeToggleItem(index)">
							remove
						</button>
					</div>
				</div>
				<div class="flex flex-1 flex-row p-2">
					<div class="flex flex-1 gap-4">
						<yuno-forms-toggle
							formControlName="group"
							label="Group"></yuno-forms-toggle>
						<yuno-forms-toggle
							formControlName="public"
							label="Public"></yuno-forms-toggle>
						<section formGroupName="title" class="flex-1">
							@for (lang of editService.languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										[formControlName]="lang"
										[language]="lang"
										placeholder="Title"
										label="Title"></yuno-forms-text>
								}
							}
						</section>
						<yuno-forms-color
							class="w-36"
							formControlName="color"
							placeholder="#00ADF1"
							label="Add color"></yuno-forms-color>
						<yuno-forms-select
							label="Type"
							formControlName="type"
							[selectValues]="['layer', 'dataset']"
							(change)="clearItem($event)"
							[display]="['Layer', 'Dataset']"></yuno-forms-select>
					</div>
				</div>
				@if (form.value.group) {
					<div class="p-6" formGroupName="items">
						<label class="yuno-form">
							<span class="select-none">Items</span>
						</label>
						<div
							cdkDropList
							[cdkDropListData]="form.controls.items"
							(cdkDropListDropped)="dropItem($event)">
							@for (sub of form.controls.items.controls; track sub; let i = $index) {
								<div
									cdkDrag
									[formGroupName]="i"
									class="mb-1 flex flex-col rounded border border-gray-200 pb-1">
									<div class="flex flex-1 p-2">
										<div class="m-auto mx-2 cursor-move pr-4" cdkDragHandle>
											<svg
												viewBox="0 0 16 24"
												class="h-8 w-4"
												fill="#9ca3af"
												border="#9ca3af">
												<use href="#drag-icon" />
											</svg>
										</div>
										<div class="flex flex-1 gap-4">
											<yuno-forms-toggle
												formControlName="public"
												label="Public"></yuno-forms-toggle>
											<section formGroupName="title" class="flex-1">
												@for (lang of editService.languages; track lang) {
													@if (lang === data.language) {
														<yuno-forms-text
															[formControlName]="lang"
															[language]="lang"
															placeholder="Title"
															label="Title"></yuno-forms-text>
													}
												}
											</section>
											<yuno-forms-color
												class="w-36"
												formControlName="color"
												placeholder="#00ADF1"
												label="Add color"></yuno-forms-color>
											<yuno-forms-select
												label="Type"
												formControlName="type"
												[selectValues]="['layer', 'dataset']"
												[display]="[
													'Layer',
													'Dataset'
												]"></yuno-forms-select>
										</div>
										<div class="ml-8 mr-2 mt-6 justify-end">
											<button
												yuno-admin-delete-admin-button
												(click)="service.removeToggleSubItem(i, index)">
												remove
											</button>
										</div>
									</div>
									@if (sub.controls.type.value === 'layer') {
										<div class="my-2 pl-14 pr-4" formArrayName="layer">
											<label class="yuno-form">
												<span class="select-none">Layers</span>
											</label>
											@for (
												alias of sub.controls.layer.controls;
												track alias;
												let j = $index
											) {
												<div class="mb-2 flex gap-4">
													<yuno-forms-select
														[formControlName]="j"
														class="flex-1"
														placeholder="Select layer"
														[selectValues]="service.toggleLayerValues"
														[display]="
															service.toggleLayerDisplay
														"></yuno-forms-select>
													<button
														yuno-admin-delete-admin-button
														(click)="
															service.removeToggleSubLayer(
																j,
																i,
																index
															)
														">
														remove
													</button>
												</div>
											}
											<button
												yuno-admin-button
												color="primary"
												[add]="true"
												(click)="service.addToggleSubLayer(i, index)">
												Add Layer
											</button>
										</div>
									}
									@if (sub.controls.type.value === 'dataset') {
										<div class="my-2 pl-14 pr-4" formArrayName="dataset">
											<label class="yuno-form">
												<span class="select-none">Datasets</span>
											</label>
											@for (
												alias of sub.controls.dataset.controls;
												track alias;
												let j = $index
											) {
												<div class="mb-2 flex gap-4">
													<yuno-forms-select
														class="flex-1"
														[formControlName]="j"
														placeholder="Select dataset"
														[selectValues]="service.toggleDatasetValues"
														[display]="
															service.toggleDatasetDisplay
														"></yuno-forms-select>
													<button
														yuno-admin-delete-admin-button
														(click)="
															service.removeToggleSubDataset(
																j,
																i,
																index
															)
														">
														remove
													</button>
												</div>
											}
											<button
												yuno-admin-button
												color="primary"
												[add]="true"
												(click)="service.addToggleSubDataset(i, index)">
												Add Dataset
											</button>
										</div>
									}
								</div>
							}
						</div>
						<yuno-forms-divider></yuno-forms-divider>
						<button
							yuno-admin-button
							color="primary"
							[add]="true"
							(click)="service.addToggleSubItem(index)">
							Add Sub-Item
						</button>
					</div>
				}
				@if (!form.value.group) {
					<div class="p-6 pr-4">
						@if (form.value.type === 'layer') {
							<div class="mb-2" formArrayName="layer">
								<label class="yuno-form">
									<span class="select-none">Layers</span>
								</label>
								@for (
									alias of form.controls.layer.controls;
									track alias;
									let i = $index
								) {
									<div class="mb-2 flex gap-4">
										<yuno-forms-select
											class="flex-1"
											[formControlName]="i"
											placeholder="Select layer"
											[selectValues]="service.toggleLayerValues"
											[display]="
												service.toggleLayerDisplay
											"></yuno-forms-select>
										<button
											yuno-admin-delete-admin-button
											(click)="service.removeToggleLayer(i, index)">
											remove
										</button>
									</div>
								}
								<button
									yuno-admin-button
									color="primary"
									[add]="true"
									(click)="service.addToggleLayer(index)">
									Add Layer
								</button>
							</div>
						}
						@if (form.value.type === 'dataset') {
							<div class="my-2" formArrayName="dataset">
								<label class="yuno-form">
									<span class="select-none">Datasets</span>
								</label>
								@for (
									alias of form.controls.dataset.controls;
									track alias;
									let i = $index
								) {
									<div class="mb-2 flex gap-4">
										<yuno-forms-select
											class="flex-1"
											[formControlName]="i"
											placeholder="Select dataset"
											[selectValues]="service.toggleDatasetValues"
											[display]="
												service.toggleDatasetDisplay
											"></yuno-forms-select>
										<button
											yuno-admin-delete-admin-button
											(click)="service.removeToggleDataset(i, index)">
											remove
										</button>
									</div>
								}
								<button
									yuno-admin-button
									color="primary"
									[add]="true"
									(click)="service.addToggleDataset(index)">
									Add Dataset
								</button>
							</div>
						}
					</div>
				}
			</form>
		}
	}
}
