import { NgComponentOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Injector,
	Input,
	Output,
	TrackByFunction,
	effect,
	inject
} from '@angular/core';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';

import {
	PageComponentType,
	Pages,
	PagesComponents,
	Textfield,
	TextfieldComponentKeys,
	TextfieldComponents,
	TextfieldComponentsTypes
} from '@yuno/api/interface';

import { PageOptions, TextfieldCompType, TextfieldToken } from '../../textfield.injection.token';
import { TextfieldDataService } from '../textfield.data.service';
import {
	TextBlockComponent,
	TextButtonComponent,
	TextCategoryComponent,
	TextEmbedComponent,
	TextImageButtonComponent,
	TextImageComponent,
	TextLegendComponent,
	TextLinkComponent,
	TextListComponent,
	TextNewsItemComponent,
	TextNotificationComponent,
	TextPageItemComponent,
	TextPredefinedComponent,
	TextTogglesComponent,
	TextVideoComponent
} from './../';

export interface TextfieldDataInject {
	injector: Injector;
	key: TextfieldComponentKeys;
	data: TextfieldComponentsTypes;
	component: TextfieldCompType;
	page?: PageOptions;
}

export interface TextfieldData {
	data: TextfieldComponentsTypes;
	component: TextfieldCompType;
}

const defaultClassName = 'flex flex-col justify-start overflow-x-hidden';

@Component({
	selector: 'yuno-textfield-injector',
	template: `
		@if (render) {
			@for (comp of components; track trackByComponentId($index, comp)) {
				<ng-template
					[ngComponentOutlet]="comp.component"
					[ngComponentOutletInjector]="comp.injector" />
			}
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgComponentOutlet]
})
export class TextfieldInjectorComponent implements AfterViewInit {
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly service = inject(TextfieldDataService);

	/**
	 * Preloads all available components for the Injector
	 * Without it wont render
	 * @private
	 */
	private textfieldComponentSelector = {
		button: TextButtonComponent,
		image: TextImageComponent,
		link: TextLinkComponent,
		list: TextListComponent,
		textblock: TextBlockComponent,
		toggles: TextTogglesComponent,
		video: TextVideoComponent,
		custom: TextBlockComponent,
		container: TextEmbedComponent,
		item: TextPageItemComponent,
		news: TextNewsItemComponent,
		legend: TextLegendComponent,
		category: TextCategoryComponent,
		notification: TextNotificationComponent,
		imageButton: TextImageButtonComponent,
		predefined: TextPredefinedComponent
	};
	components: TextfieldDataInject[];

	@HostBinding('class') className = defaultClassName;

	_render = true;
	@Input() set render(bool: boolean) {
		this._render = bool;
		this.className = bool ? defaultClassName : 'hidden';
	}

	get render(): boolean {
		return this._render;
	}

	@Input() injector: Injector;

	_language = 'nl';
	@Input() set language(lang: string | undefined) {
		if (lang) {
			if (lang !== this._language) {
				this._language = lang;
				this.regenerateComponents();
			}
		}
	}

	get language(): string {
		return this._language;
	}

	@Input() textLinkColor = 'inherit';
	@Input() textHeadingColor = 'inherit';

	@Input() buttonColor: string | undefined = undefined;
	@Input() buttonBgColor: string | undefined = undefined;

	private _data: Partial<Textfield> | Partial<Pages>;
	@Input() set data(data: Partial<Textfield> | Partial<Pages>) {
		if (!data.components) {
			return;
		}

		this._data = data;
		this.components = this.generateComponents(data.components);

		if (!this.render) {
			this.componentsOut.emit(this.components);
		}
	}

	get data(): Partial<Textfield> | Partial<Pages> {
		return this._data;
	}

	private _feature: Feature | MapGeoJSONFeature | undefined;
	@Input() set feature(data: Feature | MapGeoJSONFeature | undefined) {
		this._feature = data;
	}

	get feature(): Feature | MapGeoJSONFeature | undefined {
		return this._feature;
	}

	@Output() componentsOut = new EventEmitter<TextfieldDataInject[]>();

	constructor() {
		effect(() => {
			this.language = this.service.$language();
		});
	}

	ngAfterViewInit(): void {
		if (!this.data?.components) {
			return;
		}

		this.components = this.generateComponents(this.data.components);
		this.cdr.detectChanges();
	}

	/**
	 * A custom trackBy function for tracking items in the *ngFor directive.
	 * @param index - The index of the item.
	 * @param component - The component instance.
	 * @returns The unique identifier for the item.
	 */
	trackByComponentId: TrackByFunction<TextfieldDataInject> = (index, component) => {
		return this.service.getComponentId(index, component);
	};

	/**
	 * Generates an array of components based on the provided array of TextfieldComponents.
	 * @param arr - The array of TextfieldComponents.
	 * @returns An array of TextfieldDataInject instances.
	 */
	generateComponents(arr: TextfieldComponents[]): TextfieldDataInject[] {
		return arr
			.map(obj => this.getFirstNonEmpty(obj))
			.filter(item => item) as TextfieldDataInject[];
	}

	/**
	 * Checks if the given component is of type PageComponent.
	 * @param component - The component to check.
	 * @returns True if the component is of type PageComponent, false otherwise.
	 */
	isOfTypePageComponent(component: PageComponentType): component is PageComponentType {
		return !!(component.order || component.orderMobile || component.pageSide);
	}

	/**
	 * Retrieves the first non-empty property from the given component.
	 * @param component - The TextfieldComponents or PagesComponents instance.
	 * @returns A TextfieldDataInject instance if a non-empty property is found, otherwise undefined.
	 */
	getFirstNonEmpty(
		component: TextfieldComponents | PagesComponents
	): TextfieldDataInject | undefined {
		if (!this.language) {
			return;
		}

		const found = Object.entries(component).find(entry => {
			// Skips the __typename
			if (entry[0] === '__typename') {
				return false;
			}

			return !!entry[1];
		});

		if (!found) {
			return;
		}

		const key = found[0] as TextfieldComponentKeys;
		const data = found[1] as TextfieldComponentsTypes;
		const selectComponent = this.textfieldComponentSelector[key] || TextBlockComponent;

		const options: TextfieldDataInject = {
			injector: Injector.create({
				providers: [
					{
						provide: TextfieldToken,
						useValue: {
							data: data,
							feature: this.feature,
							language: this.language,
							component: selectComponent,
							colors: {
								textLinkColor: this.textLinkColor,
								textHeadingColor: this.textHeadingColor,
								buttonColor: this.buttonColor,
								buttonBgColor: this.buttonBgColor
							}
						}
					}
				],
				parent: this.injector
			}),
			key,
			data,
			component: selectComponent
		};

		if (this.isOfTypePageComponent(component)) {
			const page = component as PagesComponents;
			options.page = {
				order: page.order,
				orderMobile: page.orderMobile,
				pageSide: page.pageSide
			};
		}
		return options;
	}

	/**
	 * Regenerates the components to update their injectors with the new language.
	 */
	private regenerateComponents() {
		if (this._data && this._data.components) {
			this.components = this.generateComponents(this._data.components);
			this.cdr.markForCheck(); // Marks the component for change detection
		}

		if (!this.render) {
			this.componentsOut.emit(this.components);
		}
	}
}
