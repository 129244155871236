import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoLanguageIconsComponent } from '../_language-icons/language-icons.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-textarea',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<textarea
				[rows]="rows || 5"
				[id]="ngControl.name"
				[name]="ngControl.name"
				[placeholder]="placeholder"
				[formControl]="getFormControl()"></textarea>

			<yuno-language-icons [language]="language" [padding]="!!label"></yuno-language-icons>

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired,
		YunoLanguageIconsComponent
	]
})
export class YunoFormsTextareaComponent extends YunoFormsDefaultComponent {
	@Input() rows = 5;
	@Input() language?: string;
}

@Component({
	selector: 'yuno-forms-template-textarea',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<textarea
				[rows]="rows || 5"
				[ngModel]="value"
				(ngModelChange)="valueChange($event)"
				[placeholder]="placeholder"
				[required]="required"></textarea>

			<yuno-language-icons [language]="language" [padding]="!!label"></yuno-language-icons>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsLabelRequired, YunoLanguageIconsComponent],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsTextareaTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsTextareaTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() rows = 5;
	@Input() language?: string;
}
