<yuno-card-draggable
	(click)="onClick()"
	[white]="true"
	[active]="active"
	[header]="false"
	[draggable]="!addList">
	<ng-container buttons>
		@if (!duplicateDisabled) {
			<button yuno-admin-button color="muted" (click)="onDuplicate($event)">Duplicate</button>
		}
		@if (!deleteDisabled) {
			<button yuno-admin-button color="danger" (click)="onRemove($event)">Remove</button>
		}
		@if (addList) {
			<button yuno-admin-add-button (click)="onAdd()"></button>
		}
	</ng-container>
	<ng-container popup>
		<span class="text-sm capitalize">{{ componentTitle }}</span>
		@if (componentTitle !== 'container') {
			<span class="float-right text-sm">
				<!-- last modified: {{ updatedBy }} {{ updatedAt | date : 'dd-MM-YYYY, HH:mm' }} -->
				last modified: {{ updatedAt | date: 'dd-MM-YYYY, HH:mm' }}
			</span>
		}
	</ng-container>
	<ng-container content>
		<div class="pointer-events-none overflow-hidden">
			<ng-container
				[ngComponentOutlet]="item.component"
				[ngComponentOutletInjector]="item.injector"></ng-container>
		</div>
	</ng-container>
</yuno-card-draggable>
