module.exports = {
  A: {
    A: {
      "2": "K D E eC",
      "8": "F",
      "292": "A B"
    },
    B: {
      "1": "4 5 6 7 8 9 N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I",
      "292": "C L M G"
    },
    C: {
      "1": "4 5 6 7 8 9 mB nB oB pB qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC gC",
      "2": "fC GC J IB K D E F A B C L M G N O P hC iC",
      "8": "0 1 2 3 JB y z KB LB MB NB OB PB QB RB SB TB UB VB WB XB",
      "584": "YB ZB aB bB cB dB eB fB gB hB iB jB",
      "1025": "kB lB"
    },
    D: {
      "1": "4 5 6 7 8 9 qB HC rB IC sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB I 9B KC LC",
      "2": "0 1 2 J IB K D E F A B C L M G N O P JB y z",
      "8": "3 KB LB MB",
      "200": "NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB",
      "1025": "pB"
    },
    E: {
      "1": "B C L M G NC AC BC oC pC qC OC PC CC rC DC QC RC SC TC UC sC EC VC WC XC YC ZC aC FC bC tC",
      "2": "J IB jC MC kC",
      "8": "K D E F A lC mC nC"
    },
    F: {
      "1": "cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B Q H R JC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 F B C G N O P JB y z KB LB uC vC wC xC AC cC yC BC",
      "200": "MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB"
    },
    G: {
      "1": "7C 8C 9C AD BD CD DD ED FD GD HD ID OC PC CC JD DC QC RC SC TC UC KD EC VC WC XC YC ZC aC FC bC",
      "2": "MC zC dC 0C",
      "8": "E 1C 2C 3C 4C 5C 6C"
    },
    H: {
      "2": "LD"
    },
    I: {
      "1": "I",
      "2": "GC J MD ND OD PD",
      "8": "dC QD RD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C AC cC BC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "9B"
    },
    N: {
      "292": "A B"
    },
    O: {
      "1": "CC"
    },
    P: {
      "1": "0 1 2 3 y z TD UD VD WD NC XD YD ZD aD bD DC EC FC cD",
      "2": "SD",
      "8": "J"
    },
    Q: {
      "1": "dD"
    },
    R: {
      "1": "eD"
    },
    S: {
      "1": "fD gD"
    }
  },
  B: 4,
  C: "CSS Grid Layout (level 1)",
  D: true
};